export const PROJECT_LIST_COLOR = [
  "#667dff",
  "#68cf91",
  "#de77e6",
  "#ed815f",
  "#e6da69",
  "#209fc7",
  "#60b2ff",
  "#6ba521",
  "#81ce5d",
  "#a670ea",
  "#c1a336",
  "#c34799",
  "#e7694d",
  "#fa8686",
  "#fc7db2",
  "#ffc266",
];
