import styles from "./ProjectFilterIcons.module.css";

const ButtonWithTooltip = ({ icon: Icon, tooltipText, onClick, buttonClass, iconClass }) => {
  return (
    <div className={`${styles.button} ${buttonClass}`} onClick={onClick}>
      <Icon className={`${styles.icon} ${iconClass}`} />
      <div className={styles.tooltip}>{tooltipText}</div>
    </div>
  );
};

export default ButtonWithTooltip;
