import IntegrationDetails from "./IntegrationDetails/IntegrationDetails";
import JiraItem from "./IntegrationDetails/IntegrationDetailItems/JiraItem";
import MailItem from "./IntegrationDetails/IntegrationDetailItems/MailItem";
import SlackItem from "./IntegrationDetails/IntegrationDetailItems/SlackItem";

export function JiraIntegration({
  creator,
  selectedRow,
  jiraData,
  reloadJira,
  setJiraData,
  isIntegrationDataFirstLoading,
  isValidToken,
}) {
  return (
    <IntegrationDetails
      title="Jira"
      creator={creator}
      selectedRow={selectedRow}
      listData={jiraData}
      reloadFunction={reloadJira}
      ItemComponent={JiraItem}
      handleLabel={null}
      setData={setJiraData}
      isReconnectNeeded={!isValidToken}
      isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
    />
  );
}

export function MailIntegration({
  creator,
  selectedRow,
  mailData,
  mailLabels,
  mailListRef,
  mailDropdownOpen,
  toggleMailDropdown,
  handleMailLabel,
  reloadMail,
  dropDownRef,
  selectedLabel,
  setMailData,
  isIntegrationDataFirstLoading,
  isValidToken,
}) {
  return (
    <IntegrationDetails
      title="Mail"
      creator={creator}
      selectedRow={selectedRow}
      ItemComponent={MailItem}
      listData={mailData}
      mailLabels={mailLabels}
      mailListRef={mailListRef}
      mailDropdownOpen={mailDropdownOpen}
      toggleMailDropdown={toggleMailDropdown}
      handleMailLabel={handleMailLabel}
      reloadFunction={reloadMail}
      selectedLabel={selectedLabel}
      setData={setMailData}
      isReconnectNeeded={!isValidToken}
      isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
    />
  );
}

export function SlackIntegration({
  creator,
  selectedRow,
  slackData,
  reloadSlack,
  setSlackData,
  isIntegrationDataFirstLoading,
  isValidToken,
}) {
  return (
    <IntegrationDetails
      title="Slack"
      creator={creator}
      selectedRow={selectedRow}
      listData={slackData}
      reloadFunction={reloadSlack}
      ItemComponent={SlackItem}
      setData={setSlackData}
      isReconnectNeeded={!isValidToken}
      isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
    />
  );
}
