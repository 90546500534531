import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useRecoilState } from "recoil";
import { toastState } from "../../recoil/toast/toastState";

import styles from "./Toast.module.css";

function Toast() {
  const [toast, setToast] = useRecoilState(toastState);
  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("hide");
  const [hideTimeout, setHideTimeout] = useState(null);

  useEffect(() => {
    // 토스트 메시지가 설정되어 있고, visible 상태가 true인 경우에만 동작
    if (toast.isVisible && toast.message) {
      setIsVisible(true);
      setAnimationClass("show");

      // 기존 타이머 초기화
      if (hideTimeout) clearTimeout(hideTimeout);

      const newHideTimeout = setTimeout(() => {
        setAnimationClass("hide");
        const resetTimeout = setTimeout(() => {
          setIsVisible(false);
          setToast((prevState) => ({ ...prevState, isVisible: false, message: "" }));
        }, 200);
        return () => clearTimeout(resetTimeout);
      }, 3000);
      setHideTimeout(newHideTimeout);

      return () => clearTimeout(newHideTimeout);
    } else {
      // 메시지가 비어있거나 visible이 false인 경우 토스트 숨김
      setIsVisible(false);
      setAnimationClass("hide");
    }
  }, [toast]);

  const handleToastClose = (event) => {
    event.stopPropagation();
    setAnimationClass("hide");
    setTimeout(() => {
      setIsVisible(false);
      setToast({ isVisible: false, message: "" });
    }, 200);
  };

  if (!isVisible && animationClass !== "show") return null;
  if (!toast.message) return null;
  return createPortal(
    <div className={`${styles.toastContainer} ${styles[animationClass]}`}>
      <div className={styles[`toast${toast.type}Icon`]}></div>
      <span className={styles.toastMessage}>{toast.message}</span>
      <button
        className={styles.toastCloseButton}
        onClick={handleToastClose}
        onMouseDown={(e) => e.stopPropagation()}
      ></button>
    </div>,
    document.body
  );
}

export default Toast;
