import styles from "./ProjectFilterTitle.module.css";

const ProjectFilterTitle = ({ handleExpandClick, sidebarExpand }) => {
  return (
    <div className={`${styles["title"]}`}>
      <div className={`${styles["title-row"]}`}>
        <div className={`${styles["title-icon"]}`} onClick={handleExpandClick}>
          <div
            className={`${styles["title-icon-hover"]} ${
              sidebarExpand && styles["title-icon-rotate"]
            }`}
          ></div>
          <div className={styles["title-icon-default"]}></div>
        </div>
        {sidebarExpand && (
          <>
            <div className={`${styles["title-main"]}`}>Projects</div>
            {/* <div className={`${styles['title-done']} ${isDone && styles['title-done-on'] }`} onClick={()=>setIsDone(!isDone)}>Done</div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectFilterTitle;
