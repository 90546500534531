import { useState } from "react";
import { useJuneTrackCall } from "../../../../utils/june/analytics";
import useApi from "../../../../services/auth/useApi";

import { ReactComponent as ReconnectIcon } from "../../../../assets/Integration/reconnect-icon.svg";

import styles from "./Reconnect.module.css";
import LoadingComponent from "../../../Common/LoadingComponent";

export const Reconnect = ({ kind }) => {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const [isLoading, setIsLoading] = useState(false);

  const handleReconnect = () => {
    api.get(`integrations/${kind}/auth`).then((res) => {
      window.location.href = res.data.redirectUrl;
    });
  };

  return (
    <div className={styles.reconnect}>
      <ReconnectIcon className={styles.reconnect_icon} />
      <div className={styles.reconnect_textbox}>
        <span className={styles.reconnect_title}>Unable to sync</span>
        <span className={styles.reconnect_description}>
          Please reconnect or remove this account.
        </span>
      </div>
      <button onClick={handleReconnect} className={styles.reconnect_btn} disabled={isLoading}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <span className={styles.reconnect_btn_text}>Reconnect account</span>
        )}
      </button>
    </div>
  );
};
