import { useMutation, useQuery } from "@tanstack/react-query";
import useApi from "../services/auth/useApi";

export const accountKey = {
  all: ["accounts"],
  accountContacts: () => [...accountKey.all, "accountContacts"],
  accountContactsRecent: () => [...accountKey.all, "accountContactsRecent"],
};

export const useAccountContactsQuery = () => {
  const api = useApi();

  return useQuery({
    queryKey: accountKey.accountContacts(),
    queryFn: () => api.get(`accounts/contacts`).then((res) => res.data),
  });
};

export const useAccountContactsRecentQuery = () => {
  const api = useApi();

  return useQuery({
    queryKey: accountKey.accountContactsRecent(),
    queryFn: () => api.get(`accounts/contacts/recent`).then((res) => res.data),
  });
};

export const useUpdateContactsRecent = () => {
  const api = useApi();

  return useMutation({
    mutationFn: (email) => api.put(`accounts/contacts/recent`, { email }).then((res) => res.data),
  });
};
