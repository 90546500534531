import { useEffect, useRef, useState } from "react";

import { Resizable } from "re-resizable";
import { useRecoilState } from "recoil";
import { accountState } from "../../recoil/account/accountState";
import { integrationHeight } from "../../recoil/integration/integrationHeight";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";

import useFetchIntegrations from "../../services/integration/useFetchIntegrations";

import { ReactComponent as AddIcon } from "../../assets/Common/add-icon.svg";
import { ReactComponent as CloseButton } from "../../assets/Integration/close-button.svg";
import { ReactComponent as ExpandSnapGapIcon } from "../../assets/Integration/snapgap-icon.svg";
import { JiraIntegration, MailIntegration, SlackIntegration } from "./Integrations/Integrations";

import styles from "./styles.module.css";

const IntegrationItemList = ({ itemInfo, handleIntegrationItemClick, selectedRow }) => {
  const messageBoxTitle = () => {
    switch (itemInfo.kind) {
      case "gmail":
        return itemInfo.data.email;

      case "jira":
        return itemInfo.data.resourceName;

      case "slack":
        return itemInfo.data.teamName;

      default:
        return "";
    }
  };

  return (
    <div
      className={`${styles.rowIcon} ${
        selectedRow.id === itemInfo.id && styles[`rowIcon-selected`]
      }`}
      onClick={() => handleIntegrationItemClick(itemInfo)}
    >
      <div className={`${styles["rowIcon" + itemInfo.kind]}`}></div>
      <div className={styles.messageBox}>{messageBoxTitle()}</div>
    </div>
  );
};

export default function Integration({
  inboxRef,
  settingState,
  setIsIntegrationModalOpen,
  integrationBtnRef,
  isIntegrationModalOpen,
  isIntegrationDataFirstLoading,
}) {
  const api = useApi();
  const trackCall = useJuneTrackCall();

  const [accountData, setAccountData] = useRecoilState(accountState);
  const [creator, setCreator] = useState(null);
  const [selectedRow, setSelectedRow] = useState({
    id: null,
    kind: "",
    info: "",
  });
  // 전체 메일함
  const [mailLabels, setMailLabels] = useState(null);
  // 선택된 메일함
  const [selectedMailLabel, setSelectedMailLabel] = useState({
    id: "INBOX",
    name: "Inbox",
    type: "system",
  });
  const [mailScrollLoading, setMailScrollLoading] = useState(false);

  const [initNextPageToken, setInitNextPageToken] = useState(null);
  const [mailDropdownOpen, setMailDropdownOpen] = useState(false);

  const [mailData, setMailData] = useState(null);
  const [jiraData, setJiraData] = useState(null);
  const [slackData, setSlackData] = useState(null);

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [modalHeight, setModalHeight] = useRecoilState(integrationHeight);
  const { integrations, loading: integrationLoading, error } = useFetchIntegrations();

  const [isValidToken, setIsValidToken] = useState(true);
  const expandRef = useRef(null);
  const mailListRef = useRef(null);

  useEffect(() => {
    if (integrations) {
      const [primaryAccountInfo] = accountData.accountInfo.accounts.filter((account) => {
        return account.type === "primary";
      });
      setCreator(primaryAccountInfo.email);
    }
  }, [accountData]);

  useEffect(() => {
    if (selectedRow.id == null) return;

    switch (selectedRow.kind) {
      case "gmail":
        reloadMail();
        break;

      case "jira":
        reloadJira();
        break;

      case "slack":
        reloadSlack();
        break;
    }
  }, [selectedRow]);

  // mailLabel 바뀌면 다시 불러오기
  useEffect(() => {
    if (mailLabels != null && selectedRow.kind === "gmail") {
      reloadMail();
    }
  }, [selectedMailLabel, selectedRow.kind]);

  useEffect(() => {
    if (mailLabels == null) return;
    reloadMail();
  }, [mailLabels]);

  const handleCloseIntegration = () => {
    setIsIntegrationModalOpen(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const reloadMail = () => {
    if (selectedRow.id == null) return;

    setIsLoading(true);
    if (mailLabels == null) {
      api
        .get("integrations/gmail/labels", {
          params: {
            integrationId: selectedRow.id,
          },
        })
        .then((res) => {
          const processedLabels = res.data.labels;

          const visibleSystemLabels = processedLabels
            .filter((label) => label.type === "system" && label.messageListVisibility !== "hide")
            .map((label) => ({
              ...label,
              name: capitalizeFirstLetter(label.name),
            }));

          const userLabels = processedLabels.filter((label) => label.type === "user");

          setMailLabels({
            system: visibleSystemLabels,
            user: userLabels,
          });

          setIsLoading(false);
        });
    } else {
      api
        .get("integrations/gmail/mails", {
          params: {
            integrationId: selectedRow.id,
            label: selectedMailLabel.id,
          },
        })
        .then((res) => {
          const uniqueThreads = {};

          res.data.mails.forEach((mail) => {
            if (!uniqueThreads[mail.threadId]) {
              uniqueThreads[mail.threadId] = mail;
            }
          });

          const filteringMails = Object.values(uniqueThreads);
          setMailData(filteringMails);
          setInitNextPageToken(res.data.nextPageToken);
          setIsLoading(false);
        });
    }
  };

  const reloadJira = () => {
    setIsLoading(true);
    api
      .get("integrations/jira/tickets", {
        params: {
          integrationId: selectedRow.id,
        },
      })
      .then((res) => {
        if (res.data.tickets && res.data.tickets.length > 0) {
          const tickets = res.data.tickets;
          setJiraData(tickets);
        } else {
          setJiraData([]);
        }
        setIsLoading(false);
      });
  };

  const reloadSlack = () => {
    setIsLoading(true);
    api
      .get("integrations/slack", {
        params: {
          integrationId: selectedRow.id,
        },
      })
      .then((res) => {
        setSlackData(res.data.messages);
        setIsLoading(false);
      });
  };

  const loadMoreMails = async () => {
    if (mailScrollLoading) return;
    if (initNextPageToken == null) return;
    setMailScrollLoading(true);
    try {
      api
        .get("integrations/gmail/mails", {
          params: {
            integrationId: selectedRow.id,
            label: selectedMailLabel.id,
            maxResults: 50,
            nextPageToken: initNextPageToken,
          },
        })
        .then((res) => {
          const newMails = res.data.mails;
          const uniqueThreads = {};

          newMails.forEach((mail) => {
            if (!uniqueThreads[mail.threadId]) {
              uniqueThreads[mail.threadId] = mail;
            }
          });
          const filteringMails = Object.values(uniqueThreads);

          setMailData((prevMails) => [...prevMails, ...filteringMails]);
          setInitNextPageToken(res.data.nextPageToken);
          setMailScrollLoading(false);
        });
    } catch (error) {
      console.error("Failed to load more mails:", error);
    }
  };

  const handleScroll = (e) => {
    if (mailListRef.current && selectedRow.kind === "gmail") {
      const { scrollTop, clientHeight, scrollHeight } = mailListRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 4.0 && !mailScrollLoading) {
        loadMoreMails();
      }
    }
  };

  useEffect(() => {
    const container = mailListRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const toggleMailDropdown = () => {
    setMailDropdownOpen((prev) => !prev);
  };

  const handleMailLabel = (item, index) => {
    setSelectedMailLabel(item);

    setTimeout(() => {
      setMailDropdownOpen(false);
    }, 200);
  };

  useEffect(() => {
    if (inboxRef.current) {
      const handleResize = () => {
        const inboxRect = inboxRef.current.getBoundingClientRect();
        setPosition({
          top: inboxRect.top + 15,
          left: inboxRect.right + 10,
        });
      };

      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [inboxRef]);

  // 아이콘 클릭 시
  const handleIntegrationItemClick = (selectedItem) => {
    const isSameItem = selectedItem.id === selectedRow.id;

    let email;
    switch (selectedItem.kind) {
      case "gmail":
        email = selectedItem.data.email;
        break;
      case "jira":
        email = selectedItem.data.resourceName;
        break;
      case "slack":
        email = selectedItem.data.teamName;
        break;
    }

    setSelectedRow({
      id: isSameItem ? null : selectedItem.id,
      kind: isSameItem ? null : selectedItem.kind,
      info: email,
    });

    // NOTE 아이템 선택시에만 동작(취소 시에는 동작 안함)
    if (!isSameItem) {
      trackCall("view_integration", { type: selectedItem.kind });
      setIsValidToken(selectedItem.isValidToken ?? true); // 빈값 처리

      const lastSelectedIntegration = JSON.parse(localStorage.getItem("lastSelectedIntegration"));
      const updatedIntegration = {
        ...lastSelectedIntegration,
        id: selectedItem.id,
        kind: selectedItem.kind,
        info: email,
        isValidToken: selectedItem.isValidToken ?? true, // 빈값 처리
      };
      localStorage.setItem("lastSelectedIntegration", JSON.stringify(updatedIntegration));
    }
  };

  useEffect(() => {
    if (integrations && integrations.length > 0) {
      const lastSelectedIntegration = JSON.parse(localStorage.getItem("lastSelectedIntegration"));

      if (lastSelectedIntegration) {
        const integrationExists = integrations.find(
          (integration) => integration.id === lastSelectedIntegration.id
        );

        if (integrationExists) {
          setSelectedRow(lastSelectedIntegration);
          setIsValidToken(
            lastSelectedIntegration.isValidToken !== undefined
              ? lastSelectedIntegration.isValidToken
              : true
          ); // 빈값 처리
        } else {
          setSelectedRow({
            id: integrations[0].id,
            kind: integrations[0].kind,
            info: integrations[0].email,
          });
          setIsValidToken(integrations[0].isValidToken ?? true); // 기본값 설정 및 빈값 처리
        }
      } else {
        setSelectedRow({
          id: integrations[0].id,
          kind: integrations[0].kind,
          info: integrations[0].email,
        });
        setIsValidToken(integrations[0].isValidToken ?? true); // 기본값 설정 및 빈값 처리
      }
    }
  }, [integrations]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleCloseIntegration();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isIntegrationModalOpen &&
        expandRef.current &&
        !expandRef.current.contains(event.target) &&
        integrationBtnRef.current &&
        !integrationBtnRef.current.contains(event.target)
      ) {
        setIsIntegrationModalOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isIntegrationModalOpen]);

  const handleResizeStop = (e, direction, ref, d) => {
    const newHeight = ref.style.height;
    setModalHeight(newHeight);
    localStorage.setItem("integrationHeight", newHeight);
  };

  if (integrationLoading) {
    return null; // 데이터를 불러오는 동안 아무것도 표시하지 않음
  }

  return (
    <div
      className={styles.integrationOverlay}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      ref={expandRef}
    >
      <Resizable
        defaultSize={{
          width: "280px",
          height: modalHeight,
        }}
        minWidth={280}
        minHeight={300}
        enable={{
          top: false,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        snapGap={10}
        className={styles.integration}
        onResizeStop={handleResizeStop}
      >
        <div className={styles.integrationContent}>
          {/* 헤더 */}
          <div className={styles.head}>
            <h2 className={styles.title}>Integrations</h2>
            <CloseButton className={styles.close__button} onClick={handleCloseIntegration} />
          </div>
          {/* 연결된 인테그레이션 리스트 */}
          <div className={styles.connected__list}>
            {integrations &&
              integrations.length > 0 &&
              integrations.map((integrationItemInfo) => (
                <IntegrationItemList
                  key={integrationItemInfo.id}
                  itemInfo={integrationItemInfo}
                  handleIntegrationItemClick={handleIntegrationItemClick}
                  selectedRow={selectedRow}
                />
              ))}
            <div className={styles["add-icon"]} onClick={() => settingState("Integrations")}>
              <AddIcon />
            </div>
          </div>
          {/* 아이콘 클릭 시 데이터 렌더링 */}
          {integrations && integrations.length > 0 ? (
            <div className={styles.flexibleContent}>
              {selectedRow.kind === "gmail" && (
                <MailIntegration
                  creator={creator}
                  selectedRow={selectedRow}
                  mailData={mailData}
                  mailLabels={mailLabels}
                  mailListRef={mailListRef}
                  mailDropdownOpen={mailDropdownOpen}
                  toggleMailDropdown={toggleMailDropdown}
                  handleMailLabel={handleMailLabel}
                  reloadMail={reloadMail}
                  selectedLabel={selectedMailLabel}
                  setMailData={setMailData}
                  isIntegrationDataFirstLoading={isIntegrationDataFirstLoading || isLoading}
                  isValidToken={isValidToken}
                />
              )}
              {selectedRow.kind === "slack" && (
                <SlackIntegration
                  creator={creator}
                  selectedRow={selectedRow}
                  slackData={slackData}
                  reloadSlack={reloadSlack}
                  setSlackData={setSlackData}
                  isIntegrationDataFirstLoading={isIntegrationDataFirstLoading}
                  isValidToken={isValidToken}
                />
              )}
              {selectedRow.kind === "jira" && (
                <JiraIntegration
                  creator={creator}
                  selectedRow={selectedRow}
                  jiraData={jiraData}
                  reloadJira={reloadJira}
                  setJiraData={setJiraData}
                  isIntegrationDataFirstLoading={isIntegrationDataFirstLoading || isLoading}
                  isValidToken={isValidToken}
                />
              )}
            </div>
          ) : (
            <div className={styles.noIntegrations}>
              <div className={styles["add-icon-big"]} onClick={() => settingState("Integrations")}>
                <AddIcon />
              </div>
              <span>Plan your day with your tools</span>
            </div>
          )}
          <div className={styles.resize__snapGap}>
            <ExpandSnapGapIcon />
          </div>
        </div>
      </Resizable>
    </div>
  );
}
