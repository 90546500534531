import { useDrag } from "react-dnd";
import { useRecoilState, useRecoilValue } from "recoil";
import { dragEventState, draggedEventState } from "../../../../../recoil/calendar/calendarState";
import useApi from "../../../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../../../utils/june/analytics";
import styles from "./IntegrationDetailItems.module.css";

import { COMPLETED, INPROGRESS } from "../../../../../constants/taskStateType";
import { doneTaskListState } from "../../../../../recoil/taskList/doneTaskListState";
import { inboxTaskListState } from "../../../../../recoil/taskList/inboxTaskListState";
import { useState, useEffect } from "react";
import { visibilityState } from "../../../../../recoil/calendar/settingCalendar";

function parseMessage(data) {
  const parseText = (blocks, emojis, usernames) => {
    return blocks
      .flatMap((block) => {
        if (!block.elements) return [];
        return block.elements.flatMap((element) => {
          if (!element.elements) return [];
          return element.elements.map((subElement) => {
            if (subElement.type === "user") {
              return `@${usernames[subElement.user_id]}`;
            } else if (subElement.type === "text") {
              return subElement.text;
            }
            return "";
          });
        });
      })
      .join("");
  };

  if (!data || !data.blocks || data.blocks.length === 0) {
    return data && data.text ? data.text : "";
  }

  const emojis = data.emojis || {};
  const usernames = data.usernames || {};
  const blocks = data.blocks;

  return parseText(blocks, emojis, usernames);
}

function SlackTextRender({ data }) {
  const elements =
    data.blocks != null
      ? parseBlocksToElements(data.blocks, data.usernames)
      : data.text.split("\n").map((line, idx) => (
          <span key={`line-${idx}`}>
            {line}
            <br />
          </span>
        ));

  return <div>{elements}</div>;
}

// 타임스탬프 함수 분리
function formatTimestamp(createDate) {
  const date = new Date(createDate);

  // 서울 시간대로 변환
  const seoulTime = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Seoul" }));

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[seoulTime.getMonth()];
  const day = seoulTime.getDate();
  let hour = seoulTime.getHours();
  const minute = seoulTime.getMinutes();

  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;

  const suffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${month} ${day}${suffix(day)} at ${hour}:${formattedMinute} ${ampm}`;
}

function handleLinkClick(event) {
  event.stopPropagation();
}

function parseBlocksToElements(blocks, usernames) {
  return blocks
    .map((block, blockIndex) => {
      if (block.type === "rich_text") {
        return block.elements.map((element, elementIndex) => {
          return parseRichTextElement(element, `element-${blockIndex}-${elementIndex}`, usernames);
        });
      }
    })
    .flat();
}

function parseRichTextElement(element, key, usernames) {
  let content = [];

  if (element.type === "rich_text_section") {
    return (
      <span key={key}>
        {element.elements.map((innerElement, index) =>
          parseRichTextElement(innerElement, `inner-${key}-${index}`, usernames)
        )}
      </span>
    );
  }

  switch (element.type) {
    case "text":
      if (element.text) {
        let textParts = element.text.split("\n");
        textParts.forEach((part, idx) => {
          const textElement =
            element.style && element.style.code ? (
              <code key={`code-${idx}`}>{part}</code>
            ) : (
              <span key={`text-${idx}`} className={styles["slackTextType"]}>
                {part}
              </span>
            );
          content.push(textElement);
          if (idx !== textParts.length - 1) content.push(<br key={`br-${key}-${idx}`} />);
        });
      }
      break;

    case "emoji":
      const emojiCodePoints = element.unicode
        ? element.unicode.split("-").map((cp) => parseInt(cp, 16))
        : null;
      const emojiChar = emojiCodePoints
        ? String.fromCodePoint(...emojiCodePoints)
        : `:${element.name}:`;
      content.push(<span key={key}>{emojiChar}</span>);
      break;

    case "user":
      const username = usernames[element.user_id];
      content.push(
        <span key={key} className={styles["slack-id-highlight"]}>
          @{username}
        </span>
      );
      break;

    case "link":
      content.push(
        <a
          key={`link-${key}`}
          className={styles["slack-link-highlight"]}
          href={element.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          {element.url}
        </a>
      );
      break;

    default:
      break;
  }
  return (
    <span key={key} className={styles.inlineElements}>
      {content}
    </span>
  );
}

const SlackItem = ({ data, integrationId, creator, setData: setSlackData }) => {
  const redirectToSlack = () => {
    trackCall("click_integration", {
      location: "integration",
      type: "slack",
    });

    const slackMessageUrl = data.link;
    window.open(slackMessageUrl, "_blank");
  };
  const slackMessageParseString = parseMessage(data);
  const dataId = data.userId + data.ts;
  const makeLink = data.link;

  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [dragEvent, setDragEvent] = useRecoilState(dragEventState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
  const defaultVisibility = useRecoilValue(visibilityState);

  const trackCall = useJuneTrackCall();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     reloadFunction();
  //   }, 100);
  // }, [draggedEvent]);

  const convertingData = {
    ...data,
    id: "int-" + data.userId + data.ts,
    creator: creator,
    link: makeLink,
  };

  const dragData = {
    ...convertingData,
    title: slackMessageParseString,
    kind: "slack",
    provider: "slack",
    integrationId: integrationId,
    creator: creator,
    color: "#abadad",
    link: data.link,
    dragType: "integrationDrag",
    integration: {
      integrationId: integrationId,
      itemId: data.userId + data.ts,
      provider: "slack",
      link: data.link,
      title: slackMessageParseString,
    },
  };

  const [{ isDragging }, drag] = useDrag({
    type: "integrationDrag",
    item: dragData,
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        // 1. NOTE 드랍이 되었을 때
        const dropResult = monitor.getDropResult();
        if (!dropResult) {
          updateInboxTaskList((current) => {
            const updatedTasks = current.filter((task) => task.id !== data.userId + data.ts);
            return updatedTasks;
          });
        } else {
          // setIsLoading(true);

          if (dropResult.dropType !== "calendarIntegrationDrop") {
            // 1-1. NOTE 드랍한 곳이 인박스일 때
            const newItem = {
              id: dragData.id,
              title: item.title,
              integration: {
                integrationId: item.integrationId,
                itemId: data.userId + data.ts,
                provider: item.kind,
                link: item.link,
              },
              visibility: defaultVisibility === "public" ? "public" : "private",
              transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
              itemStatus: dropResult.dropType === "doneTask" ? COMPLETED : INPROGRESS,
              taskType: "Task",
            };

            api
              .post("tasks", newItem, {
                headers: {
                  "X-Requester": item.creator,
                },
              })
              .then((res) => {
                trackCall("create_block", {
                  location: "integration",
                });
              });
          }
          setSlackData((current) => current.filter((slack) => slack.userId + slack.ts !== dataId));
        }
      } else {
        // 2. NOTE 드랍을 안했을 때(원상복귀)
        updateInboxTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== "int-" + dragData.id);
          return updatedTasks;
        });

        updateDoneTaskList((current) => {
          const updatedTasks = current.filter((task) => task.id !== "int-" + dragData.id);
          return updatedTasks;
        });
      }
      // NOTE 항상
      setDragEvent(null);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      draggable="true"
      key={{ title: "slack title" }}
      className={` ${isDragging ? styles["dragging"] : styles["item-area"]}`}
      onClick={redirectToSlack}
      onDragStart={() => setDraggedEvent(dragData)}
    >
      <span className={styles["channel"]}>#{data.channel}</span>
      <div className={styles["name-create-area"]}>
        <span className={styles["name"]}>{data.sender}</span>
        <span className={styles["time"]}>{formatTimestamp(data.created)}</span>
      </div>
      <div className={styles["description-container"]}>
        <span className={styles["description"]}>
          <SlackTextRender data={data} />
        </span>
      </div>
    </div>
  );
};

export default SlackItem;
