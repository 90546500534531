export const convertGoogleCalendarToMobaCalendar = (eventData) => {
  const {
    id,
    creator,
    summary,
    title,
    start,
    end,
    attendees,
    hangoutLink,
    extendedProperties,
    projectId,
    integration,
    kind,
    recurrence,
    note,
    links,
    itemStatus,
    visibility,
    transparency,
    isCreateSelectEvent,
    isDataDuplicateEvent,
    taskType,
    created,
    eventType,
  } = eventData;

  const getTime = (time) => new Date(time);
  const getDate = (date) => date;

  const convertStart = start.dateTime
    ? getTime(start.dateTime)
    : start.date
      ? getDate(start.date)
      : start || null;

  const convertEnd = end.dateTime
    ? getTime(end.dateTime)
    : end.date
      ? getDate(end.date)
      : end || null;

  const privateProps = extendedProperties?.private || {};
  const parsedIntegration = privateProps.integration
    ? JSON.parse(privateProps.integration)
    : integration;

  return {
    id,
    creator: creator?.email || creator,
    title: summary || title,
    start: convertStart,
    startTimeZone: start.timeZone || eventData.startTimeZone || null,
    end: convertEnd,
    endTimeZone: start.timeZone || eventData.startTimeZone || null,
    allDay: start.timeZone || eventData.startTimeZone ? false : true,
    attendees,
    hangoutLink: hangoutLink || null,
    projectId: privateProps.projectId || projectId || null,
    integration: parsedIntegration,
    kind,
    recurrence: recurrence || [],
    note,
    links: links || (privateProps.links ? JSON.parse(privateProps.links) : []),
    itemStatus: itemStatus || privateProps.itemStatus,
    link: eventData.link || null,
    visibility: visibility || null,
    transparency: transparency || null,
    isCreateSelectEvent: !!isCreateSelectEvent,
    isDataDuplicateEvent,
    taskType: privateProps.taskType
      ? privateProps.taskType
      : taskType
        ? taskType
        : created && new Date(created) > new Date("2024-08-19")
          ? eventType === "default"
            ? "Event"
            : "Task"
          : attendees
            ? attendees.length > 0
              ? "Event"
              : "Task"
            : "Task",
  };
};
