import { atom } from "recoil";

/**
 * Inprogress || Completed된 프로젝트의 리스트
 */
export const projectListState = atom({
  key: "projectListState",
  default: null,
});

/**
 * Deleted된 프로젝트의 리스트
 */
export const deletedProjectListState = atom({
  key: "deletedProjectListState",
  default: null,
});
