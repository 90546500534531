import { useDrop } from "react-dnd";
import { COMPLETED } from "../../constants/taskStateType";
import styles from "./styles.module.css";

const TaskListEmptyDropArea = ({ onTasksReorderChange, handleUpdateTaskList, listType }) => {
  const [, drop] = useDrop({
    accept: ["task", "integrationDrag", "moreTask"],
    hover: (item, monitor) => {
      if (listType === "doneTask") {
        const tempItem = { ...item, itemStatus: COMPLETED };
        handleUpdateTaskList([tempItem]);
      } else {
        handleUpdateTaskList([item]);
      }
    },
    drop(props, monitor) {
      const item = monitor.getItem();
      onTasksReorderChange();

      if (item.dragType === "integrationDrag") {
        return { dropType: listType };
      }
    },
  });

  return <div ref={drop} className={styles.taskListEmptyBody}></div>;
};

export default TaskListEmptyDropArea;
