import { useEffect, useState } from "react";

import Lottie from "lottie-react";

import useApi from "../../services/auth/useApi";

import styles from "./styles.module.css";
import dollorImg from "../../assets/Onboarding/onboarding-dollor.png";
import step1 from "../../assets/Onboarding/1-step.json";
import step2 from "../../assets/Onboarding/2-step.json";
import step3 from "../../assets/Onboarding/3-step.json";
import step4 from "../../assets/Onboarding/4-step.png";
import step5 from "../../assets/Onboarding/5-step.json";
import step6 from "../../assets/Onboarding/6-step.json";
import step7 from "../../assets/Onboarding/7-step.json";
import step8 from "../../assets/Onboarding/8-step.json";
import arrowLeft from "../../assets/Onboarding/arrow-left.png";

import slackIcon from "../../assets/Onboarding/onboarding-complete-slack-icon.png";

function WelcomCompleteCard({ hideOnboarding }) {
  const api = useApi();

  const handleCompletedOnboarding = () => {
    api.post("/accounts/onboarding/complete").then((res) => {
      hideOnboarding();
    });
  };

  const bookMeeting = () => {
    window.open("https://calendly.com/ted-from-moba/moba-onboarding", "_blank");
  };

  const slackMeeting = () => {
    window.open(
      "https://join.slack.com/t/mobacommunity/shared_invite/zt-270q6w4k4-KH5ijXaB0e99EAt5MWm5lw",
      "_blank"
    );
  };

  return (
    <div className={styles.welcomeCompleteCard}>
      <div className={styles.completeGuide}>
        <div className={styles.heaaderTitleArea}>
          <span className={styles.mainHeader}>Get Started!</span>
          <span
            className={styles.subHeader}
          >{`MOBA 온보딩 세션을\n모두 진행하느라 고생 많으셨습니다.`}</span>
        </div>
        <div className={styles.completeBtnArea}>
          <div className={styles.playmobaBtn} onClick={handleCompletedOnboarding}>
            Play MOBA
          </div>
        </div>
      </div>
      <div className={styles.connectionContainer}>
        <div className={styles.divider}></div>
        <span className={styles.bookMeeting}>
          {`한 번의 설명만으로 새로운 툴을 온전히 활용하는 것은 어렵습니다.\n1:1 온보딩 세션을 신청해주시면 언제든지\n개인의 업무 스타일에 맞는 MOBA 활용법을 설명드리겠습니다.`}
        </span>
        <div className={styles.bookMeetingBtn} onClick={bookMeeting}>
          Book 1:1 Onboarding
        </div>
        <div className={styles.divider}></div>
        <div className={styles.bookmetingSlackArea}>
          <span className={styles.bookMeeting}>
            {`한 번의 설명만으로 새로운 툴을 온전히 활용하는 것은 어렵습니다.\n1:1 온보딩 세션을 신청해주시면 언제든지\n개인의 업무 스타일에 맞는 MOBA 활용법을 설명드리겠습니다.`}
          </span>
          <div className={styles.slack}>
            <img className={styles.slackIcon} src={slackIcon} alt="slack" />
          </div>
        </div>
        <div className={styles.bookMeetingBtn} onClick={slackMeeting}>
          MOBA Slack Community
        </div>
      </div>
    </div>
  );
}

function WelcomeCard({ nextStepOnClick }) {
  return (
    <div className={styles.welcomeCard}>
      <div className={styles.dollorImgArea}>
        <img src={dollorImg} className={styles.dollorImg} />
      </div>
      <div className={styles.textArea}>
        <span className={styles.believer}>Hello, Believer!</span>
        <span className={styles.moba}>
          MOBA의 빌리버가 되어주셔서 감사합니다.
          <br />
          MOBA는 다양한 프로젝트를 가장 쉽게 관리할 수 있는
          <br />
          개인용 프로젝트 관리툴입니다.
        </span>
        <span className={styles.hello}>
          여러분들이 빠르게 업무에 몰입하여
          <br />더 생산적인 업무 경험을 할 수 있도록 많은 피드백 부탁드립니다.
        </span>

        <div className={styles.continue} onClick={() => nextStepOnClick()}>
          Get Onboarding
        </div>
      </div>
    </div>
  );
}

function WelcomOnboarding({ stepsInfo, nextStepOnClick, isFirstStep, previousStepOnClick }) {
  return (
    <div className={styles.onboardingCard}>
      <div className={styles.onboardingGuide}>
        <div className={styles.heaaderTitleArea}>
          <span className={styles.mainHeader}>{stepsInfo.mainHeader}</span>
          <span className={styles.subHeader}>{stepsInfo.subHeader}</span>
          <span className={styles.description}>{stepsInfo.description}</span>
        </div>
        <div className={styles.btnArea}>
          <div className={styles.nextBtn} onClick={() => nextStepOnClick()}>
            Next
          </div>
          <div className={styles.previousArea}>
            <img
              className={styles.previousArrowIcon}
              src={arrowLeft}
              style={{ visibility: `${isFirstStep ? "hidden" : "visible"}` }}
            />
            <div
              className={styles.previousBtn}
              style={{ visibility: `${isFirstStep ? "hidden" : "visible"}` }}
              onClick={() => previousStepOnClick()}
            >
              Previous
            </div>
          </div>
        </div>
      </div>
      <div className={styles.lottieContainer}>
        {stepsInfo.isLottie ? (
          <Lottie animationData={stepsInfo.lottieJson} />
        ) : (
          <img src={stepsInfo.lottieJson} />
        )}
      </div>
    </div>
  );
}

export default function Onboarding({ hideOnboarding }) {
  const [step, setStep] = useState(0);
  const [effect, setEffect] = useState("mount");

  // useEffect(() => {
  //   setTimeout(() => {
  //     setEffect('mount');
  //   }, 500);
  // }, [])

  const nextStepOnClick = () => {
    setStep((current) => current + 1);
  };

  const previousStepOnClick = () => {
    setStep((current) => current - 1);
  };

  const handelHideOnboarding = () => {
    setEffect("unmount");
    setTimeout(() => {
      hideOnboarding();
    }, 500);
  };

  const stepsInfo = [
    {
      isLottie: true,
      lottieJson: step1,
      mainHeader: "To-do List",
      subHeader: `Task Inbox에\n해야할 일을 모두 추가해보세요.`,
      description: "",
    },
    {
      isLottie: true,
      lottieJson: step2,
      mainHeader: `Time\nBlocking`,
      subHeader: `비어 있는 캘린더에 업무들을\n연결하면 놓치지 않을 수 있습니다.`,
      description: `여러 개의 Task를 한번에 추가하는 기능은\n곧 지원될 예정입니다.`,
    },
    {
      isLottie: true,
      lottieJson: step3,
      mainHeader: `Connect\nTask and Project`,
      subHeader: `업무에 프로젝트를 연결하면\n프로젝트별 업무만\n빠르게 관리할 수 있습니다.`,
      description: "",
    },
    {
      isLottie: false,
      lottieJson: step4,
      mainHeader: `Connect\nTask and Project`,
      subHeader: `원하는 대로 프로젝트를 생성하고,\n업무들을 프로젝트 별로 분류해보세요.`,
      description: `서로 다른 성격을 가진 업무일수록 별개의 프로젝트로\n관리하는 것이 효과적입니다.\n예를 들면, 기능 개발 프로젝트, 서비스 리서치, 1:1 미팅 등을\n개별 프로젝트로 관리해보세요.`,
    },
    {
      isLottie: true,
      lottieJson: step5,
      mainHeader: "Knowledge\nManagement",
      subHeader: `업무에서 사용하는 모든 링크와 파일을\n프로젝트별로 쉽게 관리할 수 있습니다.`,
      description: `파일 업로드 기능은 곧 업데이트 될 예정입니다.`,
    },
    {
      isLottie: true,
      lottieJson: step6,
      mainHeader: `Beautiful\nNote-Taking`,
      subHeader: `가볍지만 멋진 노트 기능을\n사용할 수 있습니다.`,
      description: "",
    },
    {
      isLottie: true,
      lottieJson: step7,
      mainHeader: `Integrate\nYour Tools`,
      subHeader: `자주 사용하는 툴을 연동해보세요.`,
      description: `현재 Google Calendar, Gmail, Slack, Jira 연동을 지원합니다.`,
    },
    {
      isLottie: true,
      lottieJson: step8,
      mainHeader: `MOBA Wiki`,
      subHeader: `Wiki 모드로 전환하면 프로젝트에서\n활용한 모든 지식들을 MOBA Wiki에서\n쉽게 관리하고 빠르게 찾을 수 있습니다.`,
      description: "",
    },
  ];

  const stepView = () => {
    if (step === 0) {
      return <WelcomeCard nextStepOnClick={nextStepOnClick} />;
    }
    if (step === 9) {
      return <WelcomCompleteCard hideOnboarding={handelHideOnboarding} />;
    }

    return (
      <WelcomOnboarding
        stepsInfo={stepsInfo[step - 1]}
        nextStepOnClick={nextStepOnClick}
        isFirstStep={step === 1 ? true : false}
        previousStepOnClick={previousStepOnClick}
      />
    );
  };

  return (
    <div
      className={`${styles.onboardingBackground} ${
        effect === "mount" ? styles.mount : styles.unmount
      }`}
    >
      {
        <div
          class={styles.progressBar}
          style={{
            visibility: `${step === 0 || step === 9 ? "hidden" : "visible"}`,
          }}
        >
          <div className={styles.progress} style={{ width: `${step * 50}px` }}></div>
        </div>
      }
      {stepView()}
    </div>
  );
}
