import { useRecoilState } from "recoil";

function useToggle(state) {
  const [value, setValue] = useRecoilState(state);

  const toggle = (newValue) => {
    setValue(newValue);
  };

  return [value, toggle];
}

export default useToggle;
