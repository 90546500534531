export default function formatDateTime(date, timeFormat) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const dayOfWeek = days[date.getDay()];

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  const formatted12Hours = hours % 12 || 12;

  const formatted12HoursStr = formatted12Hours.toString().padStart(2, "0");
  const formatted24HoursStr = hours.toString().padStart(2, "0");

  const minutesStr = minutes.toString().padStart(2, "0");

  return timeFormat === "12-hour"
    ? `${month}. ${day}. ${dayOfWeek}. ${formatted12HoursStr}:${minutesStr} ${ampm}`
    : `${month}. ${day}. ${dayOfWeek}. ${formatted24HoursStr}:${minutesStr}`;
}
