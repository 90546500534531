import { useState } from "react";
import { useRecoilState } from "recoil";
import { projectColorListState } from "../../recoil/account/accountState";
import styles from "./FilterProjectReorderDragLayer.module.css";
const { useDragLayer } = require("react-dnd");

function FilterProjectReorderDragLayer() {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const [integrationImg, setIntegrationImg] = useState(null);

  if (!item || !isDragging || item.dragType !== "filterProject") {
    return null;
  }

  const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 1000,
    left: 0,
    top: 0,
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
    width: "256px",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid var(--hover, rgba(255, 255, 255, 0.08))",
    background:
      "linear-gradient(0deg, var(--hover-big, rgba(255, 255, 255, 0.04)) 0%, var(--hover-big, rgba(255, 255, 255, 0.04)) 100%), #363A3A",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
  };

  return (
    <div style={layerStyles} key={{ title: "project title" }} className={styles.taskBody}>
      <div className={styles.projectGroupBody}>
        <div
          className={styles["projectGroup-body-icon"]}
          style={{
            backgroundColor: `${item.data.color}${item.rowIcon ? "80" : "00"}`,
            boxShadow: "0 0 0 2px " + item.data.color + " inset",
          }}
        ></div>

        <input className={styles["projectGroup-body-title"]} value={item.data.title}></input>
      </div>
    </div>
  );
}

export default FilterProjectReorderDragLayer;
