import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { accountState, projectColorListState } from "../../recoil/account/accountState";

import { PROJECT_LIST_COLOR } from "../../constants/index";
import useApi from "../../services/auth/useApi";
import useApiCalendar from "../../services/calendar/useApiCalendar";
import ResourceAddModal from "../../views/TaskDetail/Link/LinkAddModal";
import styles from "./ProjectCreate.module.css";
import Timeblock from "./Timeblock";

import { v4 as uuid4 } from "uuid";
import projectBackIcon from "../../assets/Wiki/project-back-icon.png";
import bookmarkDefaultImg from "../../assets/Wiki/project-detail-bookmark-add.png";
import { projectListState } from "../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../utils/june/analytics";
import ToolTip from "./Tooltip";

const Month = ({ data, scrollToRef }) => {
  const date = new Date(data);
  const today = new Date();

  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  const todayDay = today.getUTCDate();
  const todayMonth = today.getUTCMonth();
  const todayYear = today.getUTCFullYear();

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekdayAbbreviation = weekdays[date.getUTCDay()];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getUTCMonth()];

  const isToday = day === todayDay && month === todayMonth && year === todayYear;

  useEffect(() => {
    if (isToday && scrollToRef) {
      // isToday로 가는 기능인데 현재 안되서 나중에 추가로 봐야함
      // scrollToRef.current = ref;
    }
  }, [isToday, scrollToRef]);

  const ref = useRef(null);

  return (
    <div ref={ref} className={styles.dayArea}>
      <div className={styles.dayTitle}>{day}</div>
      <div className={styles.dayWeek}>{weekdayAbbreviation}</div>
      <div className={styles.dayToday}>{isToday ? "today" : ""}</div>
      <div className={styles.spacer}></div>
      <div className={styles.monthYear}>
        {monthName} {year}
      </div>
    </div>
  );
};

function AutoExpandingTextarea({ field, dataChange, description }) {
  const [text, setText] = useState("");
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    adjustHeight();
  }, [text]);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.minHeight = "0px";
    if (textarea.scrollHeight > 126) {
      textarea.style.minHeight = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.minHeight = "126px";
    }
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  useEffect(() => {
    if (description) {
      setText(description);
    }
  }, [description]);

  return (
    <textarea
      ref={textareaRef}
      value={text}
      onInput={handleInputChange}
      onBlur={(event) => dataChange(field, event.target.value)}
      className={styles.project__description}
      placeholder="Please give a brief project introduction."
    />
  );
}

function ProjectInfo({ data, loadData }) {
  const api = useApi();

  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);
  const [iconPos, setIconPos] = useState(-100);
  const [projectInfo, setProjectInfo] = useState({
    createdAt: null,
    creator: null,
    id: null,
    title: null,
    projectId: null,
    description: null,
    bookmarks: [],
  });
  const [isResourceAddModal, setIsResourceAddModal] = useState(false);
  const trackCall = useJuneTrackCall();
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  useEffect(() => {
    if (data) {
      setProjectInfo({
        createdAt: data.createdAt || null,
        creator: data.creator || null,
        id: data.id || null,
        title: data.title || null,
        projectId: data.projectId || null,
        description: data.description || null,
        bookmarks: data.bookmarks || null,
      });
    }
  }, [data]);

  const handleDataChange = (field, value) => {
    const newData = { [field]: value };

    updateProjectList((current) => {
      const updatedList = current.map((project) => {
        if (project.id === projectInfo.id) {
          return { ...project, [field]: value };
        }
        return project;
      });
      return updatedList;
    });

    api
      .patch("projects/" + projectInfo.id, newData)
      .then((res) => {
        if (field === "title") {
          trackCall("update_project", { type: "title", location: "wiki" });
        } else if (field === "description" && projectInfo.description !== value) {
          // NOTE 값이 변경될 때에만 동작하도록 설정
          trackCall("update_project", {
            type: "description",
            location: "wiki",
          });
        }
      })
      .catch((error) => {
        loadData(true, true, true);
      });
  };

  const handleResourceAdd = () => {
    setIsResourceAddModal(true);
  };

  const updateBookmark = (key, value) => {
    trackCall("update_project", { type: "knowledge", location: "wiki" });

    if (key === "links") {
      const newItem = {
        title: value.name,
        kind: key,
        data: {
          link: value.url,
        },
      };

      api
        .post(`projects/${projectInfo.id}/bookmarks`, newItem)
        .then((res) => {
          const transformedData = {
            key: res.data.bookmarkId,
            title: res.data.title,
            data: res.data.data,
            kind: res.data.kind,
          };

          const updatedBookmarks = Array.isArray(projectInfo.bookmarks)
            ? [...projectInfo.bookmarks, transformedData]
            : [transformedData];

          setProjectInfo({
            ...projectInfo,
            bookmarks: updatedBookmarks,
          });

          updateProjectList((current) => {
            return current.map((project) => {
              if (project.id === projectInfo.id) {
                return {
                  ...project,
                  bookmarks: updatedBookmarks,
                };
              }
              return project;
            });
          });
        })
        .catch((error) => {
          loadData(true, true, true);
        });
    }
  };

  const handleBlur = (event) => {
    handleDataChange("title", event.target.value);
  };

  const onChange = (event) => {
    setProjectInfo((current) => ({
      ...current,
      title: event.target.value,
    }));
  };

  const handleIconEnter = (index) => (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setVisibleTooltipIndex(index);
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setVisibleTooltipIndex(null);
    setIconPos(-100);
  };

  const handleMove = (url) => () => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://www." + url, "_blank");
    }
  };

  const handleDeleteLink = (item) => {
    api
      .delete(`projects/${data.id}/bookmarks/${item.key}`)
      .then(() => {
        loadData();
      })
      .catch((error) => {
        loadData(true, true, true);
      });

    const newBookmarks = new Map(projectInfo.bookmarks.map((bm) => [bm.key, bm]));
    newBookmarks.delete(item.key);
    const updatedBookmarks = Array.from(newBookmarks.values());
    setProjectInfo({ ...projectInfo, bookmarks: updatedBookmarks });

    updateProjectList((current) => {
      return current.map((project) => {
        if (project.id === projectInfo.id) {
          return { ...project, bookmarks: newBookmarks };
        }
        return project;
      });
    });
  };

  return (
    <>
      <div className={styles.project__info}>
        <input
          type="text"
          className={styles.projectUntitle}
          placeholder="Untitled Project"
          value={projectInfo?.title}
          onChange={onChange}
          onBlur={handleBlur}
        />

        <AutoExpandingTextarea
          field="description"
          dataChange={handleDataChange}
          description={projectInfo?.description}
        />
        <div className={styles.project__line}></div>
        <span className={styles.bookmark__title}>Bookmarks</span>

        <div className={styles["setting-property-resource"]}>
          {projectInfo &&
            projectInfo.bookmarks &&
            projectInfo.bookmarks.map((item, index) => (
              <div
                key={index}
                className={styles["resource-item"]}
                onMouseEnter={handleIconEnter(index)}
                onMouseLeave={handleIconLeave}
                onClick={handleMove(item.data?.link)}
              >
                <div
                  className={styles["resource-item-favicon"]}
                  style={{
                    backgroundImage:
                      "url(https://www.google.com/s2/favicons?sz=64&domain_url=" +
                      item.data?.link +
                      ")",
                  }}
                ></div>
                <div
                  className={styles["resource-item-delete"]}
                  style={{
                    visibility: `${visibleTooltipIndex === index ? "visible" : "hidden"}`,
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteLink(item);
                  }}
                ></div>
                <ToolTip
                  key={index}
                  text={item.title}
                  isVisible={visibleTooltipIndex === index}
                  style={{ top: `${iconPos}px` }}
                />
              </div>
            ))}
          <div className={styles["resource-item"]} onClick={handleResourceAdd}>
            <img className={styles.bookmark__default} src={bookmarkDefaultImg} />
          </div>
        </div>
      </div>
      {isResourceAddModal && (
        <ResourceAddModal
          onSave={(key, value) => updateBookmark(key, value)}
          onClose={() => setIsResourceAddModal(false)}
        />
      )}
    </>
  );
}

function TimeLineDot({ color }) {
  return <div className={styles.dot} style={{ backgroundColor: color }}></div>;
}

function TimeLineEmpty({ dotColor }) {
  return (
    <div className={styles.guide__message__area}>
      <TimeLineDot color={dotColor} />
      <div className={styles.guide__message__box}>
        If you block the time with tasks, they will be grouped into a timeline here!
      </div>
    </div>
  );
}

function TimeLine({ dotColor, calendarTaskEvent }) {
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  const scrollToRef = useRef(null);

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToRef]);

  useEffect(() => {
    const updateParentHeight = () => {
      if (parentRef.current) {
        setParentHeight(parentRef.current.scrollHeight);
      }
    };

    updateParentHeight();

    window.addEventListener("resize", updateParentHeight);

    return () => {
      window.removeEventListener("resize", updateParentHeight);
    };
  }, [calendarTaskEvent]);

  return (
    <>
      <div className={styles.project__timeline} ref={parentRef}>
        <div className={styles.timeline__vertical} style={{ height: parentHeight }}></div>
        <div className={styles.timeBlockListArea}>
          {calendarTaskEvent == null || Object.keys(calendarTaskEvent).length === 0 ? (
            <TimeLineEmpty dotColor={dotColor} />
          ) : (
            Object.keys(calendarTaskEvent)
              .sort((a, b) => new Date(a) - new Date(b))
              .map((date) => (
                <div key={date} className={styles.timeBlockArea}>
                  <div className={styles.monthArea}>
                    <Month data={date} scrollToRef={scrollToRef} />
                  </div>
                  {calendarTaskEvent[date].map((data, index) => (
                    <Timeblock key={index} dotColor={dotColor} data={data} />
                  ))}
                </div>
              ))
          )}
        </div>
      </div>
    </>
  );
}

export default function ProjectCreate({ onDismissClick, loadData, projectDetail }) {
  const api = useApi();
  const [projectInfo, setProjectInfo] = useState(projectDetail);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [dotColor, setDotColor] = useState("");

  const [calendarTaskEvent, setCalendarTaskEvent] = useState(null);
  const [projectList, updateProjectList] = useRecoilState(projectListState);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);

  const calendarApi = useApiCalendar();
  const trackCall = useJuneTrackCall();

  const handleAddProject = () => {
    const newItem = {
      id: `${accountData.accountInfo.accounts[0].email}-${uuid4()}`,
      creator: `${accountData.accountInfo.accounts[0].email}`,
      title: "Untitled Project",
      color: PROJECT_LIST_COLOR[projectList.length % PROJECT_LIST_COLOR.length],
      status: "InProgress",
      description: "",
    };

    setProjectColorListState((current) => {
      return { ...current, [newItem.id]: newItem.color };
    });

    updateProjectList((current) => {
      return [newItem, ...current];
    });

    api
      .post("projects", newItem)
      .then((res) => {
        setProjectInfo(res.data);
        setDotColor(res.data.color);
      })
      .catch((error) => {
        loadData(true, true, true);
      });
  };

  useEffect(() => {
    if (projectInfo === null) {
      handleAddProject();
    } else {
      trackCall("view_project_detail");
      setProjectInfo(projectDetail);
      setDotColor(projectDetail.color);
      taskLoadData();
    }
  }, []);

  const taskLoadData = async () => {
    try {
      const refreshRes = await api.post("auth/oauth2/refresh", {
        kind: "account",
        key: projectDetail.creator,
      });
      const calendarRes = await calendarApi.get("", {
        headers: {
          Authorization: `Bearer ${refreshRes.data.accessToken}`,
        },
        params: {
          privateExtendedProperty: `projectId=${projectInfo.id}`,
          maxResults: 2500,
          singleEvents: true,
        },
      });

      const eventsByDate = {};

      calendarRes.data.items.forEach((event) => {
        let startDateTime;
        let endDateTime;
        let isAllDay = false;

        // 종일 이벤트 확인 (start와 end가 date 형식인 경우)
        if (event.start.date && event.end.date) {
          startDateTime = new Date(event.start.date);
          endDateTime = new Date(event.end.date);
          isAllDay = true;
        } else {
          // 시간 지정 이벤트
          startDateTime = new Date(event.start.dateTime);
          endDateTime = new Date(event.end.dateTime);
          isAllDay = endDateTime - startDateTime >= 86400000;
        }

        if (!isAllDay) {
          // 하루 이내 이벤트
          const dateKey = startDateTime.toISOString().split("T")[0];
          if (!eventsByDate[dateKey]) {
            eventsByDate[dateKey] = [];
          }
          eventsByDate[dateKey].push({ ...event, isAllDay });
        } else {
          // 종일 또는 여러 날에 걸친 이벤트
          let currentDate = new Date(startDateTime);
          while (currentDate < endDateTime) {
            const dateKey = currentDate.toISOString().split("T")[0];
            if (!eventsByDate[dateKey]) {
              eventsByDate[dateKey] = [];
            }
            eventsByDate[dateKey].push({ ...event, isAllDay });
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
      });

      Object.keys(eventsByDate).forEach((date) => {
        eventsByDate[date].sort(
          (a, b) =>
            new Date(a.start.dateTime || a.start.date) - new Date(b.start.dateTime || b.start.date)
        );
      });

      setCalendarTaskEvent(eventsByDate);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.project__create__area}>
      <div className={styles.header} onClick={() => onDismissClick()}>
        <img className={styles.header__button} src={projectBackIcon} />
      </div>
      <div className={styles.project__body}>
        {<ProjectInfo key="proejctInfo" data={projectInfo} loadData={loadData} />}
        {<TimeLine key="timeLine" dotColor={dotColor} calendarTaskEvent={calendarTaskEvent} />}
      </div>
    </div>
  );
}
