import { useQueries } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "../recoil/auth/tokenState";

import axios from "axios";
import { calendarViewBaseDateState } from "../recoil/calendar/calendarState";

export const calendarKey = {
  all: ["calendar"],
  calendarEvent: (email, params) => [...calendarKey.all, "calendarEvent", email, params],
};

export const useCalendarEventQueries = (emailList) => {
  const token = useRecoilValue(tokenState);
  const calendarViewBaseDate = useRecoilValue(calendarViewBaseDateState);

  const firstDayOfMonth = new Date(
    calendarViewBaseDate.getFullYear(),
    calendarViewBaseDate.getMonth() - 1,
    1
  );
  const lastDayOfMonth = new Date(
    calendarViewBaseDate.getFullYear(),
    calendarViewBaseDate.getMonth() + 2,
    0
  );

  const api = axios.create({
    headers: {
      Authorization: `Bearer ${token.accessToken}`,
    },
  });
  const params = {
    timeMin: firstDayOfMonth.toISOString(),
    timeMax: lastDayOfMonth.toISOString(),
    maxResults: 2500,
    singleEvents: true,
  };

  return useQueries({
    queries: emailList.map((email) => ({
      queryKey: calendarKey.calendarEvent(email, params),
      queryFn: () =>
        api
          .get(`https://www.googleapis.com/calendar/v3/calendars/${email}/events`, {
            params,
          })
          .then((res) => res.data),
      retry: 0,
    })),
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        error: results.map((result) => result.error),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};
