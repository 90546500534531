function isBlockEmpty(block) {
  return (
    (!block.content || block.content.length === 0) &&
    (!block.children || block.children.length === 0)
  );
}

export function isNoteEmpty(note) {
  if (typeof note === "string" && note.trim() !== "") {
    try {
      const blocks = JSON.parse(note);
      return Array.isArray(blocks) && blocks.every(isBlockEmpty);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return true; // NOTE JSON 파싱 실패 시 비어있다고 간주
    }
  }
  return true; // NOTE 문자열이 아니거나 빈 문자열이면 비어있다고 간주
}

export const isNoteDataChanged = (initialNote, latestNote) => {
  const initialContent =
    initialNote && !isNoteEmpty(initialNote)
      ? initialNote // NOTE JSON 문자열 그대로 사용
      : "";
  const latestContent =
    latestNote && !isNoteEmpty(latestNote)
      ? latestNote // NOTE JSON 문자열 그대로 사용
      : "";

  return initialContent !== latestContent;
};
