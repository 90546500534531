import { useRef, useEffect } from "react";
import styles from "./SidebarModal.module.css";
export default function SidebarModal({ x, y, onDelete, onDuplicate, onClose }) {
  const wrapper = document.querySelector(".rbc-time-content");
  const wrapperBottom = wrapper ? wrapper.getBoundingClientRect().bottom : window.innerHeight;
  const modalHeight = 60;
  const modalStyle = {
    top: wrapperBottom - modalHeight < y ? y - modalHeight : y,
    left: x,
  };

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (modalRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div style={modalStyle} className={styles["modal"]} ref={modalRef}>
      <button onClick={onDuplicate}>Duplicate</button>
      <button onClick={onDelete}>Delete</button>
    </div>
  );
}
