// src/services/useFetchIntegrations.js
import { useState, useEffect } from "react";
import useApi from "../auth/useApi";

const useFetchIntegrations = () => {
  const api = useApi();
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        await api.get("/integrations/status").then((res) => {
          setIntegrations(res.data.integrations);
          setLoading(false);
        });
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchIntegrations();
  }, []);

  return { integrations, loading, error };
};

export default useFetchIntegrations;
