import { ReactComponent as VisibilityDeepworkIcon } from "../../../assets/TaskDetail/visibility-deepwork.svg";
import { ReactComponent as VisibilityInvisibleIcon } from "../../../assets/TaskDetail/visibility-invisible.svg";

// private - busy (deep work)의 경우 visibility값만 넘어옴
// private - free (invisible)의 경우 visibility, transparency 두개가 함께 넘어옴
export default function iconForVisibility(visibility, transparency) {
  if (visibility === "private" && transparency === "transparent") {
    return (
      <VisibilityInvisibleIcon
        style={{
          fill: "#7C7D7D",
          width: "100%",
          height: "100%",
        }}
      />
    );
  } else if (visibility === "private" && (transparency === "opaque" || transparency == null)) {
    return <VisibilityDeepworkIcon style={{ fill: " #FFFFFF", width: "100%", height: "100%" }} />;
  }
}
