import { useEffect, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import { projectColorListState } from "../../recoil/account/accountState";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import useApi from "../../services/auth/useApi";
import iconForVisibility from "../../utils/taskDetail/visibility/iconForVisibility";

import { timeFormatState } from "../../recoil/calendar/settingCalendar";
import { useJuneTrackCall } from "../../utils/june/analytics";
import styles from "./Timeblock.module.css";

function calculateHeight(startDatetime, endDatetime) {
  const start = new Date(startDatetime.dateTime);
  const end = new Date(endDatetime.dateTime);

  const timeInterval = end - start;
  const minTimeInterval = 15 * 60 * 1000;

  if (timeInterval >= 4 * 60 * 60 * 1000) {
    return 320;
  } else if (timeInterval <= minTimeInterval) {
    return 40;
  } else {
    return Math.floor((timeInterval / (30 * 60 * 1000)) * 40);
  }
}

const TimeComponent = ({ data, isAllDay }) => {
  const date = new Date(data);
  const timeFormat = useRecoilValue(timeFormatState);

  if (isAllDay) {
    return <div>all-day</div>;
  }
  const hours24 = date.getHours();
  let hours12 = hours24 % 12;
  hours12 = hours12 ? hours12 : 12;

  const ampm = hours24 >= 12 ? "pm" : "am";

  const minutes = date.getMinutes();
  const strMinutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime12 = ampm + " " + hours12 + ":" + strMinutes;
  const formattedTime24 = hours24 + ":" + strMinutes;

  return <div>{timeFormat === "12-hour" ? formattedTime12 : formattedTime24}</div>;
};

const TaskRow = ({ data, dotColor, onTaskDetail }) => {
  // const [accountData, setAccountData] = useRecoilState(accountState);
  const api = useApi();
  const [isDone, setIsDone] = useState(
    data.extendedProperties?.private?.itemStatus === "Completed"
  );
  const [integrationImg, setIntegrationImg] = useState(null);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const [hover, setHover] = useState(false);
  const [rowIcon, setRowIcon] = useState(true);
  const trackCall = useJuneTrackCall();

  useEffect(() => {
    const integrationData = data.extendedProperties?.private?.integration;
    if (integrationData && integrationData !== "") {
      try {
        const integrationObj = JSON.parse(integrationData);
        setIntegrationImg(integrationObj.provider);
      } catch (error) {
        console.error("JSON parsing error:", error);
      }
    }
  }, []);

  const height = calculateHeight(data.start, data.end);

  const style = {
    borderRadius: "6px",
    border: `1px solid ${dotColor}`,
    background: `linear-gradient(0deg, ${dotColor}1A 0%, ${dotColor}1A 100%), #242626`,
    height: `${height}px`,
  };

  const bodyIconStyle = {
    backgroundColor: hover
      ? `${dotColor}`
      : `${dotColor}${isDone ? (rowIcon ? "" : "26") : rowIcon ? "26" : ""} `,
    boxShadow: `0 0 0 2px ${dotColor} inset`,
  };

  const handleDoneAction = () => {
    setIsDone((current) => !current);
    api
      .patch("tasks/" + data.id + "/" + (isDone ? "undone" : "done"), "", {
        headers: { "X-Requester": data.extendedProperties?.private?.creator },
      })
      .then(() => {
        !isDone && trackCall("done_block", { location: "wiki" });
      });
  };

  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  return (
    <>
      <div
        className={styles.timeBlockBox}
        style={style}
        // 2023.12.20 추후에 다시 주석 풀고 TaskDetail나오도록 수정해야함
        // onClick={(event) => onTaskDetail(event)}
      >
        <div className={styles.taskList__body} key={{ title: "task title" }}>
          {data.hangoutLink == null ? (
            <div
              className={`${styles.taskListBodyIcon} ${
                styles[`${isDone ? "checkboxCompleted" : null}`]
              }`}
              style={bodyIconStyle}
              onMouseEnter={handleIconEnter}
              onMouseLeave={handleIconLeave}
              onClick={handleDoneAction}
            ></div>
          ) : (
            <div className={styles.googleMeetIcon}></div>
          )}
          <div className={styles.taskList__body__title}>{data.summary}</div>
          {data.visibility && <div>{iconForVisibility(data.visibility, data.transparency)}</div>}
          {integrationImg && (
            <div
              className={`${styles["integration"]} ${
                integrationImg == "gmail"
                  ? styles["integration-gmail"]
                  : integrationImg == "jira"
                    ? styles["integration-jira"]
                    : integrationImg == "slack" && styles["integration-slack"]
              }`}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

function TimeLineDot({ color }) {
  return <div className={styles.dot} style={{ backgroundColor: color }}></div>;
}

function Block({ dotColor, data }) {
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const handleTaskClick = () => {
    setTaskDetail({
      isVisible: true,
      data: selectedEvent,
      modalPosition: modalPosition,
      loadData: null,
    });
  };

  useEffect(() => {
    if (modalPosition.x !== 0 || modalPosition.y !== 0) {
      handleTaskClick();
    }
  }, [selectedEvent]);

  const handleItemContextMenu = (event) => {
    const taskDetailX = window.innerWidth - 90 - 480;
    const taskDetailY = window.innerHeight - 10 - 923;

    let taskData;
    let calendarData;

    if (data.extendedProperties) {
      taskData = {
        creator: data.extendedProperties.private.creator,
        itemStatus: data.extendedProperties.private.itemStatus,
        projectId: data.extendedProperties.private.projectId
          ? data.extendedProperties.private.projectId
          : null,
        links: data.extendedProperties.private.links
          ? JSON.parse(data.extendedProperties.private.links)
          : [],
        files: data.extendedProperties.private.files ? data.extendedProperties.private.files : null,
        note: data.extendedProperties.private.note ? data.extendedProperties.private.note : null,
        recurrence: data.extendedProperties.private.recurrence
          ? data.extendedProperties.private.recurrence
          : null,
        title: data.extendedProperties.private.title ? data.extendedProperties.private.title : null,
        start: data.start.date || new Date(data.start.dateTime),
        startTimeZone: data.start.timeZone ? data.start.timeZone : null,
        end: data.end.date || new Date(data.end.dateTime),
        endTimeZone: data.start.timeZone ? data.start.timeZone : null,
      };
    }

    setSelectedEvent({ ...taskData, ...calendarData });
    setModalPosition({ x: taskDetailX, y: taskDetailY });
  };

  return (
    <>
      <div className={styles.timeBlockOnly}>
        <div className={styles.dateTimeArea}>
          <TimeComponent data={data.start.dateTime || data.start.date} isAllDay={data.isAllDay} />
        </div>
        <TimeLineDot color={dotColor} />
        <TaskRow
          key={data.id}
          data={data}
          dotColor={dotColor}
          onTaskDetail={handleItemContextMenu}
        />
      </div>
    </>
  );
}

export default function Timeblock({ dotColor, data }) {
  return <Block dotColor={dotColor} data={data} />;
}
