import axios from "axios";
import { useRecoilState } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";
import { useEffect, useRef } from "react";
import { saveToLocalStorage } from "../../utils/localStorage/localStorage";

const isDev = process.env.NODE_ENV !== "production";

// 토큰 갱신 관리 변수
let isRefreshing = false;
let failedQueue = [];

// 토큰 갱신 관리 큐
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

async function refreshAccessToken(token, setToken) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}auth/tokens/refresh`, {
      mAccessToken: token.mAccessToken,
      mRefreshToken: token.mRefreshToken,
    });

    const newToken = {
      ...token,
      mAccessToken: response.data.mAccessToken,
      mRefreshToken: response.data.mRefreshToken,
    };
    setToken(newToken);
    saveToLocalStorage("tokenState", newToken);

    return newToken;
  } catch (error) {
    console.error("토큰 갱신 중 오류 발생:", error);
    setToken({
      mAccessToken: null,
      mRefreshToken: null,
      accessToken: null,
    });
    // saveToLocalStorage("tokenState", {
    //   mAccessToken: null,
    //   mRefreshToken: null,
    //   accessToken: null,
    // });
    window.location.href = "/";
    throw error;
  }
}

const useApi = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const tokenRef = useRef(token);

  useEffect(() => {
    tokenRef.current = token;
  }, [token, setToken]);

  const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER,
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // 요청 인터셉터를 사용하여 Authorization 헤더 추가
  api.interceptors.request.use(
    async (config) => {
      if (!token.mAccessToken) {
        setToken({
          mAccessToken: null,
          mRefreshToken: null,
          accessToken: null,
        });
        window.location.href = "/";
      }
      config.headers.Authorization = `Bearer ${tokenRef.current.mAccessToken}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  // 응답 인터셉터를 사용하여 토큰 갱신 처리
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const { status } = error.response;

      if ((status === 401 || status === 444) && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newToken = await refreshAccessToken(token, setToken);
          processQueue(null, newToken.mAccessToken);

          originalRequest.headers["Authorization"] = "Bearer " + newToken.mAccessToken;
          saveToLocalStorage("tokenState", newToken);

          return axios(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export default useApi;
