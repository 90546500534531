import { useEffect, useRef, useState } from "react";
import useApi from "../../../services/auth/useApi";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import TrashPopup from "../TrashPopup/TrashPopup";

import { ReactComponent as FeedbackIcon } from "../../../assets/ProjectFilter/feedback-icon.svg";
import { ReactComponent as NewFeatureIcon } from "../../../assets/ProjectFilter/changelog.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/ProjectFilter/settings-icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/ProjectFilter/trash-icon.svg";
import ButtonWithTooltip from "./ButtonWithTooltip";
import { accountState } from "../../../recoil/account/accountState";
import { useRecoilState } from "recoil";

import styles from "./ProjectFilterIcons.module.css";

const ProjectFilterIcons = ({ settingState, onTrashClick, buttonRef }) => {
  // useApi hook
  const api = useApi();

  // State variables
  const [isTrashPopupOn, setIsTrashPopupOn] = useState(false);
  const [isNewFeature, setIsNewFeature] = useState(false);
  const [accountData, setAccountData] = useRecoilState(accountState);

  // Constants
  const trackCall = useJuneTrackCall();

  // Event handlers
  const handleTrashClick = () => {
    setIsTrashPopupOn(!isTrashPopupOn);
  };

  const closeTrashPopup = () => {
    setIsTrashPopupOn(false);
  };

  const handleFeedbackClick = () => {
    trackCall("click_feedback");

    const url = "https://feedback.moba.works/";
    window.open(url, "_blank");
  };

  const handleNewFeatureClick = () => {
    trackCall("click_update");

    const url = "https://feedback.moba.works/changelog";
    window.open(url, "_blank");

    api.post("/feature/check").then((res) => {});

    setIsNewFeature(false);
  };

  // Effects
  useEffect(() => {
    if (accountData != null) {
      const [primaryAccountInfo] = accountData.accountInfo.accounts.filter((account) => {
        return account.type === "primary";
      });
      setIsNewFeature(accountData.accountInfo.hasNewFeature);
    }
  }, [accountData]);

  return (
    <div className={styles["projectfilter_icons"]}>
      {accountData && isNewFeature && (
        <ButtonWithTooltip
          icon={NewFeatureIcon}
          tooltipText="New Feature"
          onClick={handleNewFeatureClick}
          buttonClass={styles["new-feature__button"]}
          iconClass={styles["new-feature__icon"]}
        />
      )}

      <ButtonWithTooltip
        icon={FeedbackIcon}
        tooltipText="Product Feedback"
        onClick={handleFeedbackClick}
        buttonClass={styles["feedback__button"]}
        iconClass={styles["feedback__icon"]}
      />

      <ButtonWithTooltip
        icon={SettingsIcon}
        tooltipText="Settings"
        onClick={settingState}
        buttonClass={styles["settings__button"]}
        iconClass={styles["settings__icon"]}
      />

      <div
        className={`${styles.button} ${styles["trash__button"]}`}
        onClick={onTrashClick}
        ref={buttonRef}
      >
        <TrashIcon className={styles["trash__icon"]} />
        <div className={styles.tooltip}>Trash</div>
      </div>
    </div>
  );
};
export default ProjectFilterIcons;
