import { useEffect, useRef } from "react";
import { InboxMoreType } from "../../constants/inboxMoreType";
import { saveToLocalStorage } from "../../utils/localStorage/localStorage";
import styles from "./DoneModal.module.css";

export default function DoneModal({
  onClose,
  handleCurrentMoreType,
  currentMoreType,
  doneModalRef,
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !doneModalRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleClick = (moreType) => {
    onClose();
    const type = currentMoreType === moreType.type ? null : moreType.type;
    handleCurrentMoreType(type);
    saveToLocalStorage("moreType", type);
  };

  return (
    <div className={styles.doneTypeListWrapper} ref={modalRef}>
      <ul className={styles.doneTypeList}>
        {Object.values(InboxMoreType).map((moreType) => (
          <li onClick={() => handleClick(moreType)} key={moreType.type}>
            <span
              className={`${styles.checkedIcon} ${
                moreType.type === currentMoreType ? styles.checked : ""
              }`}
            ></span>
            <span>{moreType.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
