import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useApi from "../../../services/auth/useApi";
import ActionMenuModal from "../../Modal/ActionMenuModal";
import styles from "./ProjectGroup.module.css";

import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { v4 as uuid4 } from "uuid";
import { PROJECT_LIST_COLOR } from "../../../constants/index";
import { accountState, projectColorListState } from "../../../recoil/account/accountState";
import { filteringProjectIdListState } from "../../../recoil/projects/filteringProjectIdListState";
import { projectListState } from "../../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../../utils/june/analytics";
import FilterProjectReorderDragLayer from "../FilterProjectReorderDragLayer";

const ProjectRow = ({
  index,
  data,
  onDataChange,
  onDataDelete,
  onChangeColor,
  expand,
  onExpandClick,
  isDone,
  loadProjects,
  onProjectsReorderChange,
}) => {
  const [rowIcon, setRowIcon] = useState(true);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isActionMenuModalOn, setIsActionMenuModalOn] = useState(false);
  const [tempData, setTempData] = useState(data.title);

  const projectRef = useRef(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [filteringProjectIdList, setFilteringProjectIdList] = useRecoilState(
    filteringProjectIdListState
  );
  const [isProjectGroupSelected, setIsProjectGroupSelected] = useState(false);
  const [isProjectGroupHoverd, setIsProjectGroupHoverd] = useState(false);
  const [projectList, updateProjectList] = useRecoilState(projectListState);
  const ref = useRef(null);
  const [prevIndex, setPrevIndex] = useState(index);
  const [inputFocusedId, setInputFocusedId] = useState(null);
  const api = useApi();
  const trackCall = useJuneTrackCall();

  useEffect(() => {
    setTempData(data.title);
  }, [data.title]);

  const handleInputChange = (e) => {
    setTempData(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value === "") {
      setTempData(data.title);
      projectRef.current.blur();
    } else if (e.key === "Enter") {
      onDataChange(data, tempData);
      projectRef.current.blur();
    } else if (e.key === "Escape") {
      setTempData(data.title);
      projectRef.current.blur();
    }
  };

  const handleInputBlur = (e) => {
    e.target.readOnly = true;
    setInputFocusedId(null);
    if (e.target.value === "") {
      setTempData(data.title);
    } else {
      onDataChange(data, tempData);

      if (data.title != tempData) {
        trackCall("update_project", {
          location: "project_filter",
          type: "title",
        });
      }
    }
  };

  const handleProcessIsDone = () => {
    setIsActionMenuModalOn(false);
    handleDoneOrUnDone();
    trackCall("complete_project", { location: "project_filter" });
  };

  const handleDoneOrUnDone = () => {
    updateProjectList((currentProjects) => {
      const updatedProjects = currentProjects.map((project) => {
        if (project.id === data.id) {
          return { ...project, status: "Completed" };
        }
        return project;
      });
      return updatedProjects;
    });
    api.patch("projects/" + data.id + "/" + (isDone ? "undone" : "done")).then(() => {});
  };

  const handleDelete = () => {
    setIsActionMenuModalOn(false);
    setIsProjectGroupSelected(false);
    onDataDelete(data);
  };

  const handleChangeColor = (colorValue) => {
    setIsActionMenuModalOn(false);
    setIsProjectGroupSelected(false);
    onChangeColor(data, colorValue);
  };

  const handleItemContextMenu = (e) => {
    e.preventDefault();

    if (expand) {
      projectRef.current.blur();
    }

    const { clientX, clientY } = e;
    setModalPosition({ x: clientX, y: clientY });
    setIsActionMenuModalOn(true);
    setIsProjectGroupSelected(true);
  };

  const handleIconEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2 - 8;

    setHoverPosition({
      x: rect.x * 2,
      y: centerY,
    });
    setIsProjectGroupHoverd(true);
  };

  const handleIconLeave = (e) => {
    setIsProjectGroupHoverd(false);
  };

  const handleSetRowIcon = () => {
    const newItem = data.id;
    const updateProjectList = (newItem, shouldBeAdded) => {
      setFilteringProjectIdList((prevList) => {
        if (!shouldBeAdded) {
          return prevList.some((item) => item === newItem) ? prevList : [...prevList, newItem];
        } else {
          return prevList.filter((item) => item !== newItem);
        }
      });
      loadProjects();
    };

    updateProjectList(newItem, !rowIcon);
    setRowIcon(!rowIcon);
  };

  const handleClose = () => {
    setIsActionMenuModalOn(false);
    setIsProjectGroupSelected(false);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    type: "project",
    item: { dragType: "filterProject", data: data, rowIcon: rowIcon },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (prevIndex !== index) {
      const animation = ref.current.animate(
        [
          { transform: `translateY(${(prevIndex - index) * 100}%)` },
          { transform: "translateY(0%)" },
        ],
        {
          duration: 150,
          easing: "ease-in-out",
        }
      );

      animation.onfinish = () => {
        setPrevIndex(index);
      };
    }
  }, [index, prevIndex]);

  const projectHover = useCallback(
    (item, monitor) => {
      if (!ref.current || isDragging) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const hoverIndex = index;
      const dragIndex = projectList.findIndex((project) => project.id === item.data.id);

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveProject(dragIndex, hoverIndex);
    },
    [index, projectList, isDragging]
  );

  const [, drop] = useDrop({
    accept: "project",
    hover: projectHover,
    drop(props, monitor) {
      onProjectsReorderChange();
    },
  });

  const moveProject = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex === hoverIndex) {
        return;
      }

      const dragItem = projectList[dragIndex];

      updateProjectList((prevProjects) => {
        const updatedProjects = [...prevProjects];
        updatedProjects.splice(dragIndex, 1);
        updatedProjects.splice(hoverIndex, 0, dragItem);
        return updatedProjects;
      });
    },
    [projectList, updateProjectList]
  );

  drag(drop(ref));

  const handleEdit = () => {
    if (projectRef.current) {
      setIsProjectGroupSelected(false);
      setIsActionMenuModalOn(false);
      setInputFocusedId(data.id);

      if (!expand) {
        onExpandClick();
      }
    }
  };

  useEffect(() => {
    if (expand && data.id === inputFocusedId) {
      setTimeout(() => {
        projectRef.current.readOnly = false;
        projectRef.current.focus();
      }, 200);
    }
  }, [expand, inputFocusedId, setInputFocusedId]);

  return (
    <>
      <div
        ref={ref}
        className={`${styles.projectGroupBody} ${
          expand && isProjectGroupSelected && styles.projectGroupBodySelected
        }
        ${expand && isProjectGroupHoverd && styles.projectGroupBodyHoverd}
        ${styles[`${isDragging ? "isDragging" : null}`]}
        ${styles[`${inputFocusedId !== null ? "projectGroup-add-focustWithin" : null}`]}
   
        `}
        onContextMenu={handleItemContextMenu}
        onMouseEnter={handleIconEnter}
        onMouseLeave={handleIconLeave}
      >
        <div
          className={styles["projectGroup-body-icon"]}
          style={{
            backgroundColor: `${data.color}${rowIcon ? "80" : "00"}`,
            boxShadow: "0 0 0 2px " + data.color + " inset",
          }}
          onClick={handleSetRowIcon}
        >
          {!expand && isProjectGroupHoverd ? (
            <HoverContent position={hoverPosition}>{data.title}</HoverContent>
          ) : null}
        </div>
        {!expand && (
          <div
            className={`${styles.projectGroupBodyIconOuterCircle}
              ${!expand && isProjectGroupSelected && styles.projectGroupBodyIconOuterCircleSelected}
              ${!expand && isProjectGroupHoverd && styles.projectGroupBodyIconOuterCircleHovered}`}
            onClick={handleSetRowIcon}
            ref={projectRef}
          />
        )}

        {expand && (
          <input
            className={styles["projectGroup-body-title"]}
            value={tempData}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={handleInputBlur}
            readOnly={true}
            ref={projectRef}
          ></input>
        )}
      </div>
      {isActionMenuModalOn && (
        <ActionMenuModal
          x={modalPosition.x}
          y={modalPosition.y}
          onProcessDone={handleProcessIsDone}
          onDelete={handleDelete}
          onClose={handleClose}
          onChangeColor={handleChangeColor}
          isDone={isDone}
          projectColor={data.color}
          onEdit={handleEdit}
          type="sidebarProject"
        />
      )}
      {isDragging &&
        createPortal(
          <div style={{ cursor: "pointer" }}>
            <FilterProjectReorderDragLayer />
          </div>,
          document.body
        )}
    </>
  );
};

export default function ProjectGroup({ expand, onExpandClick, loadProjects, isDone }) {
  const [accountData] = useRecoilState(accountState);
  const [groupFold, setGroupFold] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [projectAddClick, setProjectAddClick] = useState(false);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);

  // scroll 숨기기
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const trackCall = useJuneTrackCall();
  const inputRef = useRef(null);
  const [projectList, updateProjectList] = useRecoilState(projectListState);
  const [isProjectRowHoverd, setIsProjectRowHoverd] = useState(false);
  const primaryAccountInfo = accountData?.accountInfo?.accounts.find(
    (account) => account.type === "primary"
  );
  const api = useApi();

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.projectGroup_expand}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if (projectAddClick && inputRef.current) {
      inputRef.current.focus();
    }
  }, [projectAddClick]);

  useEffect(() => {
    if (expand && projectAddClick) {
      setProjectAddClick(true);
    } else {
      setProjectAddClick(false);
    }
  }, [expand]);

  const handleProjectAddClick = () => {
    if (!expand) onExpandClick();
    setProjectAddClick(true);
  };

  const handleAddProject = (e) => {
    if (e.key === "Enter" && newProjectName.trim() !== "") {
      const primaryAccountInfo = accountData.accountInfo.accounts.find(
        (account) => account.type === "primary"
      );

      const newItem = {
        id: `${primaryAccountInfo.email}-${uuid4()}`,
        creator: primaryAccountInfo.email,
        title: newProjectName,
        color:
          PROJECT_LIST_COLOR[
            projectList.filter((item) => item.status === "InProgress").length %
              PROJECT_LIST_COLOR.length
          ],
        status: "InProgress",
      };

      updateProjectList((current) => {
        return [newItem, ...current];
      });

      setProjectColorListState((current) => {
        return { ...current, [newItem.id]: newItem.color };
      });

      api
        .post("projects", newItem)
        .then((res) => {
          trackCall("create_project", { location: "project_filter" });
          setProjectAddClick(true);
        })
        .catch((error) => {
          loadProjects();
        });
    } else if (e.key === "Enter" || e.key === "Escape") {
      setProjectAddClick(false);
      setNewProjectName("");
      inputRef.current.blur();
    } else return;
    setProjectAddClick(false);
    setNewProjectName("");
    inputRef.current.blur();
  };

  const handleDataChange = (rowData, newData) => {
    api
      .patch("projects/" + rowData.id, {
        title: newData,
      })
      .then(() => {
        updateProjectList((prevProjects) => {
          return prevProjects.map((project) => {
            if (project.id === rowData.id) {
              return { ...project, title: newData };
            }
            return project;
          });
        });
      })
      .catch((error) => {
        loadProjects();
      });
  };

  const handleDataDelete = (rowData) => {
    updateProjectList((current) => {
      const updatedProjects = current.filter((project) => project.id !== rowData.id);
      return updatedProjects;
    });
    api.patch("projects/" + rowData.id + "/mark").then(() => {
      trackCall("delete_project", { location: "project_filter" });
    });
  };

  const handleChangeColor = (rowData, colorValue) => {
    api
      .patch("projects/" + rowData.id, {
        color: colorValue,
      })
      .then(() => {
        updateProjectList((prevProjects) => {
          return prevProjects.map((project) => {
            if (project.id === rowData.id) {
              return { ...project, color: colorValue };
            }
            return project;
          });
        });

        const newProjectColorList = { ...projectColorList };
        newProjectColorList[rowData.id] = colorValue;
        setProjectColorListState(newProjectColorList);

        trackCall("update_project", {
          location: "project_filter",
          type: "color",
        });
      })
      .catch((error) => {
        loadProjects();
      });
  };

  const handleProjectsReorderChange = () => {
    const projectIdArr = projectList.map((taskData) => taskData.id);

    const newItem = { projectIds: projectIdArr };

    api
      .post("/projects/order", newItem, {
        headers: {
          "X-Requester": primaryAccountInfo.email,
        },
      })
      .then((res) => {})
      .catch((error) => {
        loadProjects();
      });
  };

  const memoizedProjectList = useMemo(() => {
    return (
      !groupFold &&
      projectList &&
      projectList
        .filter((projectDataRow) => projectDataRow.status === "InProgress")
        .map((projectDataRow, index) => {
          return (
            <ProjectRow
              key={projectDataRow.id}
              data={projectDataRow}
              index={index}
              expand={expand}
              onExpandClick={onExpandClick}
              onDataChange={handleDataChange}
              onDataDelete={handleDataDelete}
              onChangeColor={handleChangeColor}
              isDone={isDone}
              loadProjects={loadProjects}
              onProjectsReorderChange={handleProjectsReorderChange}
            />
          );
        })
    );
  }, [projectList, groupFold, expand]);

  const handleProjectRowEnter = (e) => {
    setIsProjectRowHoverd(true);
  };

  const handleProjectRowLeave = (e) => {
    setIsProjectRowHoverd(false);
  };

  return (
    <>
      <div
        className={`${
          expand ? styles.projectGroup_expand : styles.projectGroup_fold
        } ${isScrolling ? styles.showScrollbar : ""}`}
      >
        <div
          className={`${
            expand ? styles["projectGroup-add"] : styles["projectGroup-add-closed"]
          } ${!expand && isProjectRowHoverd && styles.foldHoverd}
                    ${expand && isProjectRowHoverd && styles.expandHoverd}`}
          onClick={handleProjectAddClick}
          onMouseEnter={handleProjectRowEnter}
          onMouseLeave={handleProjectRowLeave}
        >
          <div className={styles["projectGroup-add-icon"]}></div>
          {expand && (
            <div className={styles["projectGroup-add-title"]}>
              {projectAddClick === true ? (
                <input
                  className={styles["projectGroup-add-input"]}
                  type="text"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                  onKeyDown={handleAddProject}
                  ref={inputRef}
                />
              ) : (
                <span>Add a project</span>
              )}
            </div>
          )}
        </div>
        {memoizedProjectList}
      </div>
    </>
  );
}

const HoverContent = ({ children, position }) => {
  return createPortal(
    <div
      className={styles["body-icon-hover"]}
      style={{
        visibility: "visible",
        position: "fixed",
        top: position.y,
        left: position.x,
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
};
