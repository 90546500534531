import styledConsole from "./styledConsole";

export default function apiLogger({ status, reqData, resData, method: consoleMethod = "log" }) {
  const { method, url, params } = reqData || {};
  const METHOD = method ? method.toUpperCase() : "";
  const paramSerialized = params ? `?${new URLSearchParams(params).toString()}` : "";

  styledConsole({
    topic: `${METHOD}:${status}`,
    topicColor: METHOD_COLOR_MAP[METHOD] || "black",
    title: `${url}${paramSerialized}`,
    data: {
      request: reqData,
      response: resData,
    },
    method: consoleMethod,
  });
}

const METHOD_COLOR_MAP = {
  GET: "skyblue",
  PATCH: "green",
  POST: "orange",
  PUT: "darkorange",
  DELETE: "red",
};
