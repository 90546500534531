import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import overlap from "react-big-calendar/lib/utils/layout-algorithms/overlap";
import { useDrop } from "react-dnd";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import styles from "./styles.module.css";

import {
  calendarEventState,
  calendarViewBaseDateState,
  calendarViewState,
  draggedEventState,
  saveEventState,
  showMorePopupState,
} from "../../recoil/calendar/calendarState";
import CustomDay from "./CustomDay";
import CalendarHeader from "./header";

import {
  accountState,
  meetWithAccountsState,
  projectColorListState,
} from "../../recoil/account/accountState";

import { useRecoilState } from "recoil";
import CustomEvent, { CustomEventWrapper } from "./CustomEvent";

import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import getMonth from "../../utils/common/dateTime/getMonth";
import useFetchMeetingCode from "../../queries/TaskDetail/useFetchMeetingCode";

import { v4 as uuid4 } from "uuid";
import { CalendarViewType, MeetWithColors } from "../../constants";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { filteringProjectIdListState } from "../../recoil/projects/filteringProjectIdListState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import {
  defaultDurationState,
  startOfWeekState,
  timeFormatState,
  visibilityState,
} from "../../recoil/calendar/settingCalendar";
import { toastState } from "../../recoil/toast/toastState";
import { trashRestoreCalendarEventState } from "../../recoil/trash/trashRestoreCalendarEventState";
import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";
import { loadFromLocalStorage } from "../../utils/localStorage/localStorage";
import { convertGoogleCalendarToMobaCalendar } from "../../utils/calendar/convertGoogleCalendarToMobaCalendar";
import { useCalendarEventQueries } from "../../queries/Calendar";
import { createPortal } from "react-dom";
import ShowMorePopup from "./ShowMorePopup";
import useClickOutside from "../../hooks/useClickOutside";
import ShowMoreTrigger from "./ShowMorePopup/ShowMoreTrigger";

moment.updateLocale("en", {
  week: {
    dow: 0,
    doy: 1,
  },
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const CustomFormat = {
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, "HH", culture),
  eventTimeRangeFormat: () => "",
  dayFormat: (date, culture, localizer) => localizer.format(date, "D ddd", culture),
  dateFormat: (date, culture, localizer) => localizer.format(date, "D", culture),
};

const MyCalendar = ({ loadData }) => {
  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const [filteringProjectIdList, setFilteringProjectIdList] = useRecoilState(
    filteringProjectIdListState
  );
  const defaultDuration = useRecoilValue(defaultDurationState);
  const setToast = useSetRecoilState(toastState);
  const api = useApi();

  const { views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
        day3: CustomDay,
      },
    }),
    []
  );
  const [nowView, setNowView] = useState(loadFromLocalStorage("calendarViewType") ?? Views.Week);

  const calendarRef = useRef(null);

  const handleView = useCallback((newView) => {
    const timeViewMap = {
      day: "1day",
      day3: "3days",
      week: "weekly",
      month: "monthly",
    };
    newView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);
    setNowView(newView);
    trackCall("change_calendar_view", { type: timeViewMap[newView] });
  }, []);

  const calendarEvent = useRecoilValue(calendarEventState);
  const [calendarBaseDate, setCalendarBaseDate] = useRecoilState(calendarViewBaseDateState);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const [meetWithAccounts, setMeetWithAccounts] = useRecoilState(meetWithAccountsState);
  const [mobaEventsList, setMobaEventList] = useState([]);

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [myEvents, setMyEvents] = useState(null);
  const [meetWithEvents, setMeetWithEvents] = useState([]);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);

  const [draggedEvent, setDraggedEvent] = useRecoilState(draggedEventState);
  const [saveEvent, setSaveEvent] = useRecoilState(saveEventState);
  const [eventRect, setEventRect] = useState(null);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const resetTaskDetail = useResetRecoilState(taskPopupState);

  const [inboxCompletedTaskList, updateInboxCompletedTaskList] = useRecoilState(doneTaskListState);
  const [trashRestoreCalendarEvent, setTrashRestoreCalendarEvent] = useRecoilState(
    trashRestoreCalendarEventState
  );
  // const [taskDetailModalHeight, setTaskDetailModalHeight] = useRecoilState(taskDetailHeightState);
  // const [modalHeight, setModalHeight] = useState(taskDetailModalHeight);

  const [showMorePopup, setShowMorePopup] = useRecoilState(showMorePopupState);
  const [showMoreEvents, setShowMoreEvents] = useState(null);
  const showMorePopupPos = useRef();
  const { targetRef, triggerRef } = useClickOutside(() => {
    setShowMorePopup(false);
  });
  const currentMoreTriggerRef = useRef(null);

  const trackCall = useJuneTrackCall();
  const defaultVisibility = useRecoilValue(visibilityState);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const startOfWeek = useRecoilValue(startOfWeekState);

  const { mutateMeetingCode } = useFetchMeetingCode();

  const calendarEventQueries = useCalendarEventQueries(
    meetWithAccounts.map((account) => account.email)
  );

  useEffect(() => {
    const targetNode = currentMoreTriggerRef.current;

    if (targetNode) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            if (!document.contains(targetNode)) {
              currentMoreTriggerRef.current = null;
              setShowMorePopup(false);
            }
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [showMorePopup]);

  useEffect(() => {}, [taskDetail]);

  useLayoutEffect(() => {
    const updateLocale = (locale, dow) => {
      moment.updateLocale(locale, {
        week: {
          dow, // week: 0 (일요일부터) or 1 (월요일부터)
          doy: 1,
        },
      });
    };

    if (startOfWeek === "Monday") {
      updateLocale("ko", 1);
    } else {
      updateLocale("en", 0);
    }

    setLocalizer(momentLocalizer(moment));
  }, [startOfWeek]);

  useEffect(() => {
    const adjEvents = initialConvertGoogleCalendarToMobaCalendar(calendarEvent);

    setMobaEventList(adjEvents);
  }, [calendarEvent]);

  useEffect(() => {
    if (!calendarEventQueries.data || calendarEventQueries.pending || !accountData) return;

    const convertMobaCalendar = calendarEventQueries.data.map((accountEvent, idx) => {
      const primaryAccountInfo = accountData.accountInfo.accounts.find((account) => {
        return account.type === "primary";
      });
      const creator = primaryAccountInfo.email;
      const currentMeetWithAccount = meetWithAccounts[idx].email;
      return (
        (accountEvent &&
          (creator !== currentMeetWithAccount
            ? initialConvertGoogleCalendarToMobaCalendar(accountEvent.items)
            : [])) ??
        []
      );
    });

    const meetWith = convertMobaCalendar.flatMap((accountEvent, idx) =>
      accountEvent.flatMap((items) => ({
        ...items,
        backgroundColor: MeetWithColors[idx % MeetWithColors.length],
        isMeetWith: true,
      }))
    );

    setMeetWithEvents(meetWith);
  }, [calendarEventQueries.data, accountData]);

  useEffect(() => {
    calendarEventQueries.error.forEach(
      (error, errorIdx) =>
        error &&
        setMeetWithAccounts((account) =>
          account.map((item, idx) => (errorIdx === idx ? { ...item, isAccess: false } : item))
        )
    );
  }, [calendarEventQueries.error]);

  useEffect(() => {
    const filteringEvents = mobaEventsList.filter((event) => {
      if (event.projectId == null || event.projectId === "") {
        return true;
      } else {
        return !filteringProjectIdList.includes(event.projectId);
      }
    });
    setMyEvents(filteringEvents);
  }, [filteringProjectIdList, mobaEventsList, projectColorList]);

  useEffect(() => {
    if (trashRestoreCalendarEvent) {
      const convertEvent = convertGoogleCalendarToMobaCalendar(trashRestoreCalendarEvent);
      setMobaEventList((current) => {
        return [...current, convertEvent];
      });
      setTrashRestoreCalendarEvent(null);
    }
  }, [trashRestoreCalendarEvent, setTrashRestoreCalendarEvent]);

  const initialConvertGoogleCalendarToMobaCalendar = (googleCalendarEvent) => {
    return googleCalendarEvent
      .filter(
        (it) =>
          it.start &&
          (it.start.date || it.start.dateTime) &&
          it.end &&
          (it.end.date || it.end.dateTime)
      )
      .map((it) => {
        return convertGoogleCalendarToMobaCalendar(it);
      });
  };

  const handleEventDelete = (eventId) => {
    setMobaEventList((current) => {
      const updatedEvents = [...current];
      const eventIndex = updatedEvents.findIndex((event) => event.id === eventId);
      if (eventIndex !== -1) {
        updatedEvents.splice(eventIndex, 1);
      }
      return updatedEvents;
    });
  };

  const handleEventChange = (eventData) => {
    setMobaEventList((current) => {
      const updatedEvents = [...current];
      const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);
      if (eventIndex !== -1) {
        // 이미 리스트에 있는 이벤트인 경우
        const { isCreateSelectEvent, isDataDuplicateEvent, ...restEventData } = eventData;
        const convertEventData = convertGoogleCalendarToMobaCalendar(restEventData);
        updatedEvents[eventIndex] = convertEventData;
      }
      return updatedEvents;
    });
  };

  const handleGoogleMeetUrlInsertChange = (eventData) => {
    setMobaEventList((current) => {
      const updatedEvents = [...current];
      const eventIndex = updatedEvents.findIndex((event) => event.id === eventData.id);
      if (eventIndex !== -1) {
        updatedEvents[eventIndex].attendees = eventData.attendees;
        updatedEvents[eventIndex].hangoutLink = eventData.hangoutLink;
      }
      return updatedEvents;
    });
  };

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...{
        className: `isDraggable ${event.allDay ? "allDay-event" : ""} ${event.isMeetWith ? "meetWith-event" : ""}`,
      },
    };
  }, []);

  const handleTaskClick = () => {
    return setTaskDetail({
      isVisible: true,
      data: selectedEvent,
      modalPosition: modalPosition,
      loadData: loadData,
      handleDataDuplicate: handleDataDuplicate,
      type: "calendar",
      handleEventDelete: handleEventDelete,
      handleEventChange: handleEventChange,
    });
  };

  const handleDataDuplicate = (e, rowData) => {
    const newId = `task-${uuid4()}`;

    let newItem = convertGoogleCalendarToMobaCalendar({
      ...rowData,
      id: newId,
      isDataDuplicateEvent: true,
      recurrence: [],
    });

    let newItemForAPI = { ...newItem };
    for (const key in newItemForAPI) {
      if (newItemForAPI[key] === null) {
        delete newItemForAPI[key];
      }
    }

    if (rowData.hangoutLink) {
      const meetingCode = rowData.hangoutLink.replace("https://meet.google.com/", "");
      newItemForAPI = {
        ...newItemForAPI,
        meetingCode: meetingCode,
      };
    }

    if (!newItem.attendees || newItem.attendees.length === 0) {
      newItemForAPI = {
        ...newItemForAPI,
        projectId: newItem.projectId != null ? newItem.projectId : "",
        start: newItem.startTimeZone
          ? { dateTime: newItem.start, timeZone: newItem.startTimeZone }
          : { date: moment(newItem.start).format("YYYY-MM-DD") },
        end: newItem.endTimeZone
          ? { dateTime: newItem.end, timeZone: newItem.endTimeZone }
          : { date: moment(newItem.end).format("YYYY-MM-DD") },
      };

      api.get("notes/" + rowData.id + "/" + rowData.creator).then((res) => {
        newItemForAPI = {
          ...newItemForAPI,
          note: res.data.note || "",
        };

        api
          .post("tasks", newItemForAPI, {
            headers: {
              "X-Requester": newItem.creator,
            },
          })
          .then((res) => {
            let trackObject = { location: "calendar" };
            if (newItem.attendees != null && newItem.attendees.length) {
              trackObject = { ...trackObject, type: "meeting" };
            } else {
              trackObject = { ...trackObject, type: "task" };
            }
            trackCall("duplicate_block", trackObject);

            setMobaEventList((current) => [...current, newItem]);

            setTaskDetail((prevState) => ({ ...prevState, isVisible: false }));

            setToast({
              type: "Success",
              isVisible: true,
              message: "Event created successfully",
            });
          })
          .catch((error) => {
            console.error("Failed to duplicate task: ", error);
          });
      });
    } else {
      // Guest 있을 때 Duplicate
      const { clientX, clientY } = e;

      setTaskDetail((prevState) => ({
        ...prevState,
        isVisible: true,
        data: newItemForAPI,
        modalPosition: { x: clientX, y: clientY },
        loadData: loadData,
        handleDataDuplicate: handleDataDuplicate,
        type: "calendar",
        handleEventDelete: handleEventDelete,
        handleEventChange: handleEventChange,
      }));
      setMobaEventList((current) => [...current, newItemForAPI]);
    }
  };

  useEffect(() => {
    if (modalPosition.x !== 0 || modalPosition.y !== 0) {
      handleTaskClick();
    }
  }, [modalPosition]);

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  const customOnDragOver = useCallback(
    (dragEvent) => {
      if (draggedEvent == null) {
        return;
      }
      if (draggedEvent !== "undroppable") {
        dragEvent.preventDefault();
      }
    },
    [draggedEvent]
  );

  useEffect(() => {
    const initialTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        createTimeIndicator();
      }, 200);

    const updateTimeIndicator =
      calendarView !== CalendarViewType.MONTH.type &&
      setInterval(() => {
        const timeTextEl = document.querySelector(`.time-indicator span`);
        if (!timeTextEl) {
          return handleTimeIndicator();
        }
        if (
          timeTextEl &&
          timeTextEl.textContent !==
            localizer.format(new Date(), timeFormat === "24-hour" ? "HH:mm" : "hh:mm a")
        ) {
          return handleTimeIndicator();
        }
      }, 1000);

    return () => {
      clearTimeout(initialTimeIndicator);
      clearInterval(updateTimeIndicator);
    };
  }, [calendarView]);

  useEffect(() => {
    calendarView !== CalendarViewType.MONTH.type &&
      setTimeout(() => {
        const wrapper = document.querySelector(".rbc-time-content");
        const element = document.querySelector(`.rbc-current-time-indicator`);
        if (!wrapper || !element) {
          return;
        }
        const wrapperBound = wrapper.getBoundingClientRect();
        wrapper.scrollTo({
          top: element.offsetTop - wrapperBound.height / 3,
          behavior: "smooth",
        });
      }, 200);

    const resizeTimeIndicator = () => {
      const wrapper = document.querySelector(`.rbc-time-content`);
      const timeEl = document.querySelector(`.time-indicator`);
      if (!wrapper || !timeEl) return;

      const wrapperBound = wrapper.getBoundingClientRect();
      timeEl.style.width = `${wrapperBound.width - 60}px`;
    };

    const calenderWrapper = calendarRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      resizeTimeIndicator();
    });
    resizeObserver.observe(calenderWrapper);

    return () => {
      resizeObserver.unobserve(calenderWrapper);
    };
  }, []);

  const createTimeIndicator = () => {
    const wrapper = document.querySelector(`.rbc-time-content`);
    const wrapperBound = wrapper.getBoundingClientRect();
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);

    const newTimeElWrapper = document.querySelector(".rbc-time-gutter");
    const newTimeEl = document.createElement("div");
    newTimeEl.classList.add("time-indicator");
    newTimeEl.style.top = originTimeEl.style.top;
    newTimeEl.style.width = `${wrapperBound.width - 60}px`;

    const newTimeTextEl = document.createElement("span");
    newTimeTextEl.textContent = localizer.format(
      new Date(),
      timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
    );

    newTimeElWrapper.appendChild(newTimeEl);
    newTimeEl.appendChild(newTimeTextEl);
  };
  const timeFormat = useRecoilValue(timeFormatState);
  const handleTimeIndicator = () => {
    const originTimeEl = document.querySelector(`.rbc-current-time-indicator`);
    const timeEl = document.querySelector(`.time-indicator`);
    const timeTextEl = document.querySelector(`.time-indicator span`);
    if (!timeEl) {
      createTimeIndicator();
    }
    timeTextEl.textContent = localizer.format(
      new Date(),
      timeFormat === "24-hour" ? "HH:mm" : "hh:mm a"
    );

    timeEl.style.top = originTimeEl
      ? originTimeEl.style.top
      : `${Number(timeEl.style.top.split("%")[0]) + 0.06945}%`;
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      if (droppedOnAllDaySlot) {
        // All day 영역일 떄
        if (!event.allDay) {
          event.allDay = true;
        }
        event.startTimeZone = null;
        event.endTimeZone = null;

        event.start = start;
        event.end = end;

        updateEvent(
          event,
          new Date(moment(start).format("YYYY-MM-DD")),
          new Date(moment(end).format("YYYY-MM-DD"))
        );

        trackCall("move_block", {
          previous_start_datetime: formatDateTimeForJune(event.start),
          previous_end_datetime: formatDateTimeForJune(event.end),
          new_start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
          new_end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
          allDay: true,
        });
      } else {
        // 드랍 후 영역이 All day 영역이 아닐 때
        if (event.allDay) {
          // TODO 15분을 사용자 지정 시간으로 변경하기
          // 이전 위치가 allday(allday 에서 드랍 시)일 때
          trackCall("move_block", {
            previous_start_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            previous_end_datetime: formatDateTimeForJune(
              new Date(event.start).setHours(0, 0, 0, 0)
            ),
            new_start_datetime: formatDateTimeForJune(start),
            new_end_datetime: formatDateTimeForJune(
              new Date(new Date(start).setMinutes(new Date(start).getMinutes() + defaultDuration))
            ),
            allDay: false,
          });
        } else {
          // 이전 위치가 allday 아닐 때
          trackCall("move_time", {
            previous_start_datetime: formatDateTimeForJune(event.start),
            previous_end_datetime: formatDateTimeForJune(event.end),
            new_start_datetime: formatDateTimeForJune(start),
            new_end_datetime: formatDateTimeForJune(end),
            allDay: false,
          });
        }

        if (nowView !== CalendarViewType.MONTH.type && event.allDay) {
          event.allDay = false;
          event.end = new Date(new Date(start).setMinutes(new Date(start).getMinutes() + 15));
        } else {
          event.end = new Date(end);
        }
        event.start = new Date(start);
        event.startTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        event.endTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        updateEvent(event, event.start, event.end);
      }
    },
    [setMyEvents, nowView]
  );

  const newEvent = useCallback(
    (event) => {
      updateEvent(event, event.start, event.end);
    },
    [setMyEvents]
  );

  const onDropFromOutside = useCallback(
    ({ start, end }) => {
      if (draggedEvent == null) return;

      if (draggedEvent === "undroppable") {
        setDraggedEvent(null);
        return;
      }

      // inprogress task에서 삭제
      updateInboxTaskList((current) => {
        const newData = current.filter((item) => {
          return item.id !== draggedEvent.id;
        });
        return newData;
      });

      // completed task에서 삭제
      updateInboxCompletedTaskList((current) => {
        const newData = current.filter((item) => {
          return item.id !== draggedEvent.id;
        });
        return newData;
      });

      // endTime === 00:00 인 경우 11:59로 변경
      // 23:45분으로 dnd한 경우 11:59로 변경
      const MS1MIN = 60000;
      const newEnd =
        moment(start).format("HH:mm") === "23:45" && moment(end).format("HH:mm") === "00:00"
          ? new Date(new Date(end).getTime() - MS1MIN)
          : end;

      const allDay = start.getDate() !== newEnd.getDate();

      const { title, kind, color, integrationId, creator, taskType } = draggedEvent;

      const data = draggedEvent;

      let event = {
        ...data,
        creator: data.creator ? data.creator : data.link.match(/authuser=([^&#]+)/)[1],
        start: allDay ? moment(start).format("YYYY-MM-DD") : start,
        end: allDay ? moment(newEnd).format("YYYY-MM-DD") : newEnd,
        allDay,
        color,
        startTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
        endTimeZone: allDay ? null : Intl.DateTimeFormat().resolvedOptions().timeZone,
        integrationId: integrationId,
        projectId: data.projectId,
        integration: data.integration,
        visibility:
          data.visibility != null
            ? data.visibility
            : defaultVisibility === "public"
              ? "public"
              : "private",
        transparency:
          data.transparency != null
            ? data.transparency
            : defaultVisibility === "invisible"
              ? "transparent"
              : "opaque",
        // integration 드랍 시 taskType 추가
        taskType: taskType ?? "Task",
      };

      if (draggedEvent.dragType === "moreTask") {
        const timeDiff = new Date(start).getTime() - new Date(data.start).getTime();
        const newStart = new Date(new Date(data.start).getTime() + timeDiff);
        const newEnd = new Date(new Date(data.end).getTime() + timeDiff);
        const showMoreEvent = {
          ...event,
          start: newStart,
          end: newEnd,
        };

        handleEventChange(showMoreEvent);
        newEvent(showMoreEvent);

        setShowMoreEvents((current) => {
          return current.filter((item) => item.id !== showMoreEvent.id);
        });
        setDraggedEvent(null);
        const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
        elementsToHide.forEach((element) => {
          element.style.display = "none";
        });
        return;
      }

      if (kind === "gmail" || kind === "jira" || kind === "slack") {
        const itemId = draggedEvent.integration.itemId;
        event = {
          ...event,
          integration: {
            link: data.link,
            provider: kind,
            integrationId: integrationId,
            itemId: itemId,
            // NOTE Integration title 프로퍼티 추가됨
            title: event.title,
          },
        };
      }

      setMobaEventList((current) => [...current, event]);
      setDraggedEvent(null);

      if (kind === "gmail" || kind === "jira" || kind === "slack") {
        let newItem = {
          id: event.id,
          title: event.title,
          creator: event.creator,
          start: event.startTimeZone
            ? { dateTime: start, timeZone: event.startTimeZone }
            : { date: moment(start).format("YYYY-MM-DD") },
          end: event.endTimeZone
            ? { dateTime: end, timeZone: event.endTimeZone }
            : { date: moment(newEnd).format("YYYY-MM-DD") },
          visibility: event.visibility,
          transparency: event.transparency,
        };

        let dataId = data.id;
        if (dataId.includes("int-")) {
          dataId = dataId.replace("int-", "");
        }

        newItem.integration = {
          integrationId: event.integrationId,
          itemId: dataId,
          provider: event.integration.provider,
          link: event.link,
          title: event.title,
        };

        api
          .post("tasks", newItem, {
            headers: {
              "X-Requester": event.creator,
            },
          })
          .then((res) => {
            trackCall("create_task", {
              location: "integration",
            });

            if (allDay) {
              trackCall("block_time", {
                location: "integration",
                start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
                end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
              });
            } else {
              trackCall("block_time", {
                location: "integration",
                start_datetime: formatDateTimeForJune(event.start),
                end_datetime: formatDateTimeForJune(event.end),
              });
            }

            setToast({
              type: "Success",
              isVisible: true,
              message: "Task planned successfully",
            });

            const convertEvent = {
              ...event,
              // start: startDateParsing.date || new Date(startDateParsing.dateTime),
              // startTimeZone: startDateParsing.timeZone
              //   ? startDateParsing.timeZone
              //   : null,
              // end: endDateParsing.date || new Date(endDateParsing.dateTime),
              // endTimeZone: startDateParsing.timeZone
              //   ? startDateParsing.timeZone
              //   : null,
              attendees: JSON.parse(res.data.task.attendees),
              ...(JSON.parse(res.data.task.attendees).length > 0
                ? {
                    hangoutLink: res.data.task.hangoutLink,
                  }
                : { hangoutLink: null }),
              kind: null,
              visibility: defaultVisibility === "public" ? "public" : "private",
              transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
              // Integration drop 시
              taskType: "Task",
            };

            handleEventChange(convertEvent, true);
          })
          .catch((error) => {
            loadData(false, false, false);
          });
      } else {
        if (allDay) {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: formatDateTimeForJune(new Date(event.start).setHours(0, 0, 0, 0)),
            end_datetime: formatDateTimeForJune(new Date(event.end).setHours(0, 0, 0, 0)),
          });
        } else {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: formatDateTimeForJune(event.start),
            end_datetime: formatDateTimeForJune(event.end),
          });
        }

        setToast({
          type: "Success",
          isVisible: true,
          message: "Task planned successfully",
        });
        handleEventChange(event);
        newEvent(event);
      }

      const elementsToHide = document.querySelectorAll(".rbc-addons-dnd-drag-row.rbc-row");
      elementsToHide.forEach((element) => {
        element.style.display = "none";
      });
    },
    [draggedEvent, setDraggedEvent, newEvent]
  );

  useEffect(() => {
    if (saveEvent == null) return;
    const updateEvent = saveEvent;
    setSaveEvent(null);

    setMobaEventList((current) => {
      let updatedEvents = [...current];
      const eventIndex = updatedEvents.findIndex((event) => event.id === updateEvent.id);
      if (eventIndex !== -1) {
        const convertEventData = convertGoogleCalendarToMobaCalendar(updateEvent);
        updatedEvents[eventIndex] = convertEventData;
      } else {
        updatedEvents = [...current, convertGoogleCalendarToMobaCalendar(updateEvent)];
      }
      return updatedEvents;
    });
  }, [saveEvent]);

  const updateEvent = (event, start, end, retryCount = 0) => {
    let newItem = {
      title: event.title,
      creator: event.creator,
      start:
        !event.allDay && event.startTimeZone
          ? { dateTime: start, timeZone: event.startTimeZone }
          : { date: moment(start).format("YYYY-MM-DD") },
      end:
        !event.allDay && event.endTimeZone
          ? { dateTime: end, timeZone: event.endTimeZone }
          : { date: moment(end).format("YYYY-MM-DD") },
      taskType: event.taskType,
    };

    if (event.visibility != null) {
      newItem = {
        ...newItem,
        visibility: event.visibility,
      };
    }

    if (event.transparency != null) {
      newItem = {
        ...newItem,
        transparency: event.transparency,
      };
    }

    const maxRetries = 1;

    api
      .patch(`tasks/${event.id}?creator=${event.creator || event.creator}`, newItem, {
        headers: {
          "X-Requester": event.creator || event.creator,
        },
      })
      .then((res) => {
        const convertEvent = {
          ...event,
          attendees: res.data.task.attendees ? JSON.parse(res.data.task.attendees) : null,
          ...(res.data.task.attendees != null
            ? {
                // google meet 자동 생성
                taskType: res.data.task.taskType,
                hangoutLink: res.data.task.hangoutLink,
              }
            : { hangoutLink: null }),
        };

        // handleGoogleMeetUrlInsertChange(convertEvent);
      })
      .catch((error) => {
        // HTTP 500 에러이고 재시도 횟수가 남았을 때만 재시도
        if (error.response && error.response.status === 500 && retryCount < maxRetries) {
          updateEvent(event, start, end, retryCount + 1); // 재시도
        } else {
          handleEventDelete(event);
          loadData(false, false, true);
        }
      });
  };

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      if (new Date(start).getTime() === new Date(end).getTime()) {
        const MS15MIN = 900000;
        const endTime = new Date(new Date(end).getTime() + MS15MIN);
        event.start = start;
        event.end = endTime;
        updateEvent(event, start, endTime);
        return;
      }
      trackCall("resize_block", {
        previous_start_datetime: formatDateTimeForJune(event.start),
        previous_end_datetime: formatDateTimeForJune(event.end),
        new_start_datetime: formatDateTimeForJune(start),
        new_end_datetime: formatDateTimeForJune(end),
      });
      event.start = start;
      event.end = end;
      updateEvent(event, start, end);
    },
    [setMyEvents]
  );

  // TODO dev 확인 후 삭제 에정
  // useEffect(() => {
  //   setModalHeight(taskDetailModalHeight);
  // }, [taskDetail.data, taskDetailRef.current, taskDetailModalHeight]);

  const clickEvent = (e, data) => {
    const modalWidth = 362;

    if (saveEvent != null) {
      return;
    }

    let convertToData = convertGoogleCalendarToMobaCalendar(data);
    convertToData = {
      ...convertToData,
      start: data.allDay ? moment(data.start).format("YYYY-MM-DD") : data.start,
      end: data.allDay ? moment(data.end).format("YYYY-MM-DD") : data.end,
    };

    const wrapElement = e.target.closest(".event_wrapper");
    if (!wrapElement) {
      return; // 'wrap' 클래스를 가진 요소가 없으면 함수를 종료합니다.
    }

    const rect = wrapElement.getBoundingClientRect();
    let x = rect.right + 5;
    let y = rect.top;

    // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
    if (x + modalWidth > window.innerWidth) {
      x = rect.left - modalWidth - 5;
    }

    // 모달이 화면의 왼쪽을 벗어나면 오른쪽에 맞춤
    if (x < 0) {
      x = 48;
    }

    setModalPosition({ x, y });
    setEventRect(rect);
    setSelectedEvent(convertToData);
  };

  const handleNavigate = (date, view) => {
    if (getMonth(date) !== getMonth(calendarBaseDate)) {
      setCalendarBaseDate(date);
    }
  };

  const handleSelectSlot = useCallback(
    (slotInfo) => {
      if (saveEvent != null) {
        return;
      }
      let el = document.getElementsByClassName(slotInfo.start.toISOString());

      if (accountData != null) {
        const [primaryAccountInfo] = accountData.accountInfo.accounts.filter((account) => {
          return account.type === "primary";
        });
        const creator = primaryAccountInfo.email;
        const eventId = uuid4();

        const { bounds, box } = slotInfo;
        // all Day 영역일때
        if (bounds == null && box == null) {
          let newItem = convertGoogleCalendarToMobaCalendar({
            id: eventId,
            title: "",
            creator: creator,
            allDay: true,
            start: moment(slotInfo.start).format("YYYY-MM-DD"),
            end: moment(slotInfo.end).format("YYYY-MM-DD"),
            isCreateSelectEvent: true,
            visibility: defaultVisibility === "public" ? "public" : "private",
            transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
            attendees:
              meetWithAccounts.length > 0
                ? [{ email: creator, organizer: true }, ...meetWithAccounts]
                : undefined,
            // allday 영역에서 클릭으로 생성 시 기본값
            taskType: meetWithAccounts.length > 0 ? "Event" : "Task",
          });

          const handleClick = (event) => {
            // NOTE All day 영역 클릭 시 모달 위치 설정
            const rect =
              el.length === 2 ? el[1].getBoundingClientRect() : el[0].getBoundingClientRect();
            const modalWidth = 362;

            // All day 영역일 때는 떼어진 공간이 없으므로 box 바로 옆 설정
            let x = rect.right;
            let y = rect.top;

            // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
            if (x + modalWidth > window.innerWidth) {
              x = rect.left - modalWidth - 5;
            }

            setModalPosition({
              x: x,
              y: y,
            });
            setMobaEventList((current) => [...current, newItem]);
            setEventRect(null);
            setSelectedEvent(newItem);

            document.removeEventListener("click", handleClick);
          };

          document.addEventListener("click", handleClick);
        } else {
          const getNewItem = (calendarView, slotInfo, eventId, creator, defaultVisibility) => {
            const baseEvent = {
              id: eventId,
              title: "",
              creator: creator,
              isCreateSelectEvent: true,
              visibility:
                slotInfo.visibility != null
                  ? slotInfo.visibility
                  : defaultVisibility === "public"
                    ? "public"
                    : "private",
              transparency:
                slotInfo.transparency != null
                  ? slotInfo.transparency
                  : defaultVisibility === "invisible"
                    ? "transparent"
                    : "opaque",
              attendees:
                meetWithAccounts.length > 0
                  ? [{ email: creator, organizer: true }, ...meetWithAccounts]
                  : undefined,
              // allday 영역 아닌 캘린더에서 클릭 앤 드래그로 생성 시 기본값
              taskType: meetWithAccounts.length > 0 ? "Event" : "Task",
            };

            if (calendarView === CalendarViewType.MONTH.type) {
              return convertGoogleCalendarToMobaCalendar({
                ...baseEvent,
                allDay: true,
                start: moment(slotInfo.start).format("YYYY-MM-DD"),
                end: moment(slotInfo.end).format("YYYY-MM-DD"),
              });
            } else {
              return convertGoogleCalendarToMobaCalendar({
                ...baseEvent,
                start: { dateTime: slotInfo.start, timeZone: "Asia/Seoul" },
                end: { dateTime: slotInfo.end, timeZone: "Asia/Seoul" },
              });
            }
          };

          let newItem = getNewItem(calendarView, slotInfo, eventId, creator, defaultVisibility);

          // NOTE  첫 클릭 앤 드래그로 바로 taskDetail 열었을 때

          // Montly view에서 클릭으로 생성 시 모달 위치 설정
          if (calendarView === CalendarViewType.MONTH.type) {
            const modalWidth = 362;

            // NOTE Monthly에서 여러 블록 드래그 시 bounds, 아닐 때 box
            let x = bounds ? bounds.x : box.x + 5;
            let y = bounds ? bounds.y : box.y + 5;

            const clickedElement = document.elementFromPoint(x, y);

            if (clickedElement) {
              let rbcDayBgElement = clickedElement.closest(".rbc-day-bg");
              if (rbcDayBgElement) {
                const rect = rbcDayBgElement.getBoundingClientRect();
                // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
                if (rect.right + modalWidth > window.innerWidth) {
                  x = rect.left - modalWidth - 5;
                } else if (rect.left - modalWidth < 0) {
                  x = rect.right + 5;
                } else {
                  x = rect.right;
                }
                y = rect.y;
              }
            }

            setModalPosition({
              x: x,
              y: y,
            });
          } else {
            // Monthly View 아닐 경우
            const rect =
              el.length === 2 ? el[1].getBoundingClientRect() : el[0].getBoundingClientRect();
            const modalWidth = 362;

            let x = rect.right;
            let y = rect.top;

            // 모달이 화면의 오른쪽을 벗어나면 왼쪽에 맞춤
            if (x + modalWidth > window.innerWidth) {
              x = rect.left - modalWidth - 5;
            }

            if (x < 0) {
              x = 48;
            }
            setModalPosition({
              x: x,
              y: y,
            });
          }

          setMobaEventList((current) => [...current, newItem]);

          el.length &&
            setEventRect(
              el.length === 2 ? el[1].getBoundingClientRect() : el[0].getBoundingClientRect()
            );
          setSelectedEvent(newItem);
        }

        if (meetWithAccounts.length > 0) {
          mutateMeetingCode(creator);
        }
      }
    },
    [setMobaEventList, accountData, calendarView, defaultVisibility, meetWithAccounts]
  );

  const isDragging = useRef();

  const handleDragResizeEvent = (e) => {
    if (!isDragging.current) {
      return;
    }
    const scrollEl = document.querySelector(".rbc-time-content");
    const scrollBound = scrollEl.getBoundingClientRect();
    if (e.pageY > scrollBound.bottom - 16) {
      scrollEl.scrollTop += 1;
    }
  };

  const handleClickShowMore = (e) => {
    const rect = e.target.getBoundingClientRect();
    showMorePopupPos.current = {
      top: rect.bottom - 10,
      left: rect.right - 50,
    };
    currentMoreTriggerRef.current = e.target;
    setShowMorePopup(!showMorePopup);
  };

  const handleShowMorePopupPos = (eventCount) => {
    const eventBlockHeight = 24;
    const showMorePopupPadding = 12;
    const popupHeight = eventBlockHeight * eventCount + showMorePopupPadding;
    const popupWidth = 180;

    const newPos = {
      top: showMorePopupPos.current.top,
      left: showMorePopupPos.current.left,
    };

    // width가 넘친 경우
    if (showMorePopupPos.current.left + popupWidth > window.innerWidth) {
      newPos.left = window.innerWidth - popupWidth - 10;
    }

    // height가 넘친 경우
    if (showMorePopupPos.current.top + popupHeight > window.innerHeight) {
      newPos.top = window.innerHeight - popupHeight - 10;
    }
    showMorePopupPos.current = newPos;
  };

  return (
    <div
      ref={calendarRef}
      style={{ width: "100%", height: "100%" }}
      onMouseDown={() => {
        isDragging.current = true;
      }}
      onMouseUp={() => {
        isDragging.current = false;
      }}
    >
      {myEvents && (
        <DragAndDropCalendar
          defaultDate={new Date()}
          components={{
            toolbar: CalendarHeader,
            event: (props) => (
              <CustomEvent
                {...props}
                localizer={localizer}
                loadData={loadData}
                onEventChange={handleEventChange}
              />
            ),
            eventWrapper: (props) => (
              <CustomEventWrapper
                {...props}
                onClick={(e, data, rect) => {
                  !data.isMeetWith && clickEvent(e, data, rect);
                }}
                loadData={loadData}
                onEventDelete={handleEventDelete}
                onDataDuplicate={handleDataDuplicate}
                onEventChange={handleEventChange}
              />
            ),

            timeGutterHeader: MyTimeGutterHeader,
            timeGutterWrapper: (props) => <MyTimeGutterWrapper {...props} localizer={localizer} />,
            day: {
              header: MyDayHeader,
            },
            week: {
              header: MyDayWeekHeader,
            },
            day3: {
              header: MyDayWeekHeader,
            },
          }}
          defaultView={
            Object.values(CalendarViewType).find(
              (viewType) => viewType.type === loadFromLocalStorage("calendarViewType")
            ).type ?? Views.DAY
          }
          localizer={localizer}
          views={views}
          formats={CustomFormat}
          dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
          eventPropGetter={eventPropGetter}
          dayPropGetter={(date) => {
            const dayofWeek = localizer.format(new Date(date), "ddd");
            if (dayofWeek === "Sun" || dayofWeek === "Sat") {
              return { className: "weekend" };
            }
          }}
          slotPropGetter={(date) => ({ className: date.toISOString() })}
          events={[...myEvents, ...meetWithEvents]}
          onDropFromOutside={onDropFromOutside}
          onDragOver={customOnDragOver}
          onEventDrop={moveEvent}
          onDragStart={(event) => {
            if (event.action === "resize" && event.direction === "DOWN") {
              document.addEventListener("mousemove", handleDragResizeEvent);
            }
          }}
          onEventResize={resizeEvent}
          onNavigate={handleNavigate}
          onSelectSlot={(e) => handleSelectSlot(e)}
          resizable
          onView={handleView}
          showMultiDayTimes={true}
          selectable
          step={defaultDuration}
          timeslots={Math.floor(60 / defaultDuration)}
          dayLayoutAlgorithm={(params) => {
            return overlap({ ...params, minimumStartDifference: 15 });
          }}
          tooltipAccessor={null}
          messages={{
            showMore: (total) => (
              <ShowMoreTrigger ref={triggerRef} onClick={handleClickShowMore}>
                + {total} more
              </ShowMoreTrigger>
            ),
          }}
          popup={false}
          showAllEvents={false}
          doShowMoreDrillDown={false}
          onShowMore={(events) => {
            handleShowMorePopupPos(events.length);
            setShowMoreEvents(events);
          }}
          draggableAccessor={(event) => !event.isMeetWith}
        />
      )}
      {showMorePopup &&
        createPortal(
          <ShowMorePopup
            ref={targetRef}
            events={showMoreEvents}
            onClose={() => setShowMorePopup(false)}
            style={showMorePopupPos.current}
          >
            {(event) => (
              <CustomEventWrapper
                key={event.id}
                event={event}
                onClick={(e, data, rect) => {
                  if (data.isMeetWith) return;
                  if (taskDetail.data?.id === data.id) {
                    return resetTaskDetail();
                  }

                  clickEvent(e, data, rect);
                }}
                loadData={loadData}
                onEventDelete={handleEventDelete}
                onDataDuplicate={handleDataDuplicate}
                onEventChange={handleEventChange}
              >
                <CustomEvent
                  event={event}
                  localizer={localizer}
                  loadData={loadData}
                  onEventChange={handleEventChange}
                />
              </CustomEventWrapper>
            )}
          </ShowMorePopup>,
          document.body
        )}
    </div>
  );
};

function MyDayHeader(props) {
  const { label } = props;
  const date = label.split(" ")[0];
  const dayOfWeek = label.split(" ")[1];

  return (
    <div className="dayWeekHeader">
      <div className="date">{date}</div>
      <div className="dayOfWeek">{dayOfWeek}</div>
    </div>
  );
}
function MyDayWeekHeader(props) {
  const { label } = props;
  const date = label.split(" ")[0];
  const dayOfWeek = label.split(" ")[1];

  return (
    <div className="dayWeekHeader">
      <div className="date">{date}</div>
      <div className="dayOfWeek">{dayOfWeek}</div>
    </div>
  );
}

function MyTimeGutterHeader() {
  return <div className="timeGutterHeader">All day</div>;
}

function MyTimeGutterWrapper({ localizer, slotMetrics }) {
  const timeFormat = useRecoilValue(timeFormatState);
  return (
    <div className="rbc-time-gutter rbc-time-column">
      {slotMetrics.groups.map((date, idx) => {
        return (
          <div className="rbc-timeslot-group" key={`${date[0]}${idx}`}>
            <div className="rbc-time-slot">
              <div className="rbc-label">
                {idx > 0 &&
                  localizer.format(
                    new Date(date[0]),
                    timeFormat === "12-hour" ? "h a" : "HH" + ":" + "00"
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function CalendarCustom({ loadData }) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [showMorePopup, setShowMorePopup] = useRecoilState(showMorePopupState);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  const [, drop] = useDrop({
    accept: ["task", "integrationDrag", "moreTask"],
    drop(props, monitor) {
      const item = monitor.getItem();
      if (item.dragType === "integrationDrag") {
        return { dropType: "calendarIntegrationDrop" };
      }
    },
  });

  return (
    <div ref={drop} className={styles["calendar-custom"]}>
      {(taskDetail.isVisible || showMorePopup) && (
        <div className={styles.overlayView} onClick={handleOverlayClick}></div>
      )}
      <MyCalendar loadData={loadData} />
    </div>
  );
}
