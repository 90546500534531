import { useState, useEffect } from "react";

import { ReactComponent as SelectExpandIcon } from "../../../../assets/Integration/select-expand-icon.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/Integration/refresh-icon.svg";
import { Reconnect } from "./Reconnect";

import styles from "./IntegrationDetails.module.css";
import SlackItem from "./IntegrationDetailItems/SlackItem";
import LoadingComponent from "../../../Common/LoadingComponent";

const IntegrationComponent = ({
  creator,
  selectedRow,
  ItemComponent,
  listData,
  mailLabels,
  mailListRef,
  mailDropdownOpen,
  toggleMailDropdown,
  handleMailLabel,
  reloadFunction,
  selectedLabel,
  setData,
  isReconnectNeeded,
  isIntegrationDataFirstLoading,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.account__info}>
        <span>{selectedRow && selectedRow.info}</span>
        <button
          className={`${styles.refresh__button} ${isReconnectNeeded && styles.disabled}`}
          onClick={reloadFunction}
          disabled={isReconnectNeeded}
        >
          <div className={styles.refresh__icon}>
            <RefreshIcon />
          </div>
        </button>
      </div>
      {!isReconnectNeeded && selectedRow.kind === "gmail" && (
        <div className={styles.mail__options}>
          <div
            className={`${styles.mail__options__button} ${
              mailDropdownOpen ? styles.mail__options__button_selected : ""
            }`}
            onClick={toggleMailDropdown}
          >
            <span className={styles.mail__option_selected}>{selectedLabel?.name || "Select"}</span>
            <SelectExpandIcon className={styles.sidebar__open_icon} />
          </div>

          {mailDropdownOpen && (
            <div className={styles.mail__options__sidebar_container}>
              <ul className={styles.mail__options__list}>
                {mailLabels &&
                  mailLabels.system.map((item, index) => (
                    <li
                      onClick={() => {
                        handleMailLabel(item, index);
                        setLoading(true);

                        const timer = setTimeout(() => {
                          setLoading(false);
                        }, 1500);

                        return () => clearTimeout(timer);
                      }}
                      key={`system-${item.id}`}
                    >
                      <div className={styles.labelArea}>
                        <div
                          className={`${
                            selectedLabel.id === item.id ? styles.selectIcon : styles.nonSelectIcon
                          }`}
                        />
                        <span className={styles.labelName}>{item.name}</span>
                      </div>
                    </li>
                  ))}
                {mailLabels && mailLabels.user.length > 0 && <hr />}
                {mailLabels &&
                  mailLabels.user.map((item, index) => (
                    <li
                      onClick={() => {
                        handleMailLabel(item, index);
                        setLoading(true);

                        const timer = setTimeout(() => {
                          setLoading(false);
                        }, 1500);

                        // 클린업 타이머
                        return () => clearTimeout(timer);
                      }}
                      key={`user-${item.id}`}
                    >
                      <div className={styles.labelArea}>
                        <div
                          className={`${
                            selectedLabel.id === item.id ? styles.selectIcon : styles.nonSelectIcon
                          }`}
                        />
                        <span className={styles.labelName}>{item.name}</span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      )}
      <div className={styles.expand_mailList_container} ref={mailListRef}>
        {isReconnectNeeded ? (
          <Reconnect kind={selectedRow.kind} />
        ) : isIntegrationDataFirstLoading || loading ? (
          <LoadingComponent />
        ) : (
          listData &&
          (selectedRow.kind === "slack" ? (
            <div className={styles.expand_mailList}>
              {listData && listData.length > 0 ? (
                <>
                  {listData.map((item, index) => (
                    <SlackItem
                      key={index}
                      data={item}
                      integrationId={selectedRow.id}
                      creator={creator}
                      setData={setData}
                      listData={listData}
                      reloadFunction={reloadFunction}
                    />
                  ))}
                </>
              ) : (
                <span className={styles.mailItem_empty}>Empty</span>
              )}
            </div>
          ) : (
            <div className={styles.expand_mailList}>
              {listData && listData.length > 0 ? (
                <>
                  {listData.map((item, index) => (
                    <ItemComponent
                      key={index}
                      data={item}
                      integrationId={selectedRow.id}
                      creator={creator}
                      setData={setData}
                      listData={listData}
                      reloadFunction={reloadFunction}
                      // setIsLoading={setIsLoading}
                    />
                  ))}
                </>
              ) : (
                <span className={styles.mailItem_empty}>Empty</span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

IntegrationComponent.defaultProps = {
  mailLabels: [],
  mailListRef: null,
  mailDropdownOpen: false,
  toggleMailDropdown: () => {},
  handleMailLabel: () => {},
  reloadFunction: () => {},
  selectedLabel: null,
};

export default IntegrationComponent;
