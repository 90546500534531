import { useEffect, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CalendarViewType } from "../../constants";
import { meetWithAccountsState, projectColorListState } from "../../recoil/account/accountState";
import {
  calendarEventDuplicateItemState,
  calendarViewState,
} from "../../recoil/calendar/calendarState";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import iconForVisibility from "../../utils/taskDetail/visibility/iconForVisibility";
import SidebarModal from "../TaskInbox/SidebarModal";
import "./CustomEvent.css";
import styles from "./CustomEvent.module.css";
import { toastState } from "../../recoil/toast/toastState";
import formatTime from "../../utils/common/dateTime/formatTime";
import { ReactComponent as BlockLinkIcon } from "../../assets/Integration/blockLink.svg";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";

export default function CustomEvent({ event, loadData, onEventChange, localizer }) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [calendarView, setCalendarView] = useRecoilState(calendarViewState);
  const setToast = useSetRecoilState(toastState);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const [rowIcon, setRowIcon] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [eventColor, setEventColor] = useState(null);
  const [integrationImg, setIntegrationImg] = useState(null);
  const api = useApi();
  const eventRef = useRef(null);
  const tooltipRef = useRef(null);
  const checkboxRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [eventHover, setEventHover] = useState(false);
  const [isTitleOverflow, setIsTitleOverflow] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState(null);
  const trackCall = useJuneTrackCall();

  const getDateTimeDiffMSec = (startDate, endDate) => {
    return new Date(endDate).getTime() - new Date(startDate).getTime();
  };

  const getEventTimeDuration = () => {
    const eventMinutes = getDateTimeDiffMSec(event.start, event.end) / (60 * 1000);
    return eventMinutes >= 60
      ? `${Math.ceil((eventMinutes / 60) * 100) / 100}h`
      : `${eventMinutes}min`;
  };
  const eventMinutes = getDateTimeDiffMSec(event.start, event.end) / (60 * 1000);

  useEffect(() => {
    if (event.projectId) {
      setEventColor(projectColorList[event.projectId]);
    } else if (event.color != null) {
      setEventColor(event.color);
    } else {
      setEventColor("#abadad");
    }

    if (event.integration?.provider != null) {
      setIntegrationImg(event.integration.provider);
    } else {
      setIntegrationImg(null);
    }

    setIsDone(event.itemStatus === "Completed");
  }, [event, projectColorList]);

  useEffect(() => {
    setRowIcon(true);
  }, [event]);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const convertData = {
      ...event,
      itemStatus: !isDone ? "Completed" : "InProgress",
    };

    setIsDone(convertData.itemStatus === "Completed");
    onEventChange(convertData);

    convertData.itemStatus === "Completed" &&
      setToast({
        type: "Done",
        isVisible: true,
        message: "Task marked as done",
      });

    setRowIcon(!rowIcon);

    api
      .patch("tasks/" + event.id + "/" + (isDone ? "undone" : "done"), "", {
        headers: { "X-Requester": event.creator },
      })
      .then(() => {
        // loadData(true, false, true);
        !isDone
          ? trackCall("done_block", {
              location: "calendar",
            })
          : trackCall("undone_block", {
              location: "calendar",
            });
      })
      .catch((error) => {
        loadData(true, true, true);
      });
  };

  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  const taskIsDone = useMemo(
    () => (event.taskType === "Task" && event.itemStatus === "Completed" ? true : false),
    [event]
  );
  const eventIsDone = useMemo(() => {
    if (event.taskType === "Event") {
      return event.allDay
        ? localizer.format(new Date(event.end), "YYYYMMDD") -
            localizer.format(new Date(), "YYYYMMDD") <
            1
        : getDateTimeDiffMSec(new Date(), event.end) < 0;
    }
    return false;
  }, [event, event.end, localizer]);

  const notDoneTask = useMemo(() => {
    if (event.isMeetWith) return false;

    const allDayDiff =
      localizer.format(new Date(event.end), "YYYYMMDD") ===
      localizer.format(new Date(event.start), "YYYYMMDD")
        ? 0
        : 1;

    const notDone = event.allDay
      ? localizer.format(new Date(event.end), "YYYYMMDD") -
          localizer.format(new Date(), "YYYYMMDD") <
        allDayDiff
      : getDateTimeDiffMSec(new Date(), event.end) < 0;

    return event.taskType === "Task" && event.itemStatus !== "Completed" && notDone;
  }, [event, event.end, localizer]);

  const eventStyle = useMemo(() => {
    const baseColor = "#ABADAD";
    const baseBgOpacity = event.taskType === "Task" ? "0D" : "26";
    let nowColor = baseColor;
    let nowBgOpacity = baseBgOpacity;

    if (meetWithAccounts.length > 0) {
      nowColor = event.isMeetWith ? event.backgroundColor : baseColor;
    } else if (event.projectId) {
      nowColor = projectColorList[event.projectId] ?? baseColor;
    } else if (event.color) {
      nowColor = event.color;
    }

    if (eventHover || (taskDetail.isVisible && taskDetail.data.id === event.id)) {
      nowBgOpacity = event.taskType === "Task" ? "26" : "40";
    }

    if (meetWithAccounts.length > 0 && event.isMeetWith) {
      return {
        borderColor: "#ffffff",
        backgroundColor: nowColor,
      };
    }

    return {
      borderColor: nowColor,
      backgroundColor: `${nowColor}${nowBgOpacity}`,
    };
  }, [event, projectColorList, meetWithAccounts, taskIsDone, taskDetail, eventHover]);

  const checkboxStyle = useMemo(() => {
    const baseColor = meetWithAccounts.length === 0 && eventColor ? eventColor : "#abadad";

    return {
      backgroundColor: hover ? baseColor : `${baseColor}${isDone ? "" : "33"} `,
      boxShadow: `0 0 0 1px ${baseColor} inset`,
    };
  }, [eventColor, hover, isDone, meetWithAccounts]);

  const handleTitleTooltip = (e) => {
    if (
      e.target.clientWidth >= e.target.scrollWidth &&
      e.target.clientHeight >= e.target.scrollHeight
    ) {
      return;
    }

    const calendarEl = document.querySelector(`.rbc-calendar`);
    const calendarBound = calendarEl.getBoundingClientRect();

    const style = {};
    const eventRefBound = eventRef?.current?.getBoundingClientRect();
    const tooltipBound = tooltipRef?.current?.getBoundingClientRect();

    const tooltipMaxWidth = calendarBound.width - 20;
    const tooltipWidth =
      tooltipBound.width > tooltipMaxWidth ? tooltipMaxWidth : tooltipBound.width;
    const defaultLeft = eventRefBound.left + eventRefBound.width / 2 - tooltipWidth / 2;

    style.maxWidth = tooltipMaxWidth;
    style.top = eventRefBound.top - 22 - 5;
    style.left = defaultLeft;

    // 툴팁 왼쪽이 잘리는경우
    if (defaultLeft < calendarBound.left) {
      style.left = calendarBound.left + 10;
    }

    // 툴팁 오른쪽이 잘리는경우
    if (defaultLeft + tooltipWidth > calendarBound.right) {
      style.left = calendarBound.right - tooltipWidth - 10;
    }

    setTooltipStyle(style);
    setIsTitleOverflow(true);
  };

  const getEventContainerSizeClass = () => {
    if (calendarView === CalendarViewType.MONTH.type || event.allDay) {
      return styles.mediumEvent;
    }
    if (eventMinutes <= 15) {
      return styles.smallEvent;
    }
    if (eventMinutes >= 45) {
      return styles.largeEvent;
    }
    return "";
  };

  return (
    <>
      <div
        ref={eventRef}
        className={`event_wrapper ${styles.container}
        ${getEventContainerSizeClass()}
        ${taskIsDone || eventIsDone ? styles.eventDone : ""}
        ${notDoneTask ? styles.notDoneTask : ""}
        ${event.isMeetWith ? styles.meetWithContainer : ""}
        `}
        onMouseEnter={() => setEventHover(true)}
        onMouseLeave={() => setEventHover(false)}
      >
        <div
          className={`${styles["wrap"]} `}
          style={{ backgroundColor: eventStyle.backgroundColor }}
        >
          <span
            className={styles.eventBorder}
            style={{ borderColor: eventStyle.borderColor }}
          ></span>
          {event.isMeetWith ? (
            <div
              onMouseOver={handleTitleTooltip}
              onMouseLeave={() => setIsTitleOverflow(false)}
              className={styles.title}
            >
              {event.title ? event.title : `busy`}
            </div>
          ) : (
            <>
              {event.hangoutLink ? (
                <a
                  href={event.hangoutLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={styles.meetLinkWrapper}
                >
                  <div className={styles.blockLinkIcon}>
                    <BlockLinkIcon />
                  </div>
                  <div className={styles["meet"]}></div>
                </a>
              ) : (
                event.taskType === "Task" && (
                  <div
                    ref={checkboxRef}
                    className={`${styles["checkbox"]} ${
                      styles[`${isDone ? "checkboxCompleted" : null}`]
                    }`}
                    style={checkboxStyle}
                    onClick={handleClick}
                    onMouseEnter={handleIconEnter}
                    onMouseLeave={handleIconLeave}
                  ></div>
                )
              )}

              <div className={styles.contents}>
                <div
                  onMouseOver={handleTitleTooltip}
                  onMouseLeave={() => setIsTitleOverflow(false)}
                  className={styles["title"]}
                >
                  {event.title
                    ? event.title
                    : event.isCreateSelectEvent
                      ? `New ${event.taskType}`
                      : "No title"}
                </div>
                {calendarView !== CalendarViewType.MONTH.type &&
                  !event.allDay &&
                  eventMinutes >= 45 && (
                    <div className={styles.timeDuration}>
                      {`${formatTime(event.start, "12-hour")} - ${formatTime(event.end, "12-hour")} / ${getEventTimeDuration(eventMinutes)}`}
                    </div>
                  )}
              </div>
              {event.visibility && event.visibility === "private" ? (
                <div className={styles.iconForVisibility}>
                  {iconForVisibility(event.visibility, event.transparency)}
                </div>
              ) : null}
              {integrationImg && (
                <a
                  href={event.integration?.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                  className={styles.integrationWrapper}
                >
                  <div className={styles.blockLinkIcon}>
                    <BlockLinkIcon />
                  </div>
                  <div
                    className={`${styles["integration"]} ${
                      integrationImg === "gmail"
                        ? styles["integration-gmail"]
                        : integrationImg === "jira"
                          ? styles["integration-jira"]
                          : integrationImg === "slack" && styles["integration-slack"]
                    }
              `}
                  ></div>
                </a>
              )}
            </>
          )}
        </div>
      </div>
      {calendarView === CalendarViewType.MONTH.type ? (
        createPortal(
          <div
            ref={tooltipRef}
            className="event-tooltip"
            style={{
              left: tooltipStyle && tooltipStyle.left,
              top: tooltipStyle && tooltipStyle.top,
              maxWidth: tooltipStyle && tooltipStyle.maxWidth,
              opacity: isTitleOverflow && 1,
              height: isTitleOverflow && "auto",
              padding: isTitleOverflow && "4px",
            }}
          >
            {event.title ? event.title : `New ${event.taskType}`}
          </div>,
          document.querySelector("#event-tooltip")
        )
      ) : (
        <div
          ref={tooltipRef}
          className="event-tooltip"
          style={{
            left: tooltipStyle && tooltipStyle.left,
            top: tooltipStyle && tooltipStyle.top,
            maxWidth: tooltipStyle && tooltipStyle.maxWidth,
            opacity: isTitleOverflow && 1,
            height: isTitleOverflow && "auto",
            padding: isTitleOverflow && "4px",
          }}
        >
          {event.title ? event.title : `New ${event.taskType}`}
        </div>
      )}
    </>
  );
}

export function CustomEventWrapper({
  event,
  children,
  onClick,
  loadData,
  onEventDelete,
  onDataDuplicate,
  onEventChange,
}) {
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const api = useApi();
  const [isExpand, setIsExpand] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
  const setToast = useSetRecoilState(toastState);
  const [duplicateItem, setDuplicateItem] = useRecoilState(calendarEventDuplicateItemState);

  const wrapRef = useRef(null);
  const [, drop] = useDrop({
    accept: "task",
    drop: (event) => {},
  });
  const trackCall = useJuneTrackCall();
  useEffect(() => {
    if (event.isDataDuplicateEvent) {
      if (event.attendees && event.attendees.length > 0) {
        let eventsContainer = wrapRef.current.closest(".rbc-event");

        if (!eventsContainer) {
          eventsContainer = wrapRef.current.querySelector(".rbc-event");
        }
        setDuplicateItem(eventsContainer);
        const rect = eventsContainer ? eventsContainer.getBoundingClientRect() : null;

        if (rect) {
          const newEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
            clientX: rect.left + rect.width,
            clientY: rect.top + rect.height,
          });

          wrapRef.current.dispatchEvent(newEvent);
        }
      } else {
        onEventChange({ ...event, isDataDuplicateEvent: false });
      }
    }
  }, [event]);

  const handleEvent = (e) => {
    if (!event.data) return;
    const { clientX, clientY } = e;
    setModalPosition({ x: clientX, y: clientY });
    setIsExpand(false);
  };

  const handleItemContextMenu = (e) => {
    e.preventDefault();
    //inputRef.current.blur();
    const { clientX, clientY } = e;

    // allDay영역은 Fixed position이 위의 부모 Div에서 Translate옵션이 있어서, 웹페이지 전체가 아닌 부모에 묶여버린다.

    setModalPosition({ x: clientX, y: clientY });

    //getBoundingClientRect().left + window.scrollX
    setIsSidebarModalOn(true);
  };

  const handleDelete = () => {
    onEventDelete(event.id);
    setIsSidebarModalOn(false);
    // 캘린더에서 우클릭 contextMenu로 삭제 시
    setToast({
      type: "Delete",
      isVisible: true,
      message: "Event has been deleted",
    });
    api
      .patch("tasks/" + event.id + "/mark", "", {
        headers: { "X-Requester": event.creator },
      })
      .then(() => {
        trackCall("delete_block", {
          location: "calendar",
          type: event.taskType,
        });
      });
  };

  const handleDataDuplicate = (e) => {
    onDataDuplicate(e, event);
    setIsSidebarModalOn(false);
  };

  const handleClickEvent = (e) => {
    const rect = wrapRef.current.getBoundingClientRect();
    let scrolledY = 0;
    let leftSpace = 0;
    let topSpace = 0;

    const eventsContainer = document.querySelector(".rbc-time-content");
    if (eventsContainer) {
      scrolledY = eventsContainer.scrollTop;
    }

    const newPoisition = {
      left: rect.left + leftSpace,
      top: rect.top + scrolledY + topSpace,
      width: rect.width,
      height: e.target.getBoundingClientRect().height,
    };

    onClick(newPoisition, event);
  };

  return (
    <>
      <div ref={drop}>
        <div
          ref={wrapRef}
          onContextMenu={handleItemContextMenu}
          onClick={(e) => onClick(e, event, wrapRef.current.getBoundingClientRect())}
        >
          {children}
        </div>
      </div>
      {isSidebarModalOn &&
        createPortal(
          <SidebarModal
            x={modalPosition.x}
            y={modalPosition.y}
            onDelete={handleDelete}
            onDuplicate={handleDataDuplicate}
            onClose={() => setIsSidebarModalOn(false)}
          />,
          document.body
        )}
    </>
  );
}
