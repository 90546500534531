import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { createPortal } from "react-dom";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";

import ResourceAddModal from "./LinkAddModal";
import PropertyTitle from "../Common/PropertyTitle";

import { ReactComponent as LinkIcon } from "../../../assets/TaskDetail/link-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/Common/add-icon.svg";
import { ReactComponent as MoreIcon } from "../../../assets/TaskDetail/more-icon.svg";

import styles from "./LinkModal.module.css";

export default function LinkModal({
  expand,
  linkMoreModalRef,
  integration,
  handleMoveIntegration,
  getBackgroundImgUrl,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);

  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState(null);
  const [iconPos, setIconPos] = useState(-100);
  const [isResourceAddModalOn, setIsResourceAddModalOn] = useState(false);
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(-1);

  const [selectedItem, setSelectedItem] = useState(null);

  const { data } = taskDetail;
  const trackCall = useJuneTrackCall();

  const updateTaskConfig = (key, value, index) => {
    if (key === "links") {
      setTaskDetail((prevState) => {
        // 기존의 링크 배열을 복사
        const newLinks = prevState.data.links ? [...prevState.data.links] : [];

        if (index !== undefined) {
          // 특정 인덱스의 링크를 업데이트
          newLinks[index] = value;
        } else {
          // 새로운 링크를 추가
          newLinks.push(value);
        }

        return {
          ...prevState,
          data: {
            ...prevState.data,
            [key]: newLinks,
          },
        };
      });
    } else {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
      }));
    }
  };

  const handleIconEnter = (index) => (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setVisibleTooltipIndex(index);
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setVisibleTooltipIndex(null);
    setIconPos(-100);
  };

  const handleDeleteLink = (item) => {
    setTaskDetail((prevTask) => ({
      ...prevTask,
      data: {
        ...prevTask.data,
        links: prevTask.data.links.filter((link) => link !== item),
      },
    }));
    setIsSidebarModalOn(-1);
  };

  const handleResourceAdd = () => {
    setIsResourceAddModalOn(true);
  };

  const handleMove = (url) => () => {
    trackCall("click_link", { location: expand ? "full" : "modal" });
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://www." + url, "_blank");
    }
  };

  const handleEditLink = (index) => {
    setSelectedItem({
      ...data.links[index],
      index: index,
    });
    setIsResourceAddModalOn(true);
    setIsSidebarModalOn(-1);
  };

  const getResourceItemPosition = (index) => {
    const element = document.querySelector(`.${styles["resource-item"]}:nth-child(${index + 1})`);
    const rect = element.getBoundingClientRect();
    return { top: `${rect.top + 35}px`, left: `${rect.right - 100}px` };
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (linkMoreModalRef.current && !linkMoreModalRef.current.contains(event.target)) {
        setIsSidebarModalOn(-1);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles["setting-property-resource"]}>
        <PropertyTitle Icon={LinkIcon} label="Links" expand={expand} />

        {(data.links != null && data.links.length > 0) || integration ? (
          <div className={styles["property--links__add"]}>
            {integration && (
              <div
                className={styles["resource-item"]}
                onClick={handleMoveIntegration}
                key={integration.id}
              >
                <div
                  className={styles["resource-item-favicon"]}
                  style={{ backgroundImage: `url('${getBackgroundImgUrl()}')` }}
                ></div>
                <div className={styles.resource_name}>
                  <span>{integration.title}</span>
                </div>
              </div>
            )}
            {data.links &&
              data.links.map((item, index) => (
                <div
                  key={item.id}
                  className={styles["resource-item"]}
                  onMouseEnter={handleIconEnter(index)}
                  onMouseLeave={handleIconLeave}
                  onClick={handleMove(item.url)}
                >
                  <div
                    className={styles["resource-item-favicon"]}
                    style={{
                      backgroundImage:
                        "url(https://www.google.com/s2/favicons?sz=64&domain_url=" + item.url + ")",
                    }}
                  ></div>
                  <div className={styles.resource_name}>
                    <span>{item.name}</span>
                  </div>

                  <button
                    className={styles["resource-item-more"]}
                    style={{
                      visibility: `${visibleTooltipIndex === index ? "visible" : "hidden"}`,
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isSidebarModalOn === index) {
                        setIsSidebarModalOn(-1);
                        return;
                      } else {
                        setIsSidebarModalOn(index);
                      }
                    }}
                  >
                    <MoreIcon className={styles["resource-item-more__icon"]} />
                  </button>

                  {isSidebarModalOn === index &&
                    createPortal(
                      <div
                        ref={linkMoreModalRef}
                        className={styles["modal"]}
                        style={getResourceItemPosition(index)}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditLink(index);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteLink(item);
                          }}
                        >
                          Delete
                        </button>
                      </div>,
                      document.body
                    )}
                </div>
              ))}

            <AddIcon className={styles.resource__add_btn} onClick={handleResourceAdd} />
          </div>
        ) : (
          <button className={styles.property__empty_title} onClick={handleResourceAdd}>
            <span>Add a link</span>
          </button>
        )}
      </div>

      {isResourceAddModalOn && (
        <ResourceAddModal
          selectedItem={selectedItem}
          onSave={(key, value, index) => updateTaskConfig(key, value, index)}
          onClose={() => setIsResourceAddModalOn(false)}
        />
      )}
    </>
  );
}
