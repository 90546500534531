import { useState, useCallback } from "react";
import useApi from "../services/auth/useApi";
import { deletedProjectListState } from "../recoil/projects/projectListState";
import { useRecoilState } from "recoil";

const useFetchDeletedProjects = () => {
  const api = useApi();
  const [error, setError] = useState(null);
  const [deletedProjectList, updateDeletedProjectList] = useRecoilState(deletedProjectListState);

  const fetchDeletedProjects = useCallback(() => {
    try {
      api.get("/projects").then((res) => {
        const resFilterData = res.data.projects.filter(
          (projectDataRow) => projectDataRow.status === "Deleted"
        );
        updateDeletedProjectList(resFilterData);
      });
    } catch (err) {
      setError(err);
    }
  }, []);

  return { error, fetchDeletedProjects };
};

export default useFetchDeletedProjects;
