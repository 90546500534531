import styles from "./Calendar.module.css";
import { ReactComponent as VisibilityDeepworkIcon } from "../../assets/TaskDetail/visibility-deepwork.svg";
import { ReactComponent as VisibilityInvisibleIcon } from "../../assets/TaskDetail/visibility-invisible.svg";
import { ReactComponent as VisibilityInvisibleIconLight } from "../../assets/TaskDetail/visibility-invisible-light.svg";
import { ReactComponent as VisibilityPublicIcon } from "../../assets/TaskDetail/visibility-public.svg";

const ToggleSwitch = ({ title, options, selectedOption, onOptionChange }) => {
  const optionToViewVisibility = (option) => {
    if (option === "public") {
      return (
        <>
          <VisibilityPublicIcon
            style={{
              fill: selectedOption == "public" ? "#fff" : "#7c7d7d",
              width: "16px",
              height: "16px",
              flexShrink: "0",
              marginRight: "4px",
            }}
          />
          <span className={styles.switch__text}>Public</span>
        </>
      );
    } else if (option === "invisible") {
      return (
        <>
          {selectedOption == "invisible" ? (
            <>
              <VisibilityInvisibleIconLight
                style={{
                  width: "16px",
                  height: "16px",
                  flexShrink: "0",
                  marginRight: "4px",
                }}
              />
              <span className={styles.switch__text}>Invisible</span>
            </>
          ) : (
            <>
              <VisibilityInvisibleIcon
                style={{
                  width: "16px",
                  height: "16px",
                  flexShrink: "0",
                  marginRight: "4px",
                }}
              />
              <span className={styles.switch__text}>Invisible</span>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <VisibilityDeepworkIcon
            style={{
              fill: selectedOption == "deepwork" ? "#fff" : "#7c7d7d",
              width: "16px",
              height: "16px",
              flexShrink: "0",
              marginRight: "4px",
            }}
          />
          <span className={styles.switch__text}>Deep Work</span>
        </>
      );
    }
  };

  const optionToView = (option) => {
    if (option == 15) {
      return "15 mins";
    } else if (option == 30) {
      return "30 mins";
    } else if (option == 60) {
      return "1 hour";
    } else {
      return option;
    }
  };

  return (
    <div className={styles.calendar__setting__item}>
      <div className={styles.description}>{title}</div>
      <div className={styles.switch__container}>
        {options.map((option) => (
          <div
            key={option}
            className={`${styles.switch__item} ${selectedOption === option ? styles.selected : ""}`}
            onClick={() => onOptionChange(option)}
          >
            {title === "Visibility" ? (
              optionToViewVisibility(option)
            ) : (
              <span className={styles.switch__text}>{optionToView(option)}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToggleSwitch;
