import { useEffect, useRef, useState } from "react";
import { PROJECT_MODAL_LIST_COLOR } from "../../constants/index";
import styles from "./ActionMenuModal.module.css";
import Circle from "./Circle";

export default function ActionMenuModal({
  x,
  y,
  onProcessDone,
  onDelete,
  onClose,
  onChangeColor,
  isDone,
  projectColor,
  onEdit,
  type = "other",
}) {
  const modalStyle = {
    top: `${y}px`,
    left: `${x}px`,
  };

  const [selectedCircle, setSelectedCircle] = useState(projectColor.toUpperCase());

  const handleCircleClick = (colorValue) => {
    onChangeColor(colorValue);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (modalRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div style={modalStyle} className={styles["modal"]} ref={modalRef}>
      <div className={styles.container}>
        {PROJECT_MODAL_LIST_COLOR.map((colorValue) => (
          <Circle
            key={colorValue}
            id={colorValue}
            circleColor={colorValue}
            selected={colorValue === selectedCircle}
            onClick={handleCircleClick}
          />
        ))}
      </div>
      <hr />
      {type === "sidebarProject" ? (
        <button className={styles.modalChild} onClick={onEdit}>
          Edit
        </button>
      ) : null}
      <button className={styles.modalChild} onClick={onProcessDone}>
        {isDone ? "Undone" : "Done"}
      </button>
      <button className={styles.modalChild} onClick={onDelete}>
        Delete
      </button>
    </div>
  );
}
