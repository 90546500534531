import styles from "./PropertyTitle.module.css";
import clsx from "clsx";

interface PropertyTitleProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  expand: boolean;
}

function PropertyTitle({ Icon, label, expand }: PropertyTitleProps) {
  return (
    <div
      className={clsx(styles.property__title, {
        [styles["property__title--collapsed"]]: !expand,
        [styles["property__title--expanded"]]: expand,
      })}
    >
      <Icon className={styles.property__icon} />
      {expand && (
        <div className={styles.property__label}>
          <span>{label}</span>
        </div>
      )}
    </div>
  );
}

export default PropertyTitle;
