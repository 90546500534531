export default function formatDateTimeForJune(date) {
  const changedDate = new Date(date);

  const padZero = (num) => (num < 10 ? "0" + num : num);

  const year = changedDate.getFullYear();
  const month = padZero(changedDate.getMonth() + 1);
  const day = padZero(changedDate.getDate());
  const hours = padZero(changedDate.getHours());
  const minutes = padZero(changedDate.getMinutes());
  const seconds = padZero(changedDate.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
