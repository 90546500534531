import { useState, useCallback } from "react";
import useApi from "../services/auth/useApi";

import { useRecoilState } from "recoil";
import { deletedTaskListState } from "../recoil/taskList/deletedTaskListState";

const useFetchDeletedTasks = () => {
  const api = useApi();
  const [error, setError] = useState(null);
  const [deletedTaskList, updateDeletedTaskList] = useRecoilState(deletedTaskListState);

  const fetchDeletedTasks = useCallback(() => {
    try {
      api.get("/tasks?status=Deleted").then((res) => {
        updateDeletedTaskList(res.data.tasks);
      });
    } catch (err) {
      setError(err);
    }
  }, []);

  return { fetchDeletedTasks };
};

export default useFetchDeletedTasks;
