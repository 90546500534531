import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { projectDetailRepeat } from "../../../constants";

import generateRecurrence from "../../../utils/taskDetail/generateRecurrence";

import DeleteButton from "../Common/DeleteButton";

import { ReactComponent as CheckIcon } from "../../../assets/TaskDetail/check-icon.svg";

import clsx from "clsx";

import styles from "./RepeatModal.module.css";

export default function RepeatModal({
  onClose,
  position,
  isRepeatSetted,
  setIsRepeatSetted,
  setIsRepeatModalOn,
  setIsRepeatAddModal,
  isCustomRepeatSetted,
  setIsCustomRepeatSetted,
  repeatModalRef,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);

  const [isPropertyModal, setIsPropertyModal] = useState(null);
  const [isRepeatHover, setIsRepeatHover] = useState(false);
  const [isRepeatChanged, setIsRepeatChanged] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);

  const wrapRef = useRef(null);
  const triggerRef = useRef(null);

  const { data } = taskDetail;

  const updateTaskConfig = (key, value) => {
    setTaskDetail((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: [value],
      },
    }));
    setIsPropertyModal(false);
    setIsRepeatChanged(true);
  };

  const handleRepeatAdd = (e) => {
    e.stopPropagation();
    if (!isCustomRepeatSetted) {
      setIsRepeatAddModal(true);
      setIsRepeatSetted(false);
    } else {
      handleDeleteRepeat(e);
    }
    setIsRepeatModalOn(false);
  };

  const handleIsPropertyModal = (event) => {
    if (!data.start) {
      return;
    }
    event.stopPropagation();
    setIsRepeatModalOn(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (
        wrapRef.current &&
        !wrapRef.current.contains(event.target) &&
        !repeatModalRef.current.contains(event.target)
      ) {
        setIsPropertyModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapRef]);

  const handleDeleteRepeat = (event) => {
    event.stopPropagation();
    setTaskDetail((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        recurrence: [],
      },
    }));
    setIsRepeatSetted(false);
    setIsCustomRepeatSetted(false);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "ArrowDown") {
        setFocusIndex((prevIndex) => (prevIndex + 1) % (projectDetailRepeat.length + 1));
      } else if (event.key === "ArrowUp") {
        setFocusIndex(
          (prevIndex) =>
            (prevIndex - 1 + projectDetailRepeat.length + 1) % (projectDetailRepeat.length + 1)
        );
      } else if (event.key === "Enter") {
        const focusedElement = wrapRef.current.querySelector(`[data-index="${focusIndex}"]`);
        if (focusedElement) {
          focusedElement.click();
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [projectDetailRepeat.length, focusIndex]);

  useEffect(() => {
    if (wrapRef.current) {
      const focusedElement = wrapRef.current.querySelector(`[data-index="${focusIndex}"]`);
      if (focusedElement) {
        focusedElement.focus();
      }
    }
  }, [focusIndex]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (repeatModalRef.current && !repeatModalRef.current.contains(event.target)) {
        setIsRepeatModalOn(false);
      }
    }

    function handleEscapeKey(event) {
      event.stopPropagation();
      if (event.key === "Escape") {
        setIsRepeatModalOn(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [repeatModalRef]);

  // custom repeat 설정되었는지 파악하기 위해 필요한 effect
  useEffect(() => {}, [isRepeatChanged, isCustomRepeatSetted]);
  return (
    <>
      <div
        className={styles.repeatModalWrapper}
        style={position}
        ref={repeatModalRef}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            e.stopPropagation();
            setIsRepeatModalOn(false);
          }
        }}
      >
        <div
          className={styles["setting-property-btn"]}
          // onMouseEnter={handleRepeatAreaEnter}
          // onMouseLeave={handleRepeatAreaLeave}
          onClick={onClose}
        >
          <span
            className={`${styles["setting-property-icon"]} ${
              data.recurrence && data.recurrence.length !== 0 && styles["label-on"]
            } ${!data.start && styles["label-disabled"]}`}
          ></span>
          <div style={{ flexGrow: 1 }} className={`${!data.start && styles["label-disabled"]}`}>
            <span
              className={`${styles["setting-property-label"]} ${
                data.recurrence && data.recurrence.length !== 0 && styles["label-on"]
              }`}
            ></span>
          </div>
          {data.recurrence && data.recurrence.length !== 0 && isRepeatHover ? (
            <DeleteButton onDelete={handleDeleteRepeat} />
          ) : null}
        </div>
        <div className={styles["wrap"]} ref={wrapRef}>
          {projectDetailRepeat.map((row, idx) => (
            <div
              key={idx}
              className={clsx(styles["repeat-row"], {
                [styles["repeat-row--focused"]]: idx === focusIndex, // 포커스가 이 요소에 있을 경우 추가 스타일 적용
              })}
              // onClick 함수 완성
              onClick={(e) => {
                e.stopPropagation();
                if (generateRecurrence(row) == data.recurrence) {
                  // 이미 선택된 값이 클릭되었으므로, 값을 삭제
                  handleDeleteRepeat(e);
                  setIsRepeatModalOn(false);
                } else {
                  // 새로운 값이 클릭되었으므로, 값을 업데이트
                  updateTaskConfig("recurrence", generateRecurrence(row));
                  setIsRepeatSetted(true);
                  setIsCustomRepeatSetted(false);
                  setIsRepeatModalOn(false);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  updateTaskConfig("recurrence", generateRecurrence(row));
                  setIsRepeatModalOn(false);
                  setIsRepeatSetted(true);
                  setIsCustomRepeatSetted(false);
                }
              }}
              tabIndex={0}
              data-index={idx}
            >
              <div className={styles["repeat-row-list"]}>
                <CheckIcon
                  className={clsx({
                    [styles.checkIcon]: generateRecurrence(row) != data.recurrence,
                    [styles["checkIcon--checked"]]: generateRecurrence(row) == data.recurrence,
                  })}
                />
                <span>{row}</span>
              </div>
            </div>
          ))}
          {/* New Repeat Settings 영역 */}
          <div
            className={clsx(styles["repeat-row"], {
              [styles["repeat-row--focused"]]: projectDetailRepeat.length === focusIndex, // 포커스가 이 요소에 있을 경우 추가 스타일 적용
            })}
            tabIndex={0}
            data-index={projectDetailRepeat.length}
            onClick={handleRepeatAdd}
            onKeyDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.key === "Enter") {
                if (!isCustomRepeatSetted) {
                  // 첫 설정
                  setIsRepeatAddModal(true);
                  setIsRepeatSetted(false);
                  setIsRepeatModalOn(false);
                } else {
                  handleDeleteRepeat(e);
                  setIsRepeatModalOn(false);
                }
              }
            }}
          >
            <div className={styles["repeat-row-list"]}>
              <CheckIcon
                className={clsx({
                  [styles.checkIcon]: !isCustomRepeatSetted,
                  [styles["checkIcon--checked"]]:
                    data.recurrence.length > 0 && isCustomRepeatSetted,
                })}
              />
              <span>New Repeat Settings</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
