import styles from "./style.module.css";

import { ReactComponent as VerticlaLien } from "../../assets/JoinWaitList/line.svg";

export default function Login() {
  const handleMoveJoinWait = () => {
    window.open("https://form.moba.works/ko/waitlist", "_blank");
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.body}>
        <div className={styles.joinWaitListArea}>
          <span className={styles.welcomeContents}> 🎉</span>
          <div className={styles.title}>
            MOBA has just launched
            <br />a private release version
          </div>
          <VerticlaLien />
          <div className={styles.description}>
            An invitation is required to access the private release version of MOBA. 💌
            <br />
            If you do not have an invitation, please sign up for the waitlist.
            <br />
            We will contact you to let you know when you can use MOBA's first product.
          </div>
        </div>
      </div>
      <div className={styles.joinWaitListBtn} onClick={handleMoveJoinWait}>
        Join Waitlist
      </div>
    </div>
  );
}
