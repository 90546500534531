import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { projectListState } from "../recoil/projects/projectListState";
import useApi from "../services/auth/useApi";

const useFetchProjects = () => {
  const api = useApi();
  const [error, setError] = useState(null);
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  const fetchProjectsData = useCallback(() => {
    try {
      api.get("/projects").then((res) => {
        const resFilterData = res.data.projects.filter(
          (projectDataRow) =>
            projectDataRow.status === "InProgress" || projectDataRow.status === "Completed"
        );
        updateProjectList(resFilterData);
      });
    } catch (err) {
      setError(err);
    }
  }, []);

  return { error, fetchProjectsData };
};

export default useFetchProjects;
