import moment from "moment";
import { useEffect, useRef, useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import DatePicker from "react-datepicker";

import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import formatDate from "../../utils/common/dateTime/formatDate";
import formatDateTime from "../../utils/common/dateTime/formatDateTime";
import formatTime from "../../utils/common/dateTime/formatTime";
import formatTimeInterval from "../../utils/common/dateTime/formatTimeInterval";
import { isTaskDataChanged } from "../../utils/taskDetail/checkDataChanged/isTaskDataChanged";
import { isNoteDataChanged } from "../../utils/taskDetail/checkDataChanged/isNoteDataChanged";

import { projectColorListState } from "../../recoil/account/accountState";
import { timeFormatState, visibilityState } from "../../recoil/calendar/settingCalendar";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { toastState } from "../../recoil/toast/toastState";
import { taskDetailHeightState } from "../../recoil/taskDetail/taskDetailHeightState";

import DateModal from "./DateTime/DateModal";
import GuestModal from "./Guest/GuestModal";
import ProjectModal from "./Projects/ProjectModal";
import LinkModal from "./Link/LinkModal";
import TaskNote from "./Note/TaskNote";
import VideoModal from "./Video/VideoModal";
import NotePreview from "./Note/NotePreview";

import gmailIcon from "../../assets/TaskDetail/integration-gmail-icon.svg";
import jiraIcon from "../../assets/TaskDetail/integration-jira-icon.svg";
import slackIcon from "../../assets/TaskDetail/integration-slack-icon.svg";

import clsx from "clsx";

import "react-datepicker/dist/react-datepicker.css";
import "./DateTime/CustomDatePicker.css";

import styles from "./TaskSetting.module.css";

function isValidationBuffMode(data) {
  const isValidLink =
    data.hangoutLink && typeof data.hangoutLink === "string" && data.hangoutLink.trim() !== "";
  const hasAttendees = Array.isArray(data.attendees) && data.attendees.length > 0;

  return isValidLink || hasAttendees;
}

function checkIfAllDay(eventData) {
  const start = eventData;
  const isAllday = typeof start === "string" && /^\d{4}-\d{2}-\d{2}$/.test(start);
  return isAllday;
}

function areDatesSameDay(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  return year1 === year2 && month1 === month2 && day1 === day2;
}

export default function TaskSetting({
  expand,
  loadData,
  onSave,
  onClose,
  inlineTopPosition,
  guestError,
  setGuestError,
  setVisibilityType,
  isChangeNote,
  isDisabled,
  isNoteChanged,
  setIsChanged,
  linkMoreModalRef,
  videoCreateModalRef,
  repeatModalRef,
  isTask,
  isTimeStart,
  setIsTimeStart,
  titleRef,
  isTitleFocused,
  setIsTitleFocused,
  projectDropdownRef,
  isDateStart,
  setIsDateStart,
  isDateEnd,
  setIsDateEnd,
  isTimeEnd,
  setIsTimeEnd,
  startTimeRef,
  startDateRef,
  endTimeRef,
  endDateRef,
  guestDropdownRef,
  repeatAddModalRef,
  mutateMeetingCode,
  isPendingMeetingCode,
  meetingCode,
  setIsModalNoteClicked,
  isModalNoteClicked,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data } = taskDetail;
  const api = useApi();

  const [isDone, setIsDone] = useState(data.itemStatus === "Completed");
  const [toggleTitle, setToggleTitle] = useState(false);

  // const titleRef = useRef(null);
  const titleClearButtonRef = useRef(null);

  const nowDate = new Date();
  const minutes = nowDate.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;
  nowDate.setMinutes(roundedMinutes);

  const [isSameDay, setIsSameDay] = useState(false);
  const [dateStart, setDateStart] = useState(null);

  const [dateEnd, setDateEnd] = useState(null);

  const [timeStart, setTimeStart] = useState(null);

  const [timeEnd, setTimeEnd] = useState(null);

  const [dateTitle, setDateTitle] = useState("Date/Time");
  const [isAllDay, setIsAllDay] = useState(null);
  const [dateInterval, setDateInterval] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const parentDivRef = useRef(null);
  const [isDatePropertyModal, setIsDatePropertyModal] = useState(null);
  const [isBuffMode, setIsBuffMode] = useState(null);
  const [integration, setIntegration] = useState(null);
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const [taskPopupInitialData, setTaskPopupInitialData] = useState(data);
  const [isSaveActivated, setIsSaveActivated] = useState(false);
  const [toast, setToast] = useRecoilState(toastState);

  const taskDetailHeight = useRecoilValue(taskDetailHeightState);

  const defaultVisibility = useRecoilValue(visibilityState);
  const [isGoogleMeetHover, setIsGoogleMeetHover] = useState(false);
  const [noteModalHeight, setNoteModalHeight] = useState(taskDetailHeight - 40 - 2 - 42);

  const trackCall = useJuneTrackCall();

  const getDateTimeDiffMSec = (startDate, endDate) => {
    return new Date(endDate).getTime() - new Date(startDate).getTime();
  };
  const timeFormat = useRecoilValue(timeFormatState);

  useEffect(() => {
    setIsSaveActivated(
      isTaskDataChanged(taskPopupInitialData, taskDetail.data) ||
        (isNoteDataChanged(taskPopupInitialData.note, taskDetail.data.note) && isChangeNote.current)
    );
  }, [taskDetail.data, taskPopupInitialData]);

  useEffect(() => {
    refreshDate(dateStart, dateEnd, timeStart, timeEnd);
  }, [timeStart, timeEnd, dateStart, dateEnd, setIsModalNoteClicked]);

  useEffect(() => {
    refreshDate(dateStart, dateEnd, timeStart, timeEnd);
  }, [isDatePropertyModal, isAllDay]);

  const refreshDate = useCallback(
    (dateStart, dateEnd, timeStart, timeEnd) => {
      if (dateStart != null && dateEnd != null && timeStart != null && timeEnd != null) {
        const start = new Date(dateStart.getTime());
        start.setHours(timeStart.getHours(), timeStart.getMinutes(), 0);
        const end = new Date(dateEnd.getTime());
        end.setHours(timeEnd.getHours(), timeEnd.getMinutes(), 0);

        setTaskDetail((prev) => {
          if (isAllDay) {
            const endPlus = new Date(end.getTime() + 24 * 60 * 60 * 1000);
            return {
              ...prev,
              data: {
                ...prev.data,
                allDay: true,
                start: { date: moment(start || start.dateTime).format("YYYY-MM-DD") },
                startTimeZone: null,
                end: { date: moment(endPlus).format("YYYY-MM-DD") },
                endTimeZone: null,
              },
            };
          } else {
            return {
              ...prev,
              data: {
                ...prev.data,
                allDay: false,
                start: { dateTime: start || start.dateTime, timeZone: "Asia/Seoul" },
                end: { dateTime: end || end.dateTime, timeZone: "Asia/Seoul" },
              },
            };
          }
        });

        let newTitle;
        if (isAllDay) {
          newTitle = formatDate(start) + " - " + formatDate(end);
          setDateInterval(null);
        } else {
          if (formatDate(start) != formatDate(end)) {
            newTitle = formatDateTime(start, timeFormat) + " - " + formatTime(end, timeFormat);

            const timeDiffMilliseconds = Math.abs(end - start);
            const hours = Math.floor(timeDiffMilliseconds / (60 * 60 * 1000));
            if (hours > 24) {
              setDateInterval(null);
            } else {
              setDateInterval(formatTimeInterval(start, end));
            }
          } else {
            newTitle = formatDateTime(start, timeFormat) + " - " + formatTime(end, timeFormat);
            setDateInterval(formatTimeInterval(start, end));
          }
        }
        setDateTitle(newTitle);
      }
    },
    [isAllDay, dateStart, dateEnd, timeStart, timeEnd]
  );

  useEffect(() => {
    setNoteModalHeight(taskDetailHeight - 40 - 2 - 42);
  }, [isModalNoteClicked]);

  useEffect(() => {
    if (isTitleFocused && data.isCreateSelectEvent) {
      titleRef.current.focus();
    }
  }, [isTitleFocused]);

  useEffect(() => {
    if (data.isCreateSelectEvent) {
      titleRef.current.focus();
    }

    setIsAllDay(checkIfAllDay(data.start));

    if (data.hangoutLink == null && data.attendees != null) {
      function fetchEventData(retryCount = 0) {
        api
          .get("events/" + data.id, {
            headers: { "X-Requester": data.creator },
          })
          .then((res) => {
            if (res.data.event?.hangoutLink != null) {
              setTaskDetail((prev) => ({
                ...prev,
                data: {
                  ...prev.data,
                  hangoutLink: res.data.event.hangoutLink,
                },
              }));
              setIsBuffMode(!isValidationBuffMode(data));

              // 게스트는 있지만 구글밋링크가 없는 이벤트의 경우 구글밋링크를 넣어준다.
              if (taskDetail.handleEventChange) {
                // Calendar에서 이벤트 클릭해서 온경우
                const newData = {
                  ...data,
                  hangoutLink: res.data.event.hangoutLink,
                };
                taskDetail.handleEventChange(newData);
              }
            } else if (retryCount < 2) {
              // retryCount가 2보다 작을 때만 재시도
              setTimeout(() => fetchEventData(retryCount + 1), 2000); // 2초 후에 retryCount를 1 증가시켜 재시도
            }
          });
      }
      fetchEventData();
    }

    setIsBuffMode(!isValidationBuffMode(data));

    if (checkIfAllDay(data.start)) {
      // isAllday일때만 타는 로직
      if (areDatesSameDay(data.start, data.end)) {
        setDateStart(new Date(data.start));
        setDateEnd(new Date(data.end));
        setTimeStart(new Date(data.start));
        setTimeEnd(new Date(data.end));
      } else {
        // start end date가 같지 않을때 이슈 사항, 모바 캘린더는 isAllday 일때 17~17일 1일, 17~18일 1일, 17~19일 2일로 취급
        const dateConverting = new Date(data.end);
        const endPlus = new Date(dateConverting.getTime() - 24 * 60 * 60 * 1000);

        setDateStart(new Date(data.start));
        setDateEnd(endPlus);
        setTimeStart(new Date(data.start));
        setTimeEnd(endPlus);
      }
    } else {
      setDateStart(data.start ? new Date(data.start) : null);
      setDateEnd(data.end ? new Date(data.end) : null);
      setTimeStart(data.start ? new Date(data.start) : null);
      setTimeEnd(data.end ? new Date(data.end) : null);
    }

    if (data.integration != null) {
      setIntegration(data.integration);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (startDateRef.current && !startDateRef.current.contains(event.target)) {
        setIsDateStart(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startDateRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (endDateRef.current && !endDateRef.current.contains(event.target)) {
        setIsDateEnd(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endDateRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (startTimeRef.current && !startTimeRef.current.contains(event.target)) {
        setIsTimeStart(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [startTimeRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (endTimeRef.current && !endTimeRef.current.contains(event.target)) {
        setIsTimeEnd(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [endTimeRef]);

  useEffect(() => {
    handleResizeHeight();
  }, [setIsTitleFocused]);

  const handleSetModalPosition = (event) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({
      x: rect.left + window.scrollX,
      y: rect.top + rect.height + 5 + window.scrollY,
    });
  };

  useEffect(() => {}, [setModalPosition, expand]);

  const handleCheckbox = () => {
    if (isDisabled) {
      return;
    }
    onClose();
    if (taskDetail.handleEventChange) {
      const convertData = {
        ...data,
        itemStatus: !isDone ? "Completed" : "InProgress",
      };
      taskDetail.handleEventChange(convertData);
    }

    setIsDone(!isDone);
    !isDone &&
      setToast({
        type: "Done",
        isVisible: true,
        message: "Task marked as done",
      });
    api
      .patch("tasks/" + data.id + "/" + (isDone ? "undone" : "done"), "", {
        headers: { "X-Requester": data.creator },
      })
      .then(() => {
        loadData(true, true, false);
        !isDone &&
          trackCall("done_block", {
            location: taskDetail.type,
          });
      });
  };

  const handleTitleKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // 기본 동작(줄바꿈)을 방지
      onSave();
    } else if (e.key === "Enter" && e.shiftKey) {
      e.stopPropagation();
    } else if (e.key === "Escape") {
      e.stopPropagation();
      setIsTitleFocused(false);
      titleRef.current.blur();
    }

    if (e.key === "Backspace") {
      e.stopPropagation();
    }
  };

  const handleTitleBlur = (e) => {
    e.stopPropagation();
    setIsTitleFocused(false);
  };

  const handlePropertyBtn = (e = null, name) => {
    if (name !== "project") {
      if (e && e.target !== e.currentTarget) return;
    }
  };

  const updateTaskConfig = (key, value) => {
    // if (isEnter) return;
    if (key === "links") {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: [...(prevState.data[key] || []), value],
        },
      }));
    } else {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
      }));
    }
  };

  const handleTitleFocus = (event) => {
    setIsTitleFocused(true);

    requestAnimationFrame(() => {
      const len = titleRef.current.value.length;
      titleRef.current.setSelectionRange(len, len);
      handleResizeHeight();
      titleRef.current.focus();
    });
  };

  const handleTitleClear = (event) => {
    event.preventDefault();
    titleRef.current.value = "";
    titleRef.current.focus();
  };

  const handleResizeHeight = () => {
    titleRef.current.style.height = "auto"; //height 초기화

    if (titleRef.current.scrollHeight < 98) {
      titleRef.current.style.height = titleRef.current.scrollHeight + "px";
    } else {
      titleRef.current.style.height = "98px";
    }
  };

  const handleTextareaChange = (e) => {
    updateTaskConfig("title", e.target.value);
    handleResizeHeight();
  };

  const handleTimeStartChange = (e) => {
    const dateTimeDiffMSec = getDateTimeDiffMSec(timeStart, timeEnd);
    setTimeEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));
    setDateEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));

    setIsTimeStart(!isTimeStart);
    setTimeStart(e);
    setDateStart(e);
  };

  const handleTimeEndChange = (e) => {
    setIsTimeEnd(!isTimeEnd);
    setTimeEnd(e);
    setDateEnd(e);
  };

  const handleTimeStart = (e) => {
    if (e.currentTarget != e.target) return;

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.x - parentDivRect.x;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsTimeStart(!isTimeStart);
    setIsDateEnd(false);
    setIsDateStart(false);
    setIsTimeEnd(false);
  };

  const handleTimeEnd = (e) => {
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.x - parentDivRect.x;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsTimeEnd(!isTimeEnd);
    setIsDateStart(false);
    setIsTimeStart(false);
    setIsDateEnd(false);
  };

  const handleDateStart = (e) => {
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.left - parentDivRect.left;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsDateStart(!isDateStart);
    setIsTimeStart(false);
    setIsDateEnd(false);
    setIsTimeEnd(false);
  };
  const handleDateEnd = (e) => {
    if (e.currentTarget != e.target) return;
    e.preventDefault();

    const parentDivRect = parentDivRef.current.getBoundingClientRect();
    const clickedDivRect = e.currentTarget.getBoundingClientRect();

    const x = clickedDivRect.left - parentDivRect.left;
    const y = clickedDivRect.top - parentDivRect.top + clickedDivRect.height;

    setModalPosition({ x: x, y: y });
    setIsDateEnd(!isDateEnd);
    setIsDateStart(false);
    setIsTimeEnd(false);
    setIsTimeStart(false);
  };

  const handleDateStartChange = (e) => {
    if (formatDate(e) == formatDate(dateEnd)) setIsSameDay(true);
    const dateTimeDiffMSec = getDateTimeDiffMSec(dateStart, dateEnd);
    setDateEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));
    setTimeEnd(new Date(new Date(e).getTime() + dateTimeDiffMSec));

    setIsDateStart(!isDateStart);
    setDateStart(e);
    setTimeStart(e);
  };

  const handleDateEndChange = (e) => {
    setIsDateEnd(!isDateEnd);
    setDateEnd(e);
    setTimeEnd(e);
  };

  const handleBuffModeChange = (isBuffMode) => {
    setIsBuffMode(isBuffMode);
    if (!isBuffMode) {
      // NOTE 게스트 있을 시(미팅 시) 기본값은 public
      setVisibilityType("public");
    } else {
      setVisibilityType(defaultVisibility);
    }
  };

  const handleDateClear = () => {
    setDateStart(null);
    setDateEnd(null);
    setTaskDetail((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        start: null,
        end: null,
      },
    }));
  };

  // const handleGoogleMeet = () => {
  //   if (data.hangoutLink != null) {
  //     window.open(data.hangoutLink, "_blank");
  //   }
  // };

  const getBackgroundImgUrl = () => {
    switch (integration.provider) {
      case "gmail":
        return gmailIcon;
      case "jira":
        return jiraIcon;
      case "slack":
        return slackIcon;
      default:
        break;
    }
  };

  const handleMoveIntegration = () => {
    trackCall("click_integration", {
      location: data.taskType,
      type: integration.provider,
    });
    window.open(integration.link, "_blank");
  };

  const [hover, setHover] = useState(false);

  const style = {
    backgroundColor: isDisabled
      ? "#242626"
      : hover
        ? `${data.projectId ? projectColorList[data.projectId] : "#abadad"}`
        : `${data.projectId ? projectColorList[data.projectId] : "#abadad"}${isDone ? "" : "26"} `,
    boxShadow: `0 0 0 1px ${
      isDisabled ? "#484D4D" : data.projectId ? projectColorList[data.projectId] : "#abadad"
    } inset`,
    cursor: isDisabled ? "not-allowed" : "pointer",
  };
  const handleIconEnter = () => {
    setHover(true);
  };

  const handleIconLeave = () => {
    setHover(false);
  };

  const handleGoingClick = () => {
    alert("기능없음");
  };

  return isModalNoteClicked ? (
    <div
      style={{ height: noteModalHeight }}
      className={styles.modalNote}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <TaskNote isChangeNote={isChangeNote} onSave={onSave} expand={expand} />
    </div>
  ) : (
    <>
      <div className={clsx({ [styles["setting"]]: !expand, [styles["setting--expand"]]: expand })}>
        <div
          className={clsx(styles["setting-state"], {
            [styles["setting-state--expand"]]: expand,
          })}
        >
          {isTask && (
            <div
              className={`${styles["setting-state-checkbox"]}
                ${styles[`${isDone ? "checkboxCompleted" : null}`]}
                ${isDisabled ? styles.disabled : ""}`}
              style={style}
              onClick={handleCheckbox}
              onMouseEnter={handleIconEnter}
              onMouseLeave={handleIconLeave}
            ></div>
          )}

          {/*
            이번 프로젝트에서는 제외
            {isBuffMode ? null : (
            <div
              className={styles.googleMeetGoingIcon}
              onClick={handleGoingClick}
            >
              Going?
            </div>
          )} */}

          <div
            className={clsx({
              [styles["setting-title--expand"]]: expand,
              [styles["setting-title"]]: !expand,
            })}
          >
            {isTitleFocused ? (
              <textarea
                className={styles["setting-title-input"]}
                ref={titleRef}
                placeholder={`New ${data.taskType}`}
                onKeyDown={handleTitleKey}
                onBlur={handleTitleBlur}
                onChange={handleTextareaChange}
                value={data.title}
                rows={1}
              />
            ) : (
              <div
                className={styles["setting-title-text"]}
                onClick={handleTitleFocus}
                ref={titleRef}
                data-placeholder={data.title ? "" : `New ${data.taskType}`}
              >
                <span>{data.title && data.title}</span>
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx(styles["setting-property"], {
            [styles["setting-property--expand"]]: expand,
          })}
        >
          <ProjectModal
            loadData={loadData}
            expand={expand}
            projectDropdownRef={projectDropdownRef}
          />
          <DateModal
            isDateStart={isDateStart}
            isDateEnd={isDateEnd}
            isTimeStart={isTimeStart}
            isTimeEnd={isTimeEnd}
            startTimeRef={startTimeRef}
            startDateRef={startDateRef}
            endTimeRef={endTimeRef}
            endDateRef={endDateRef}
            handleTimeStartChange={handleTimeStartChange}
            handleTimeEndChange={handleTimeEndChange}
            getDateTimeDiffMSec={getDateTimeDiffMSec}
            handleDateStart={handleDateStart}
            handleDateEnd={handleDateEnd}
            handleDateStartChange={handleDateStartChange}
            handleDateEndChange={handleDateEndChange}
            dateInterval={dateInterval}
            dateTitle={dateTitle}
            isSameDay={isSameDay}
            setDateStart={setDateStart}
            dateStart={dateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
            setTimeStart={setTimeStart}
            timeStart={timeStart}
            setTimeEnd={setTimeEnd}
            timeEnd={timeEnd}
            handleTimeStart={handleTimeStart}
            handleTimeEnd={handleTimeEnd}
            isAllDay={isAllDay}
            setIsAllDay={setIsAllDay}
            isDatePropertyModal={isDatePropertyModal}
            setIsDatePropertyModal={setIsDatePropertyModal}
            guestError={guestError}
            setGuestError={setGuestError}
            handleDateClear={handleDateClear}
            setIsDateStart={setIsDateStart}
            setIsDateEnd={setIsDateEnd}
            setIsTimeStart={setIsTimeStart}
            setIsTimeEnd={setIsTimeEnd}
            onCloseRepeat={() => handlePropertyBtn(null)}
            expand={expand}
            refreshDate={refreshDate}
            handleSetModalPosition={handleSetModalPosition}
            repeatModalRef={repeatModalRef}
            repeatAddModalRef={repeatAddModalRef}
            isTask={isTask}
          />

          {isTimeStart &&
            createPortal(
              <div
                className={`${styles["datepickerTimeWrap"]} ${"datepickerTimeWrap"}`}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={startTimeRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={timeStart}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat={timeFormat === "24-hour" ? "HH:mm" : "h:mm aaa"}
                  inline
                  onChange={handleTimeStartChange}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>,
              document.body
            )}
          {isDateStart &&
            createPortal(
              <div
                className={styles["datepicker-wrap"]}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={startDateRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={dateStart}
                  selectsStart
                  onChange={handleDateStartChange}
                  startDate={dateStart}
                  endDate={dateEnd}
                  inline
                />
              </div>,
              document.body
            )}

          {isTimeEnd &&
            createPortal(
              <div
                className={`${styles["datepickerTimeWrap"]} ${"datepickerTimeWrap"}`}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={endTimeRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={timeEnd}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat={timeFormat === "24-hour" ? "HH:mm" : "h:mm aaa"}
                  onChange={handleTimeEndChange}
                  inline
                  filterTime={(time) => {
                    const dateTimeDiffMsec = getDateTimeDiffMSec(timeStart, time);
                    return dateTimeDiffMsec > 0;
                  }}
                />
              </div>,
              document.body
            )}

          {isDateEnd &&
            createPortal(
              <div
                className={styles["datepicker-wrap"]}
                style={{
                  top: `${modalPosition.y}px`,
                  left: `${modalPosition.x}px`,
                }}
                ref={endDateRef}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <DatePicker
                  selected={dateEnd}
                  selectsEnd
                  onChange={handleDateEndChange}
                  inline
                  startDate={dateStart}
                  endDate={dateEnd}
                  minDate={dateStart}
                  filterDate={(date) => {
                    if (isAllDay) return true;

                    const isSameDay =
                      new Date(dateStart).toDateString() === new Date(date).toDateString();
                    if (isSameDay) {
                      const endDate = new Date(date).toDateString();
                      const endTime = new Date(dateEnd).toTimeString();
                      const nextDateTime = new Date(`${endDate} ${endTime}`);
                      const dateTimeDiffMsec = getDateTimeDiffMSec(dateStart, nextDateTime);
                      return dateTimeDiffMsec > 0;
                    }

                    const dateTimeDiffMsec = getDateTimeDiffMSec(dateStart, date);
                    return dateTimeDiffMsec > 0;
                  }}
                />
              </div>,
              document.body
            )}
          {!isTask && (
            <>
              <GuestModal
                onClose={() => handlePropertyBtn(null)}
                setIsBuffMode={handleBuffModeChange}
                expand={expand}
                guestDropdownRef={guestDropdownRef}
                mutateMeetingCode={mutateMeetingCode}
              />
              <VideoModal
                expand={expand}
                isBuffMode={isBuffMode}
                videoCreateModalRef={videoCreateModalRef}
                mutateMeetingCode={mutateMeetingCode}
                isPendingMeetingCode={isPendingMeetingCode}
                meetingCode={meetingCode}
              />
            </>
          )}

          <LinkModal
            updateTaskConfig={updateTaskConfig}
            expand={expand}
            linkMoreModalRef={linkMoreModalRef}
            integration={integration}
            handleMoveIntegration={handleMoveIntegration}
            getBackgroundImgUrl={getBackgroundImgUrl}
          />
        </div>
        {/* expand일 때 선택 탭 */}

        {expand && (
          <div className={styles.select_tab}>
            <button className={styles["select_tab--btn"]}>
              <span>Notes</span>
            </button>
          </div>
        )}
        <NotePreview
          expand={expand}
          isChangeNote={isChangeNote}
          onSave={onSave}
          noteData={data.note}
          setIsModalNoteClicked={setIsModalNoteClicked}
        />
      </div>
    </>
  );
}
