import { atom } from "recoil";
import { loadFromLocalStorage } from "../../utils/localStorage/localStorage";
import { CalendarViewType } from "../../constants";

export const draggedEventState = atom({
  key: "draggedEventState",
  default: null,
});

export const calendarEventState = atom({
  key: "calendarEventState",
  default: [],
});

export const calendarViewBaseDateState = atom({
  key: "calendarViewBaseDateState",
  default: new Date(),
});

// TODO 이미 가져온 캘린더 데이터 전체를 캐싱하는 방식으로 변경

export const dragEventState = atom({
  key: "dragEventState",
  default: null,
});

export const saveEventState = atom({
  key: "saveEventState",
  default: null,
});

export const calendarViewState = atom({
  key: "calendarViewState",
  default: loadFromLocalStorage("calendarViewType") ?? CalendarViewType.WEEK.type,
});

export const calendarEventDuplicateItemState = atom({
  key: "calendarEventDuplicateItemState",
  default: null,
});

export const showMorePopupState = atom({
  key: "showMorePopupState",
  default: false,
});
