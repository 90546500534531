import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import styles from "./Wiki.module.css";

import emptyAddIcon from "../../assets/Wiki/icon-empty-add.png";
import emptyIcon from "../../assets/Wiki/icon-empty.svg";

import { filteringProjectIdListState } from "../../recoil/projects/filteringProjectIdListState";
import { projectListState } from "../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../utils/june/analytics";
import ProjectItem from "./ProjectItem";

function ProjectList({ projectData, onClick, loadData }) {
  return (
    <div className={styles.project__body}>
      <div className={styles.project__container}>
        {projectData.map((project, index) => {
          return (
            <ProjectItem
              key={index}
              projectRowData={project}
              onClick={onClick}
              loadData={loadData}
            />
          );
        })}
      </div>
    </div>
  );
}

function ProejctListEmpty({ onClick, isDone }) {
  return (
    <div className={styles.project__empty__body} onClick={() => onClick(true, null)}>
      {isDone ? (
        <>
          <img className={styles.empty__icon} src={emptyIcon} alt="emptyIcon" />
          <span className={styles.empty__description}>No archived project</span>
        </>
      ) : (
        <div className={styles.empty__div} onClick={() => onClick(true, null)}>
          <img className={styles.empty__icon} src={emptyAddIcon} alt="emptyAddIcon" />
          <span className={styles.empty__description}>Create your first project!</span>
        </div>
      )}
    </div>
  );
}

export default function Wiki({ onProjectAddClick, loadData }) {
  const [currentStatusSeleted, setCurrentStatusSeleted] = useState("InProgress");
  const trackCall = useJuneTrackCall();
  const [filteringProjectIdList, setFilteringProjectIdList] = useRecoilState(
    filteringProjectIdListState
  );
  const [projectList, updateProjectList] = useRecoilState(projectListState);

  const memoizedProjectList = useMemo(() => {
    const projectIds = filteringProjectIdList;
    const inProgressProjects = projectList
      ?.filter((project) => project.status === currentStatusSeleted)
      .filter((event) => !projectIds.includes(event.id));

    if (inProgressProjects && inProgressProjects.length === 0) {
      return (
        <ProejctListEmpty
          onClick={onProjectAddClick}
          isDone={currentStatusSeleted !== "InProgress"}
        />
      );
    } else {
      return (
        <ProjectList
          projectData={inProgressProjects}
          onClick={onProjectAddClick}
          loadData={loadData}
        />
      );
    }
  }, [filteringProjectIdList, currentStatusSeleted, projectList]);

  useEffect(() => {
    trackCall("view_project_list");
  }, []);

  return (
    <div className={styles.wiki__body}>
      <div className={styles.wiki__header}>
        <div className={styles.headerInprogessAndDoneArea}>
          <div
            onClick={() => {
              setCurrentStatusSeleted("InProgress");
              trackCall("click_wiki_status", { status: "in progress" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === "InProgress" ? styles.titleSelected : null
            }`}
          >
            In Progress
          </div>
          <div
            onClick={() => {
              setCurrentStatusSeleted("Completed");
              trackCall("click_wiki_status", { status: "done" });
            }}
            className={`${styles.title} ${
              currentStatusSeleted === "Completed" ? styles.titleSelected : null
            }`}
          >
            Done
          </div>
        </div>
        <div
          className={styles.headerCreateProject}
          onClick={() => {
            onProjectAddClick(true, null);
            trackCall("create_project", { location: "wiki" });
          }}
        >
          Create Project
        </div>
      </div>
      {memoizedProjectList}
    </div>
  );
}
