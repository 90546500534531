import { useEffect, useRef } from "react";

const useClickOutside = (handler) => {
  const targetRef = useRef(null);
  const triggerRef = useRef(null);
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const element = targetRef.current;
    const trigger = triggerRef.current;

    if (!element || !trigger) return;

    const handleEvent = (e) => {
      e.stopPropagation();

      if (!element.contains(e.target) && !trigger.contains(e.target)) {
        savedHandler.current(e);
      }
    };

    document.addEventListener("mousedown", handleEvent);

    return () => document.removeEventListener("mousedown", handleEvent);
  }, [targetRef.current, triggerRef.current, savedHandler.current]);

  return { targetRef, triggerRef };
};

export default useClickOutside;
