import { ReactComponent as VisibilityDeepworkIcon } from "../../../assets/TaskDetail/visibility-deepwork.svg";
import { ReactComponent as VisibilityInvisibleIconLight } from "../../../assets/TaskDetail/visibility-invisible-light.svg";
import { ReactComponent as VisibilityInvisibleIconMoba } from "../../../assets/TaskDetail/visibility-invisible-moba.svg";
import { ReactComponent as VisibilityPublicIcon } from "../../../assets/TaskDetail/visibility-public.svg";

import styles from "./Header.module.css";

// TODO 중복 제거
export function getVisiblilityComponent(type, isVisibilityClick, handleVisibilityClick) {
  switch (type) {
    case "public":
      return (
        <button
          className={`${styles.visibilityIcon} ${styles[`${isVisibilityClick ? "active" : null}`]}`}
          onClick={handleVisibilityClick}
        >
          <VisibilityPublicIcon style={{ fill: "#fff" }} />
        </button>
      );
    case "deepwork":
      return (
        <button
          className={`${styles.visibilityIcon} ${styles[`${isVisibilityClick ? "active" : null}`]}`}
          onClick={handleVisibilityClick}
        >
          <VisibilityDeepworkIcon style={{ fill: "#fff" }} />
        </button>
      );
    case "invisible":
      return (
        <button className={`${styles.visibilityIcon} }`} onClick={handleVisibilityClick}>
          {isVisibilityClick ? <VisibilityInvisibleIconMoba /> : <VisibilityInvisibleIconLight />}
        </button>
      );
  }
}

export function getVisiblilityTooltipContents(type) {
  switch (type) {
    case "public":
      return "Anyone can see the details of this event.".split("\n").map((line, index, array) => (
        <div key={index}>
          {line}
          {index < array.length - 1 && <br />}
        </div>
      ));
    case "deepwork":
      return "You can do deep work without interruption.\nIt is marked as busy to other people."
        .split("\n")
        .map((line, index, array) => (
          <div key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </div>
        ));
    case "invisible":
      return "You can focus while still collaborating\nwith others.It is invisible to other people."
        .split("\n")
        .map((line, index, array) => (
          <div key={index}>
            {line}
            {index < array.length - 1 && <br />}
          </div>
        ));
  }
}

export function getVisiblilityDropdownComponent(type, selected) {
  const componentStyle = {
    fill: `${selected ? "#69e4ff" : "#fff"}`,
  };

  switch (type) {
    case "public":
      return <VisibilityPublicIcon className={styles.dropdownIcon} style={componentStyle} />;
    case "deepwork":
      return <VisibilityDeepworkIcon className={styles.dropdownIcon} style={componentStyle} />;
    case "invisible":
      return (
        <>
          {selected ? (
            <VisibilityInvisibleIconMoba className={styles.dropdownIcon} style={componentStyle} />
          ) : (
            <VisibilityInvisibleIconLight className={styles.dropdownIcon} style={componentStyle} />
          )}
        </>
      );
  }
}
