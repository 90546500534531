import { forwardRef, useEffect } from "react";
import ShowMoreItem from "./ShowMoreItem";
import styles from "./index.module.css";

function ShowMorePopup({ events, onClose, children, ...props }, ref) {
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  return (
    <div ref={ref} className={styles.morePopup} {...props}>
      {events &&
        events.map((event) => (
          <ShowMoreItem key={event.id} event={event}>
            {children(event)}
          </ShowMoreItem>
        ))}
    </div>
  );
}

export default forwardRef(ShowMorePopup);
