// private - busy (deep work)의 경우 visibility값만 넘어옴
// private - free (invisible)의 경우 visibility, transparency 두개가 함께 넘어옴
export default function typeForVisibility(visibility, transparency) {
  if (visibility === "public") {
    return "public";
  } else if (visibility === "private" && transparency === "transparent") {
    return "invisible";
  } else if (visibility === "private" && (transparency === "opaque" || transparency == null)) {
    return "deepwork";
  } else if (visibility == null) {
    return "public";
  } else {
    return "public";
  }
}
