import { AnalyticsBrowser } from "@june-so/analytics-next";
import { useCallback } from "react";

const analytics = AnalyticsBrowser.load({
  writeKey: "H7kWfAp9CYMNfnTV",
});

// analytics.track(event, [properties], [options], [callback]);
// https://www.june.so/docs/tracking/web/react
export function useJuneTrackCall() {
  const trackCall = useCallback(
    (eventName, eventProperties) => {
      if (analytics && eventName && eventProperties) {
        analytics.track(eventName, eventProperties);
      } else if (analytics && eventName) {
        analytics.track(eventName);
      }
    },
    [analytics]
  );

  return trackCall;
}

export function useJuneIdentifyCall() {
  const identifyCall = useCallback(
    (email, subscriptionStatus) => {
      if (analytics && email && subscriptionStatus) {
        analytics.identify(email, {
          email,
          subscription_status: subscriptionStatus,
        });
      }
    },
    [analytics]
  );

  return identifyCall;
}
