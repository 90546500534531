import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import "./TaskNote.css";

import { SuggestionMenuController, useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import { BlockNoteSchema, defaultBlockSpecs, locales } from "@blocknote/core";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";

import clsx from "clsx";

import styles from "./TaskNote.module.css";

const MENUS = {
  heading: true,
  numberedListItem: true,
  bulletListItem: true,
  paragraph: true,
  checkListItem: false,
  audio: false,
  table: false,
  image: false,
  video: false,
  file: false,
};

export default function TaskNote({ isChangeNote, onSave, isEditable = true, expand }) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data } = taskDetail;

  const schema = BlockNoteSchema.create({
    blockSpecs: {
      ...Object.fromEntries(Object.entries(defaultBlockSpecs).filter(([key, value]) => MENUS[key])),
    },
  });

  const editor = useCreateBlockNote({
    schema,
    initialContent: data.note ? JSON.parse(data.note) : undefined,
    dictionary: {
      ...locales.en,
      formatting_toolbar: {
        ...locales.en.formatting_toolbar,
        strike: {
          ...locales.en.formatting_toolbar.strike,
          secondary_tooltip: "Mod+Shift+S",
        },
      },
    },
  });

  useEffect(() => {
    if (editor) {
      setTimeout(() => {
        const blocks = editor.document;
        const editorElement = document.querySelector(".bn-editor");
        if (editorElement && blocks.length === 1 && blocks[0].content.length === 0) {
          editorElement.focus(); // 에디터에 포커스 설정
        }
      }, 100); // 100ms 지연 후 실행
    }
  }, [editor]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  return (
    <div className={styles.note} onKeyDown={handleKeyDown}>
      <BlockNoteView
        className={clsx({ [styles["note-input"]]: isEditable, [styles["note-view"]]: !isEditable })}
        editor={editor}
        editable={isEditable}
        // NOTE 드래그 끝났을때 tooltip 뜨는거 방지
        onDragStart={(e) => {
          e.stopPropagation();
        }}
        // NOTE React dnd 동작 방지
        onDrop={(e) => {
          e.stopPropagation();
        }}
        onChange={() => {
          isChangeNote.current = true;
          setTaskDetail((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              note: JSON.stringify(editor.document),
            },
          }));
        }}
        onKeyDownCapture={(event) => {
          const isCmdOrCtrl = event.metaKey || event.ctrlKey;
          if (isCmdOrCtrl && event.key === "Enter") {
            event.preventDefault();
            onSave();
          }
        }}
        data-theming={expand ? "moba" : "moba-mini"}
        theme="dark"
        slashMenu={false}
        sideMenu={expand ? true : false}
      >
        <SuggestionMenuController
          triggerCharacter={"/"}
          suggestionMenuComponent={CustomSlashMenu}
        />
      </BlockNoteView>
    </div>
  );
}

function CustomSlashMenu(props) {
  const { items, onItemClick } = props;

  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        if (currentActiveIndex >= items.length - 1) {
          return setCurrentActiveIndex(0);
        }
        setCurrentActiveIndex(currentActiveIndex + 1);
        break;
      case "ArrowUp":
        if (currentActiveIndex <= 0) {
          return setCurrentActiveIndex(items.length - 1);
        }
        setCurrentActiveIndex(currentActiveIndex - 1);
        break;
      case "Enter":
        onItemClick(items[currentActiveIndex]);
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={`bn-suggestion-menu ${styles.slash_menu}`}>
      {items.map((item, index) => (
        <div
          key={item.key}
          className={`${styles.slash_menu_item} ${
            currentActiveIndex === index ? styles.slash_menu_item_selected : ""
          }`}
          onClick={() => {
            onItemClick(item);
          }}
          onMouseEnter={(e) => {
            setCurrentActiveIndex(index);
          }}
        >
          <div className={styles.slash_menu_icon}>{item.icon}</div>
          <div>{item.title}</div>
        </div>
      ))}
    </div>
  );
}
