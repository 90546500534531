import styles from "./DeleteButton.module.css";

import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";

export default function DeleteButton({ onDelete }) {
  return (
    <div className={styles.selectedGuestRowDelete} onClick={(event) => onDelete(event)}>
      <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
    </div>
  );
}
