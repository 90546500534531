import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import { isNoteDataChanged } from "../../utils/taskDetail/checkDataChanged/isNoteDataChanged";
import { areDatesEqual } from "../../utils/common/dateTime/areDatesEqual";
import typeForVisibility from "../../utils/taskDetail/visibility/typeForVisibility";
import formatDateTimeForJune from "../../utils/june/formatDateTimeForJune";

import useFetchMeetingCode from "../../queries/TaskDetail/useFetchMeetingCode";

import { accountState, meetWithAccountsState } from "../../recoil/account/accountState";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { toastState } from "../../recoil/toast/toastState";
import { popupState } from "../../recoil/popup/popupState";
import {
  calendarEventDuplicateItemState,
  saveEventState,
} from "../../recoil/calendar/calendarState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { doneTaskListState } from "../../recoil/taskList/doneTaskListState";
import { visibilityState } from "../../recoil/calendar/settingCalendar";
import { isFirstCreatedState } from "../../recoil/taskDetail/isFirstCreateState";
import { taskDetailHeightState } from "../../recoil/taskDetail/taskDetailHeightState";

import Header from "./Header/Header";
import TaskSetting from "./TaskSetting";
import RadioBtn from "../../compoenets/RadioBtn";

import { COMPLETED, INPROGRESS } from "../../constants/taskStateType";

import clsx from "clsx";

import styles from "./style.module.css";

function haveAttendeesChanged(originalAttendees, newAttendees) {
  if (newAttendees == null) {
    return false;
  }

  if (originalAttendees == null) {
    return true;
  }
  const originalEmails = new Set(originalAttendees.map((attendee) => attendee.email));
  const newEmails = new Set(newAttendees.map((attendee) => attendee.email));

  if (originalEmails.size !== newEmails.size) {
    return true;
  }

  for (const email of originalEmails) {
    if (!newEmails.has(email)) {
      return true;
    }
  }

  return false;
}

const AccModal = ({ creator, onClose }) => {
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data } = taskDetail;
  const modalRef = useRef(null);

  const handleRadioBtn = (selectedAcc) => {
    if (data.creator != selectedAcc) {
      setTaskDetail((prevState) => {
        return { ...prevState, creator: selectedAcc, projectId: null };
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        !e.target.classList.contains(styles["header-menu-acc"])
      ) {
        onClose();
      }
    }
    if (modalRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={modalRef} className={styles["AccModal-main"]}>
      {accountData.accountInfo.accounts.map((item) => (
        <div className={styles["AccModal-row"]}>
          <RadioBtn
            onClick={handleRadioBtn}
            checked={data.creator == item.email ? true : false}
            name="account"
            label={item.email}
            labelWidth="180px"
          />
          <div
            className={styles["AccModal-img"]}
            style={{
              backgroundImage: `url(${item.photoUrl})`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

const TaskDetail = forwardRef(({ style, initialData, taskDetailRef }, ref) => {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const { data, loadData } = taskDetail;
  const [defaultVisibility, setDefaultVisibility] = useRecoilState(visibilityState);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [savePopup, setSavePopup] = useRecoilState(popupState);
  const [isFirstCreated, setIsFirstCreated] = useRecoilState(isFirstCreatedState);
  const [saveEvent, setSaveEvent] = useRecoilState(saveEventState);

  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [doneTaskList, updateDoneTaskList] = useRecoilState(doneTaskListState);
  const [toast, setToast] = useRecoilState(toastState);
  const [taskDetailModalHeight, setTaskDetailModalHeight] = useRecoilState(taskDetailHeightState);
  const meetWithAccounts = useRecoilValue(meetWithAccountsState);

  const [selectedVisibilityType, setSelectedVisibilityType] = useState(
    data.visibility ? typeForVisibility(data.visibility, data.transparency) : defaultVisibility
  );
  const [toggleExpand, setToggleExpand] = useState(false);
  const [isAccModal, setIsAccModal] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [isSidebarModalOn, setIsSidebarModalOn] = useState(false);
  // const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [taskPopupInitialData, setTaskPopupInitialData] = useState(data);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [isTask, setIsTask] = useState(taskDetail.data.taskType === "Task" ?? null);
  const [isVisibilityClick, setIsVisibilityClick] = useState(false);
  const [taskDetailDataChanged, setTaskDetailDataChanged] = useState(false);
  const [isModalNoteClicked, setIsModalNoteClicked] = useState(false);

  const [duplicateItem, setDuplicateItem] = useRecoilState(calendarEventDuplicateItemState);

  const mainRef = useRef(null);

  const initialTaskType = useRef(null);
  const initialTransparency = useRef(null);
  const initialVisibility = useRef(null);
  const initialTitle = useRef(null);
  const initialProjectId = useRef("");
  const initialRecurrence = useRef(null);
  const initialGuest = useRef(null);
  const initialAllday = useRef(null);
  const initialStart = useRef(null);
  const initialEnd = useRef(null);
  const initialVideo = useRef(null);
  const initialLinks = useRef(null);
  const initialNoteData = useRef("");

  const currentIsCreateSelectEvent = useRef(null);
  const currentIsDataDuplicateEvent = useRef(null);
  const isChangeNote = useRef(false);

  const latestDataRef = useRef(data); // 가장 최신의 Data를 저장하는 변수
  const moreModalRef = useRef(null);
  const linkMoreModalRef = useRef(null);
  const videoCreateModalRef = useRef(null);
  const repeatModalRef = useRef(null);
  const visibilityDropdownRef = useRef(null);
  const guestDropdownRef = useRef(null);
  const repeatAddModalRef = useRef(null);

  const titleRef = useRef(null);
  const projectDropdownRef = useRef(null);

  const startDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const endDateRef = useRef(null);
  const endTimeRef = useRef(null);

  const [isDateStart, setIsDateStart] = useState(false);
  const [isDateEnd, setIsDateEnd] = useState(false);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const [isTitleFocused, setIsTitleFocused] = useState(true);
  const [isTimeStart, setIsTimeStart] = useState(false);

  const api = useApi();
  const trackCall = useJuneTrackCall();

  const isDisabled = currentIsCreateSelectEvent.current || currentIsDataDuplicateEvent.current;

  const { mutateMeetingCode, meetingCode, isPendingMeetingCode, error } = useFetchMeetingCode();

  useImperativeHandle(ref, () => ({
    saveChanges: onSave,
  }));

  useEffect(() => {
    if (taskDetailRef.current) {
      setTaskDetailModalHeight(taskDetailRef.current.offsetHeight);
    }
  }, [taskDetailRef.current]);

  useEffect(() => {
    latestDataRef.current = data;
  }, [data]);

  const handleClose = () => {
    const handleEventDeletion = () => {
      if (taskDetail.handleEventDelete) {
        taskDetail.handleEventDelete(taskDetail.data.id);
      }
    };

    if (currentIsCreateSelectEvent.current || currentIsDataDuplicateEvent.current) {
      handleEventDeletion();
    }

    setTaskDetail({ ...taskDetail, isVisible: false });
    setIsFirstCreated(false);

    setSavePopup({
      isVisible: false,
      message: "",
      okButtonTitle: "",
      closeButtonTitle: "",
      discardButtonTitle: "",
      data: null,
    });
  };

  const handleTaskDelete = () => {
    const handleEventDeletion = () => {
      if (taskDetail.handleEventDelete) {
        taskDetail.handleEventDelete(data.id);
      }
    };

    handleEventDeletion();

    updateInboxTaskList((current) => current.filter((task) => task.id !== data.id));

    handleClose();

    // NOTE TaskDetail 내 삭제 버튼 클릭 시 toast
    setToast({
      type: "Delete",
      isVisible: true,
      message: "Event has been deleted",
    });

    if (!currentIsCreateSelectEvent.current && !currentIsDataDuplicateEvent.current) {
      api
        .patch(`tasks/${data.id}/mark`, "", {
          headers: { "X-Requester": data.creator },
        })
        .then(() => {
          loadData(true, true, false);
          trackCall("delete_block", {
            location: taskDetail.type,
            type: taskDetail.data.attendees?.length > 0 ? "meeting" : "task",
          });
        })
        .catch(() => {
          loadData(true, true, true);
        });
    }
  };

  useEffect(() => {
    if (savePopup.clickType === "ok" || savePopup.clickType === "cancel") {
      handleSave(savePopup.data);
    } else if (savePopup.clickType === "discard") {
      if (savePopup.isDataDuplicateWithGuest) {
        if (duplicateItem instanceof HTMLElement) {
          duplicateItem.remove();
        }
      }
      handleClose();
    }
  }, [savePopup]);

  // NOTE TaskDetail 처음 창 열었을 때 동작하는 로직
  useEffect(() => {
    // NOTE 초기값 ref에 저장, 변경사항 체크할 때 사용
    initialTaskType.current = data.taskType;
    initialTransparency.current = data.transparency;
    initialVisibility.current = data.visibility;
    initialTitle.current = data.title;
    initialProjectId.current = data.projectId;
    initialAllday.current = data.allDay;
    initialEnd.current = data.end;
    initialStart.current = data.start;
    initialGuest.current = data.isDataDuplicateEvent ? [] : (data.attendees ?? []);
    initialVideo.current = data.hangoutLink ? data.hangoutLink : null;
    initialLinks.current = data.links;
    initialRecurrence.current = data.recurrence;

    currentIsCreateSelectEvent.current = data.isCreateSelectEvent;
    currentIsDataDuplicateEvent.current = data.isDataDuplicateEvent;

    let trackObject = { location: taskDetail.type };
    if (data.attendees != null && data.attendees.length) {
      trackObject = { ...trackObject, type: "meeting" };
    } else {
      trackObject = { ...trackObject, type: "task" };
    }
    trackCall("view_block_detail", trackObject);

    api.get("notes/" + data.id + "/" + data.creator).then((res) => {
      initialNoteData.current = data.note ? data.note : res.data.note || "";
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          note: data.note ? data.note : res.data.note || "",
        },
      }));
    });
  }, []);

  // NOTE TaskDetail 바깥 클릭 시 저장 또는 닫기
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (
        mainRef.current &&
        !mainRef.current.contains(event.target) &&
        // linkModal의 more context menu 클릭 시 창 닫히지 않도록 설정
        (!linkMoreModalRef.current || !linkMoreModalRef.current.contains(event.target)) &&
        !videoCreateModalRef.current &&
        // repeatModal 닫히지 않도록 설정
        !repeatModalRef.current &&
        !projectDropdownRef.current
      ) {
        return onSave();
      }
    }
    if (mainRef.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      // if (currentIsCreateSelectEvent.current) {
      //   if (taskDetail.handleEventDelete) {
      //     taskDetail.handleEventDelete(data.id);
      //   }
      // }
      // NOTE 여기서 초기화 시 checkChanges 함수 동작 안하는 이슈 발생으로 주석 처리
      // setTaskDetail((prev) => ({
      //   ...prev,
      //   data: {
      //     ...prev.data,
      //     createdAt: null,
      //     creator: null,
      //     id: null,
      //     itemStatus: null,
      //     title: null,
      //     projectId: null,
      //     links: null,
      //     files: null,
      //     note: null,
      //     start: null,
      //     end: null,
      //     repeat: null,
      //     attendees: null,
      //     note: null,
      //     // taskType: null,
      //   },
      // }));
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [taskDetail.data, isTask, isPendingMeetingCode]);

  const checkChanges = useCallback(
    (data, task) => {
      const taskTypeChanged = task !== (initialTaskType.current === "Task");
      const visibilityChanged = initialVisibility.current !== data.visibility;
      const transparencyChanged = initialTransparency.current !== data.transparency;
      const totalVisibilityChanged = visibilityChanged || transparencyChanged;

      const titleChanged = initialTitle.current !== data.title;
      const projectIdChanged = initialProjectId.current !== data.projectId;

      let startChanged = false;
      let endChanged = false;
      if (!initialStart.current && !data.start) {
        startChanged = false;
        endChanged = false;
      } else {
        if (!data.start) {
          startChanged = true;
        } else {
          if (initialAllday.current !== data.allDay) {
            startChanged = true;
          } else {
            if (data.allDay) {
              startChanged =
                new Date(initialStart.current).getTime() !== new Date(data.start.date).getTime();
            } else {
              startChanged =
                new Date(initialStart.current).getTime() !==
                new Date(data.start.dateTime).getTime();
            }
          }
        }

        if (!data.end) {
          endChanged = true;
        } else {
          if (initialAllday.current !== data.allDay) {
            endChanged = true;
          } else {
            if (data.allDay) {
              endChanged =
                new Date(initialEnd.current).getTime() !== new Date(data.end.date).getTime();
            } else {
              endChanged =
                new Date(initialEnd.current).getTime() !== new Date(data.end.dateTime).getTime();
            }
          }
        }
      }

      let linksChanged = false;
      if (initialLinks.current) {
        linksChanged =
          initialLinks.current.length !== data.links.length ||
          initialLinks.current.some((link, idx) => link.url !== data.links[idx].url);
      } else if (data.links && data.links.length > 0) {
        linksChanged = true;
      }
      const repeatChanged =
        JSON.stringify(initialRecurrence.current) !== JSON.stringify(data.recurrence);
      const guestChanged = haveAttendeesChanged(initialGuest.current, data.attendees);
      const videoChanged = initialVideo.current !== data.hangoutLink;

      if (isPendingMeetingCode) {
        return false;
      } else {
        return (
          (!data.isCreateSelectEvent && taskTypeChanged) ||
          totalVisibilityChanged ||
          titleChanged ||
          projectIdChanged ||
          startChanged ||
          endChanged ||
          repeatChanged ||
          guestChanged ||
          videoChanged ||
          linksChanged
        );
      }
    },
    [isTask, isPendingMeetingCode, taskDetail.data]
  );

  let hasChanged;
  useEffect(() => {
    if (taskDetail.data) {
      hasChanged = checkChanges(taskDetail.data, isTask);
      // NOTE save 버튼 활성화용 상태
      setTaskDetailDataChanged(hasChanged || isNoteDataChanged(initialNoteData.current, data.note));
    }
  }, [taskDetail.data, isTask, checkChanges, isPendingMeetingCode]);

  function onSave() {
    const prepareUpdateTask = () => {
      const {
        id,
        creator,
        title,
        start,
        end,
        itemStatus,
        links,
        projectId,
        recurrence,
        attendees,
        note,
        visibility,
        transparency,
        hangoutLink,
        meetingCode,
        taskType,
        startTimeZone,
      } = data;
      const updateTask = {
        id,
        creator,
        title: title || "No title",
        start,
        end,
        itemStatus,
        links,
        projectId,
        recurrence,
        attendees: attendees || [],
        note,
        visibility,
        transparency,
        taskType: isTask ? "Task" : "Event",
      };

      if (meetingCode) {
        updateTask.meetingCode = meetingCode || "";
      }

      // // NOTE duplicate 시 start.dateTime이 없는 경우 따로 생성
      // if (!updateTask.start.dateTime) {
      //   updateTask.start = { dateTime: updateTask.start, timeZone: startTimeZone };
      //   updateTask.end = { dateTime: updateTask.end, timeZone: startTimeZone };
      // }

      // Null 값 제거
      Object.keys(updateTask).forEach((key) => updateTask[key] === null && delete updateTask[key]);

      return updateTask;
    };

    const checkAndSave = (updateTask) => {
      // guest 있는데 날짜 없을 경우 체크
      if (updateTask.start && updateTask.end) {
        const initialStartDate = new Date(initialStart.current);
        const initialEndDate = new Date(initialEnd.current);
        const dataStartDate = new Date(
          latestDataRef.current.start.dateTime || latestDataRef.current.start.date
        );
        const dataEndDate = new Date(
          latestDataRef.current.end.dateTime || latestDataRef.current.end.date
        );

        const isStartEqual = areDatesEqual(initialStartDate, dataStartDate);
        const isEndEqual = areDatesEqual(initialEndDate, dataEndDate);

        // 만약 guest 가 바뀌었고(없었는데 생기거나 있었는데 게스트 변경 시), 반복일정이나,제목, 날짜가 변경되었을 때 팝업 띄우기
        if (
          meetWithAccounts.length > 0 ||
          initialRecurrence.current !== latestDataRef.current.recurrence ||
          haveAttendeesChanged(initialGuest.current, latestDataRef.current.attendees) ||
          initialTitle.current !== latestDataRef.current.title ||
          !isStartEqual ||
          !isEndEqual
        ) {
          setSavePopup({
            isVisible: true,
            message: "Want to send invitations to\nthe other invitees?",
            okButtonTitle: "Send",
            closeButtonTitle: "Don't send",
            discardButtonTitle: "Discard Changes",
            data: updateTask,
            isDataDuplicateWithGuest: data.isDataDuplicateEvent,
            clickType: "",
            okCallback: () => handleSave(updateTask),
          });
        } else {
          handleSave(updateTask);
        }
        setIsFirstCreated(false);
      } else {
        setToast({ isVisible: true, message: "Please block the date." });
        setGuestError(true);
      }
    };

    // 이 updateTask가 최종적으로 바뀐 데이터, prepareUpdateTask로 null 값 제거
    const updateTask = prepareUpdateTask();

    if (
      checkChanges(taskDetail.data, isTask) ||
      isNoteDataChanged(initialNoteData.current, data.note)
    ) {
      // NOTE 시간 설정 시 Inbox에서 사라지지 않는 이슈 해결
      if (!taskPopupInitialData.start && updateTask.start && updateTask.end) {
        updateInboxTaskList((current) => current.filter((task) => task.id !== updateTask.id));
      }
      if (updateTask.attendees.length !== 0) {
        checkAndSave(updateTask);
      } else {
        handleSave(updateTask);
      }
    } else {
      handleClose();
    }
  }

  const handleSave = (updateTask) => {
    // null 값 제거한 updateTask 받아서
    if (updateTask.start && updateTask.end) {
      if (currentIsCreateSelectEvent.current) {
        setToast({
          type: "Success",
          isVisible: true,
          message: "Event created successfully",
        });
      } else {
        setToast({
          type: "Success",
          isVisible: true,
          message: "Event updated successfully",
        });
      }
    }
    currentIsCreateSelectEvent.current = false;
    currentIsDataDuplicateEvent.current = false;
    setIsFirstCreated(false);

    // June 설정 로직
    const trackTask = (taskType, additionalData = {}) => {
      let trackObject = { location: taskDetail.type, type: taskType };

      if (updateTask.attendees.length > 0) {
        trackObject = { ...trackObject, type: "meeting" };
      } else {
        trackObject = { ...trackObject, type: "task" };
      }

      trackObject = {
        ...trackObject,
        type: updateTask.taskType === "Task" ? "task" : "event",
        knowledge: !!updateTask.links?.length,
        note: isChangeNote.current,
        project: !!updateTask.projectId?.length,
        visibility: updateTask.visibility
          ? typeForVisibility(updateTask.visibility, updateTask.transparency)
          : undefined,
        repeat: !!updateTask.recurrence?.length,
        title: initialTitle.current !== updateTask.title,
        ...additionalData,
      };
      trackCall(taskType, trackObject);
    };

    // June 설정 로직 - 시간 변경 시
    const trackTimeChange = (beforeStart, beforeEnd, afterStart, afterEnd) => {
      if (beforeStart !== afterStart || beforeEnd !== afterEnd) {
        if (initialStart.current === undefined) {
          trackCall("block_time", {
            location: "inbox",
            start_datetime: afterStart,
            end_datetime: afterEnd,
          });
        } else {
          const juneTimeChageLog = {
            previous_start_datetime: beforeStart,
            previous_end_datetime: beforeEnd,
            new_start_datetime: afterStart,
            new_end_datetime: afterEnd,
          };

          if (beforeStart === afterStart || beforeEnd === afterEnd) {
            trackCall("resize_block", juneTimeChageLog);
          } else {
            trackCall("move_block", {
              ...juneTimeChageLog,
              allDay: false,
            });
          }
        }
      }
    };

    const finalizeTasks = (res) => {
      // NOTE 서버에서 전달받은 데이터로 클라이언트 상태 다시 업데이트
      let calendarUpdateData = {
        ...latestDataRef.current,
        title: res.data.task.title,
        hangoutLink: res.data.task.hangoutLink ? res.data.task.hangoutLink : null,
        // meetingCode: res.data.task.attendees ? res.data.task.hangoutLink : null,
        taskType: isTask ? "Task" : "Event",
        isCreateSelectEvent: false,
        isDataDuplicateEvent: false,
      };

      if (taskDetail.handleEventChange) {
        if (updateTask.start) {
          taskDetail.handleEventChange(calendarUpdateData);
        }
      } else {
        if (updateTask.start || updateTask.end) {
          setSaveEvent(calendarUpdateData);
        }
      }

      setSavePopup({
        isVisible: false,
        message: "",
        okButtonTitle: "",
        closeButtonTitle: "",
        discardButtonTitle: "",
        data: null,
      });
    };

    // 클라이언트 상태 업데이트 하는 부분
    const updateTaskData = () => {
      // 1. event delete, eventChange 가 모두 있을 때
      if (taskDetail.handleEventDelete && taskDetail.handleEventChange) {
        if (!updateTask.start && !updateTask.end) {
          // 1-1 인박스 요소면 해당 요소 삭제, 만약 Completed 요소면 doneTaskList 전역상태 업데이트, 아니면 inboxTaskList 전역상태 업데이트
          taskDetail.handleEventDelete(latestDataRef.current.id);
          latestDataRef.current.itemStatus === COMPLETED
            ? updateDoneTaskList((current) => [updateTask, ...current])
            : updateInboxTaskList((current) => [updateTask, ...current]);
        } else {
          // 1-2 캘린더 요소면 해당 요소 업데이트
          taskDetail.handleEventChange(latestDataRef.current);
        }
      } else {
        // 2. event delete, eventChange 가 모두 있지 않을 때(하나만 있거나 둘 다 없을 때)
        if (!updateTask.start && !updateTask.end) {
          // 2-1-1 인박스 요소고 해당 요소의 상태가 InProgress일 때 inboxTaskList 전역상태 업데이트
          if (latestDataRef.current.itemStatus === INPROGRESS) {
            updateInboxTaskList((current) =>
              current.map((task) =>
                task.id === latestDataRef.current.id ? { ...task, ...latestDataRef.current } : task
              )
            );
          } else {
            // 2-1-2 인박스 요소고 해당 요소의 상태가 Done일 때 doneTaskList 전역상태 업데이트
            updateDoneTaskList((current) =>
              current.map((task) =>
                task.id === latestDataRef.current.id ? { ...task, ...latestDataRef.current } : task
              )
            );
          }
        } else {
          // 2-2 캘린더 요소일 때 saveEvent 전역 상태 업데이트
          setSaveEvent(latestDataRef.current);
        }
      }
    };

    const handleApiCall = (url, method, task) => {
      api[method](url, task, {
        headers: { "X-Requester": latestDataRef.current.creator },
      })
        .then((res) => finalizeTasks(res))
        .catch((error) => {
          console.error(error);
          loadData(true, true, true);
        });
    };

    // NOTE 서버로 전송하는 필드
    const prepareUpdateTask = () => {
      let task = {
        ...data,
        title: data.title ? data.title : "No title",
        taskType: isTask ? "Task" : "Event",
      };

      if (task.start && task.end) {
        if (!task.start.dateTime && !task.end.date) {
          task.start = { dateTime: task.start, timeZone: task.startTimeZone };
          task.end = { dateTime: task.end, timeZone: task.endTimeZone };
        }
      }

      if (data.meetingCode) {
        // 값이 없으면 필드 제외
        task.meetingCode = data.meetingCode;
      }

      Object.keys(task).forEach((key) => {
        if (task[key] === null) delete task[key];
      });

      if (task.start) {
        task.id = latestDataRef.current.id;
      }
      return task;
    };

    if (latestDataRef.current.isCreateSelectEvent) {
      if (updateTask.start && updateTask.end) {
        if (updateTask.start.dateTime && updateTask.end.dateTime) {
          // NOTE all-day 아닐 떄
          trackTask("create_block", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(updateTask.start.dateTime),
            end_datetime: formatDateTimeForJune(updateTask.end.dateTime),
          });
          trackCall("block_time", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(updateTask.start.dateTime),
            end_datetime: formatDateTimeForJune(updateTask.end.dateTime),
            allDay: false,
          });
        } else {
          // NOTE all-day 일 때
          trackTask("create_block", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(
              new Date(updateTask.start.date).setHours(0, 0, 0, 0)
            ),
            end_datetime: formatDateTimeForJune(new Date(updateTask.end.date).setHours(0, 0, 0, 0)),
          });
          trackCall("block_time", {
            location: "calendar",
            start_datetime: formatDateTimeForJune(
              new Date(updateTask.start.date).setHours(0, 0, 0, 0)
            ),
            end_datetime: formatDateTimeForJune(new Date(updateTask.end.date).setHours(0, 0, 0, 0)),
            allDay: true,
          });
        }
      }
    } else if (latestDataRef.current.isDataDuplicateEvent) {
      trackTask("duplicate_block");
    } else {
      trackTask("update_block");

      const beforeStart = formatDateTimeForJune(initialStart.current);
      const beforeEnd = formatDateTimeForJune(initialEnd.current);
      const afterStart = formatDateTimeForJune(updateTask.start?.dateTime || "");
      const afterEnd = formatDateTimeForJune(updateTask.end?.dateTime || "");

      trackTimeChange(beforeStart, beforeEnd, afterStart, afterEnd);
    }

    // 여기서부터 handleSave 실제 동작 부분 시작

    // NOTE 클라이언트 상태 업데이트
    updateTaskData();

    // NOTE 서버 상태 업데이트
    // prepareUpdateTask로 null 값 제거한 updateTask 받아서
    const task = prepareUpdateTask();
    // 클릭앤 드래그로 생성했거나 복제됐을 때에는 post로, 그 외에는 patch로
    const url =
      latestDataRef.current.isCreateSelectEvent || latestDataRef.current.isDataDuplicateEvent
        ? "tasks?notification=" + (savePopup.clickType === "ok")
        : `tasks/${latestDataRef.current.id}?creator=${
            latestDataRef.current.creator
          }&notification=${savePopup.clickType === "ok"}`;
    const method =
      latestDataRef.current.isCreateSelectEvent || latestDataRef.current.isDataDuplicateEvent
        ? "post"
        : "patch";

    handleApiCall(url, method, task);
    handleClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const blockNoteEl = document.querySelector(".bn-suggestion-menu");
      if (blockNoteEl) return;

      const bnDragHandleMenu = document.querySelector(".bn-drag-handle-menu");
      if (bnDragHandleMenu && event.key === "Escape") {
        return;
      }

      const bnToolbar = document.querySelector(".bn-toolbar");
      if (bnToolbar && event.key === "Escape") {
        return;
      }

      const isCmdOrCtrl = event.metaKey || event.ctrlKey;

      if (moreModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsSidebarModalOn(false);
      }
      if (startTimeRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsTimeStart(false);
      }
      if (startDateRef.current && event.key === "Escape") {
        event.stopPropagation();
        return setIsDateStart(false);
      }
      if (endTimeRef.current && event.key === "Escape") {
        return setIsTimeEnd(false);
      }
      if (endDateRef.current && event.key === "Escape") {
        return setIsDateEnd(false);
      }
      if (visibilityDropdownRef.current && event.key === "Escape") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        return setIsVisibilityClick(false);
      }
      if (repeatAddModalRef.current && event.key === "Enter") {
        event.stopPropagation();
        return;
      }
      if (repeatAddModalRef.current && event.key === "Escape") {
        event.stopPropagation();
        return;
      }
      if (projectDropdownRef.current && event.key === "Enter") {
        event.stopPropagation();
      }

      if (
        (!startTimeRef.current &&
          !videoCreateModalRef.current &&
          !projectDropdownRef.current &&
          !moreModalRef.current &&
          (!repeatModalRef.current || !repeatAddModalRef.current) &&
          !guestDropdownRef.current &&
          event.key === "Escape") ||
        event.key === "Enter" ||
        (event.key === isCmdOrCtrl && event.key === "Enter")
      ) {
        event.stopPropagation();
        return onSave();
      }

      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        (event.key === "Backspace" && event.code === "Delete")
      ) {
        console.log("event: ", event.code);
        !toggleExpand && event.target.tagName !== "INPUT" && handleTaskDelete();
        !toggleExpand && event.target.tagName !== "INPUT" && handleTaskDelete();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    startTimeRef,
    titleRef,
    setIsTitleFocused,
    data,
    repeatAddModalRef,
    toggleExpand,
    isPendingMeetingCode,
  ]);

  const handleVisibilityClick = () => {
    setIsVisibilityClick((current) => !current);
  };

  // NOTE 처음 taskDetail 창을 열었을 때에만 defaultVisibility 값으로 보이도록 설정
  useEffect(() => {
    isFirstCreated &&
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          visibility: defaultVisibility === "public" ? "public" : "private",
          transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
        },
      }));
  }, [defaultVisibility]);

  useEffect(() => {
    isFirstCreated &&
      setSelectedVisibilityType(
        typeForVisibility(taskDetail.data.visibility, taskDetail.data.transparency)
      );
  }, [taskDetail.data.visibility, taskDetail.data.transparency]);

  const handleVisibilityDropdownItemClick = (type) => {
    setIsVisibilityClick(false);
    setSelectedVisibilityType(type);

    const updatedVisibility = type === "public" ? "public" : "private";
    const updatedTransparency = type === "invisible" ? "transparent" : "opaque";

    // 상태를 직접 업데이트
    setTaskDetail((prevDetail) => {
      const updatedDetail = {
        ...prevDetail,
        data: {
          ...prevDetail.data,
          visibility: updatedVisibility,
          transparency: updatedTransparency,
        },
      };
      return updatedDetail;
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (visibilityDropdownRef.current && !visibilityDropdownRef.current.contains(event.target)) {
        setIsVisibilityClick(false);
      }

      if (moreModalRef.current && !moreModalRef.current.contains(event.target)) {
        setIsSidebarModalOn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMoreButtonClick = (event) => {
    setIsSidebarModalOn((prev) => !prev);
  };

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.bodyMini}`);
    const bodyElement = document.querySelector(`.${styles.body}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }

    if (bodyElement) {
      bodyElement.addEventListener("scroll", handleScroll);
      return () => {
        bodyElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  // useEffect(() => {}, [isTask, taskDetailRef.current]);

  useEffect(() => {
    if (isTask) {
      // event에서 task로 바뀌었다면 attendees 초기화 및 video 초기화

      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          attendees: [], // attendees 초기화
          hangoutLink: null, // hangoutLink 초기화
          taskType: "Task",
          meetingCode: "",
        },
      }));
    } else {
      setTaskDetail((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          taskType: "Event",
        },
      }));
    }
    if (data.isCreateSelectEvent) {
      setIsTitleFocused(true);
    }
  }, [isTask]);

  return (
    <>
      <div
        className={clsx(styles.detail__wrap, {
          [styles["detail__wrap-mini"]]: !toggleExpand,
          [styles["detail__wrap-expand"]]: toggleExpand,
        })}
        style={!toggleExpand ? style : {}}
        ref={taskDetailRef}
      >
        <div
          className={clsx(styles.main, {
            [styles["main-mini"]]: !toggleExpand,
            [styles["main-expand"]]: toggleExpand,
          })}
          ref={mainRef}
        >
          <Header
            // Refs
            latestDataRef={latestDataRef}
            moreModalRef={moreModalRef}
            visibilityDropdownRef={visibilityDropdownRef}
            // States
            isTask={isTask}
            setIsTask={setIsTask}
            toggleExpand={toggleExpand}
            setToggleExpand={setToggleExpand}
            isDisabled={isDisabled}
            isVisibilityClick={isVisibilityClick}
            isSidebarModalOn={isSidebarModalOn}
            selectedVisibilityType={selectedVisibilityType}
            isModalNoteClicked={isModalNoteClicked}
            setIsModalNoteClicked={setIsModalNoteClicked}
            // Functions
            handleVisibilityClick={handleVisibilityClick}
            handleVisibilityDropdownItemClick={handleVisibilityDropdownItemClick}
            handleTaskDelete={handleTaskDelete}
            handleMoreButtonClick={handleMoreButtonClick}
          />
          <div
            className={`${toggleExpand ? styles.body : styles.bodyMini} ${isScrolling && styles.showScrollbar}`}
          >
            <TaskSetting
              expand={toggleExpand}
              loadData={loadData}
              onSave={onSave}
              onClose={handleClose}
              guestError={guestError}
              setGuestError={setGuestError}
              setVisibilityType={handleVisibilityDropdownItemClick}
              isChangeNote={isChangeNote}
              isDisabled={isDisabled}
              linkMoreModalRef={linkMoreModalRef}
              videoCreateModalRef={videoCreateModalRef}
              isTask={isTask}
              setIsTask={setIsTask}
              repeatModalRef={repeatModalRef}
              startTimeRef={startTimeRef}
              endTimeRef={endTimeRef}
              startDateRef={startDateRef}
              endDateRef={endDateRef}
              isTimeStart={isTimeStart}
              setIsTimeStart={setIsTimeStart}
              isTimeEnd={isTimeEnd}
              setIsTimeEnd={setIsTimeEnd}
              isDateStart={isDateStart}
              setIsDateStart={setIsDateStart}
              isDateEnd={isDateEnd}
              setIsDateEnd={setIsDateEnd}
              titleRef={titleRef}
              isTitleFocused={isTitleFocused}
              setIsTitleFocused={setIsTitleFocused}
              projectDropdownRef={projectDropdownRef}
              guestDropdownRef={guestDropdownRef}
              repeatAddModalRef={repeatAddModalRef}
              mutateMeetingCode={mutateMeetingCode}
              isPendingMeetingCode={isPendingMeetingCode}
              meetingCode={meetingCode}
              setIsModalNoteClicked={setIsModalNoteClicked}
              isModalNoteClicked={isModalNoteClicked}
            />
          </div>
          {!isModalNoteClicked && (
            <div className={styles.footer}>
              <button
                className={clsx(styles["setting-save--disabled"], {
                  [styles["setting-save"]]: taskDetailDataChanged,
                })}
                onClick={onSave}
              >
                <p>Save</p>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* {popupType && <RadioPopup {...popupProps} />} */}
    </>
  );
});

export default TaskDetail;
