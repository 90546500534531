// 로컬 스토리지에 데이터 저장
export function saveToLocalStorage(key, value) {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (err) {
    console.error("로컬 스토리지에 저장하는데 실패했습니다.", err);
  }
}

// 로컬 스토리지에서 데이터 불러오기
export function loadFromLocalStorage(key) {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined; // 값이 없으면 undefined 반환
    }
    return JSON.parse(serializedValue);
  } catch (err) {
    console.error("로컬 스토리지에서 불러오는데 실패했습니다.", err);
    return undefined;
  }
}
