import { useRecoilState } from "recoil";
import { UseMutateFunction, useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import useApi from "../../services/auth/useApi";
import { fetchMeetingCode } from "../../services/taskDetail/video/fetchMeetingCode";
import { taskPopupState } from "../../recoil/taskDetail/taskPopupState";
import { tokenState } from "../../recoil/auth/tokenState";

interface UseFetchMeetingCodeResult {
  mutateMeetingCode: UseMutateFunction<string, Error, string, unknown>;
  meetingCode: string | null;
  isPendingMeetingCode: boolean;
  error: Error | null;
}

const useFetchMeetingCode = (): UseFetchMeetingCodeResult => {
  const api = useApi();
  const navigate = useNavigate();
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [token, setToken] = useRecoilState(tokenState);

  const mutation: UseMutationResult<string, Error, string, unknown> = useMutation<
    string,
    Error,
    string
  >({
    mutationFn: async (creator: string): Promise<string> => {
      return await fetchMeetingCode(api, creator);
    },
    onSuccess: (meetingCode: string) => {
      setTaskDetail((prevState: any) => {
        return {
          ...prevState,
          data: {
            ...(prevState.data as any),
            meetingCode: meetingCode,
            hangoutLink: `https://meet.google.com/${meetingCode}`,
          },
        };
      });
    },
    onError: (error: any) => {
      console.log("error : ", error);
      if (error.response && error.response.status === 400) {
        setToken({
          mAccessToken: null,
          mRefreshToken: null,
          accessToken: null,
        });
        navigate("/");
      }
      throw error;
    },
  });

  return {
    mutateMeetingCode: mutation.mutate,
    meetingCode: mutation.data || null,
    isPendingMeetingCode: mutation.isPending,
    error: mutation.error,
  };
};

export default useFetchMeetingCode;
