export const PROJECT_MODAL_LIST_COLOR = [
  "#DE77E6",
  "#A670EA",
  "#667EFF",
  "#60B2FF",
  "#209FC7",
  "#6BA521",
  "#81CE5D",
  "#68CF91",
  "#FC7DB2",
  "#C34799",
  "#FA8686",
  "#E7694D",
  "#ED815F",
  "#FFC266",
  "#C1A336",
  "#E6DA69",
];
