import { AxiosInstance } from "axios";

export const fetchMeetingCode = async (api: AxiosInstance, creator: string): Promise<string> => {
  const response = await api.post(
    "/tasks/googleMeet",
    {},
    {
      headers: { "X-Requester": creator },
    }
  );
  return response.data.meetingCode;
};
