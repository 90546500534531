import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";

import useApi from "../../../services/auth/useApi";
import findAttendByEmail from "../../../utils/taskDetail/findAttendByEmail";
import emailValidation from "../../../utils/taskDetail/emailValidation";

import { toastState } from "../../../recoil/toast/toastState";
import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { accountState } from "../../../recoil/account/accountState";
import { taskDetailHeightState } from "../../../recoil/taskDetail/taskDetailHeightState";

import PropertyTitle from "../Common/PropertyTitle";
import { ReactComponent as GuestIcon } from "../../../assets/TaskDetail/guests.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/TaskDetail/delete-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/Common/add-icon.svg";

import clsx from "clsx";

import styles from "./GuestModal.module.css";

const ResponseStatus = {
  ACCEPTED: "accepted",
  DECLINED: "declined",
  TENTATIVE: "tentative",
};
const SelectedGuestRow = ({ data, email, onDelete, isSelfTask, responseStatus, organizer }) => {
  const { name, imgUrl } = findAttendByEmail(data, email);

  const iconStyle = {
    backgroundSize: "cover",
  };

  if (imgUrl) {
    iconStyle.backgroundImage = `url(${imgUrl})`;
  } else {
    iconStyle.backgroundColor = "white";
    iconStyle.borderRadius = "50%";
  }

  return (
    <div className={styles["selected-guest-row"]}>
      <div className={styles.selectedGuestRowIconWrapper}>
        <div className={styles["selected-guest-row-icon"]} style={iconStyle}></div>
        {(responseStatus === ResponseStatus.ACCEPTED || organizer) && (
          <span className={styles.acceptedIcon} />
        )}
        {responseStatus === ResponseStatus.DECLINED && <span className={styles.declinedIcon} />}
        {responseStatus === ResponseStatus.TENTATIVE && <span className={styles.tentativeIcon} />}
      </div>

      {name === email || !name ? (
        <span className={styles["selected-guest-row-name"]}>{email}</span>
      ) : (
        <>
          <span className={styles["selected-guest-row-name"]}>{name}</span>
          <span className={styles["selected-guest-row-email"]}>{"(" + email + ")"}</span>
        </>
      )}

      {organizer && <div className={styles.organizerBadge}>Organizer</div>}
      {isSelfTask ? (
        <div className={styles.selectedGuestRowDelete} onClick={() => onDelete(email)}>
          <DeleteIcon className={styles.selectedGuestRowDeleteIcon} />
        </div>
      ) : null}
    </div>
  );
};

const GuestRow = ({ data, email, onClick, isActive, isExistingGuest }) => {
  const { name, imgUrl } = findAttendByEmail(data, email);
  const guestRef = useRef(null);

  useEffect(() => {
    if (!guestRef.current) return;

    isActive && guestRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [isActive, guestRef]);

  return (
    <li
      className={`${styles["guest-row"]} ${isActive && styles.guest_row_active}`}
      onMouseDown={(event) => event.preventDefault()}
      onClick={(event) => event.stopPropagation()}
      ref={guestRef}
    >
      <input type="radio" id={email} name="guestOption" />
      <label
        className={styles["guest-row__option"]}
        htmlFor={email}
        onClick={(e) => {
          e.stopPropagation();
          onClick(email);
        }}
      >
        <div className={clsx({ [styles["guest-row__icon-wrapper"]]: isExistingGuest })}>
          <div
            className={styles["guest-row__icon"]}
            style={{
              backgroundImage: `url(${imgUrl})`,
              backgroundSize: "cover",
            }}
          ></div>
          {isExistingGuest && <div className={styles["guest-row__icon-checkmark"]}></div>}
        </div>
        {name ? (
          <>
            <span
              className={clsx(styles["guest-row__name"], {
                [styles["guest-row__name--existing"]]: isExistingGuest,
              })}
            >
              {name}
            </span>
            <span
              className={clsx(styles["guest-row__email"], {
                [styles["guest-row__name--existing"]]: isExistingGuest,
              })}
            >{` (${email})`}</span>
          </>
        ) : (
          <span
            className={clsx(styles["guest-row__name"], {
              [styles["guest-row__name--existing"]]: isExistingGuest,
            })}
          >
            {email}
          </span>
        )}
      </label>
    </li>
  );
};

function isEmailInAccounts(emailToCheck, data) {
  return data.accountInfo.accounts.some((account) => account.email === emailToCheck);
}

export default function GuestModal({
  onClose,
  setIsBuffMode,
  expand,
  guestDropdownRef,
  mutateMeetingCode,
}) {
  // Recoil 상태
  const [toast, setToast] = useRecoilState(toastState);
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const [accountData, setAccountData] = useRecoilState(accountState);
  const [taskDetailModalHeight, setTaskDetailModalHeight] = useRecoilState(taskDetailHeightState);

  // useState를 사용한 상태
  const [contactData, setContactData] = useState([]);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [isShowGuestsInput, setIsShowGuestsInput] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [organizer, setOrganizer] = useState("");
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const initialGuest = useRef(null);

  // const wrapRef = useRef(null);
  const inputRef = useRef(null);

  const { data } = taskDetail;
  const api = useApi();
  const isSelfTask = isEmailInAccounts(data.creator, accountData);

  useEffect(() => {
    getContact();
  }, []);

  useEffect(() => {
    if (isShowGuestsInput) {
      inputRef.current.focus();
    }
  }, [isShowGuestsInput]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (guestDropdownRef.current && !guestDropdownRef.current.contains(event.target)) {
        setFilteredGuests([]);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [guestDropdownRef]);

  useEffect(() => {
    setCurrentActiveIndex(0);
  }, [filteredGuests]);

  // TaskDetailModal의 높이를 동적으로 조절하기 위한 useEffect
  useEffect(() => {
    setTaskDetailModalHeight((prev) => prev + 30);
  }, [isShowGuestsInput]);

  useEffect(() => {
    initialGuest.current = data.attendees;
  }, []);

  useEffect(() => {
    if (
      taskDetail.data.attendees &&
      initialGuest.current &&
      initialGuest.current.length === 0 &&
      taskDetail.data.attendees.length > 0
    ) {
      mutateMeetingCode(data.creator);
    }
  }, [taskDetail.data.attendees]);

  const getContact = async () => {
    const { data: newContact } = await api.get("accounts/contacts");
    const contactList = newContact.contacts[data.creator] || [];
    setOrganizer(newContact.organizer);
    setContactData(contactList);
    setFilteredGuests(contactList);
  };

  const showErrorToast = () => {
    setToast({ isVisible: true, message: "The email address is incorrect." });
  };

  const handleGuestInputChange = (e) => {
    setIsInvalidEmail(false);

    if (e.target.value === "") {
      setFilteredGuests([]);
      return;
    }
    if (contactData.length === 0) return;
    setFilteredGuests(contactData.filter((guest) => guest.email.includes(e.target.value)));
  };

  const handleGuestInputBlur = () => {
    inputRef.current.value = "";
    setIsInvalidEmail(false);
    setIsShowGuestsInput(false);
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();
    switch (e.key) {
      case "Enter":
        e.stopPropagation();
        if (filteredGuests[currentActiveIndex]) {
          return handleAddGuest(filteredGuests[currentActiveIndex].email);
        }
        handleAddGuest(e.target.value);
        break;
      case "Escape":
        inputRef.current.value = "";
        setIsShowGuestsInput(false);

        onClose();
        break;
      case "ArrowDown":
        setCurrentActiveIndex(0);
        if (currentActiveIndex >= filteredGuests.length - 1) {
          return setCurrentActiveIndex(0);
        }
        setCurrentActiveIndex(currentActiveIndex + 1);
        break;
      case "ArrowUp":
        if (currentActiveIndex <= 0) {
          return setCurrentActiveIndex(filteredGuests.length - 1);
        }
        setCurrentActiveIndex(currentActiveIndex - 1);
        break;

      default:
        break;
    }
  };

  const handleAddGuest = (email) => {
    const isEmailValidation = emailValidation(email);
    if (!isEmailValidation) {
      setIsInvalidEmail(true);
      showErrorToast();
      return;
    }

    setTaskDetail((prevState) => {
      const currentAttendees = [...(prevState.data.attendees || [])];

      const hasCreator = currentAttendees.some((guest) => guest.email === data.creator);
      if (!hasCreator) {
        currentAttendees.push({ email: data.creator, organizer: true });
      }

      const isDuplicateEmail = currentAttendees.some((guest) => guest.email === email);
      if (!isDuplicateEmail) {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            attendees: [...currentAttendees, { email }],
          },
        };
      }

      return prevState;
    });

    setIsBuffMode(false);

    onClose();

    inputRef.current.value = "";
    setIsShowGuestsInput(true);
  };

  const handleDelGuest = (email) => {
    const updatedAttendees = (data.attendees || []).filter((guest) => guest.email !== email);

    if (updatedAttendees.length === 0) {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          attendees: null,
          hangoutLink: null,
        },
      }));
      setIsBuffMode(true);
    } else {
      setTaskDetail((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          attendees: updatedAttendees,
        },
      }));
    }
  };

  const getGuestInputItemPosition = () => {
    if (inputRef.current !== null) {
      const element = inputRef.current;
      const rect = element.getBoundingClientRect();

      return {
        top: `${rect.bottom + 5 + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
      };
    }
  };

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles["guests-modal"]}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);
      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <div
      className={styles["setting-property"]}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === "Escape") {
          setIsShowGuestsInput(false);
        }
      }}
    >
      <div className={styles["setting-property__btn"]}>
        <PropertyTitle Icon={GuestIcon} label="Guests" expand={expand} />
        <div className={styles["setting-property__guests-contents"]}>
          {data.attendees?.length > 0 ? (
            <div className={styles["guests-info__container"]}>
              <div>
                <span
                  className={styles["guests-info__count"]}
                >{`${data.attendees.length} guests`}</span>
              </div>
              {!isShowGuestsInput && (
                <AddIcon
                  className={styles["guests-info__add-btn"]}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShowGuestsInput(true);
                  }}
                />
              )}
            </div>
          ) : (
            !isShowGuestsInput && (
              <button
                className={styles.property__empty_title}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowGuestsInput(true);
                }}
              >
                <span>Add guests</span>
              </button>
            )
          )}
          {/* 추가된 guests */}
          {data.attendees?.length > 0 && (
            <div
              className={clsx({
                [styles["selected-guest-wrap"]]: !expand,
                [styles["selected-guest-wrap--expand"]]: expand,
              })}
            >
              {data.attendees.map(
                (guest) =>
                  guest.organizer && (
                    <SelectedGuestRow
                      key={guest.email}
                      data={contactData}
                      email={guest.email}
                      responseStatus={guest.responseStatus}
                      onDelete={handleDelGuest}
                      isSelfTask={isSelfTask}
                      organizer
                    />
                  )
              )}
              {data.attendees.map(
                (guest) =>
                  !guest.organizer && (
                    <SelectedGuestRow
                      key={guest.email}
                      data={contactData}
                      email={guest.email}
                      responseStatus={guest.responseStatus}
                      onDelete={handleDelGuest}
                      isSelfTask={isSelfTask}
                    />
                  )
              )}
            </div>
          )}

          {isShowGuestsInput && (
            <div className={styles.input_box}>
              <input
                ref={inputRef}
                className={`${styles["guests-input"]} ${isInvalidEmail && styles.validationError} `}
                placeholder="Add guests"
                onChange={handleGuestInputChange}
                onBlur={handleGuestInputBlur}
                onKeyDown={handleInputKeyDown}
              />
              {isInvalidEmail && <span className={styles.validationErrorIcon}></span>}
              {/* guests 후보 Dropdown */}
              {createPortal(
                inputRef.current?.value && filteredGuests.length > 0 && (
                  <ul
                    className={clsx(styles["guests-modal"], {
                      [styles["showScrollbar"]]: isScrolling,
                    })}
                    ref={guestDropdownRef}
                    style={getGuestInputItemPosition()}
                    onClick={(event) => event.stopPropagation()}
                    onMouseDown={(event) => event.stopPropagation()}
                  >
                    {filteredGuests.map((guest, index) => (
                      <GuestRow
                        key={index}
                        data={filteredGuests}
                        email={guest.email}
                        onClick={handleAddGuest}
                        isActive={currentActiveIndex === index}
                        isExistingGuest={
                          data.attendees &&
                          data.attendees.some(
                            (existingGuest) => existingGuest.email === guest.email
                          )
                        }
                      />
                    ))}
                  </ul>
                ),
                document.body
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
