import * as dates from "date-arithmetic";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-big-calendar";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import { useRecoilValue } from "recoil";
import { defaultDurationState } from "../../recoil/calendar/settingCalendar";
import "./CustomDay.css";

export default function CustomDay({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) {
  const currRange = useMemo(
    () => CustomDay.range(localizer.startOf(date, "day"), { localizer }),
    [date, localizer]
  );
  const wrapRef = useState(null);
  const defaultDuration = useRecoilValue(defaultDurationState);

  useEffect(() => {
    const rowElements = document.querySelectorAll(".rbc-row");

    rowElements.forEach((rowElement) => {
      const segments = rowElement.querySelectorAll(".rbc-row-segment");
      // segments.forEach((segment) => {
      //   if (segment.style.flexBasis != "33.3333%") {
      //     if (segment.style.flexBasis == "100%") {
      //       segment.style.paddingRight = "2px";
      //     } else {
      //       segment.style.paddingRight = "12px";
      //     }
      //   }
      // });
    });
  }, [wrapRef]);

  return (
    <>
      <div className="custom-view-day" ref={wrapRef}></div>
      <TimeGrid
        date={date}
        eventOffset={defaultDuration}
        localizer={localizer}
        max={max}
        min={min}
        range={currRange}
        scrollToTime={scrollToTime}
        {...props}
      />
    </>
  );
}

CustomDay.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CustomDay.range = (date, { localizer }) => {
  const start = date;
  const end = dates.add(start, 2, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CustomDay.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, "day");

    case Navigate.NEXT:
      return localizer.add(date, 3, "day");

    default:
      return date;
  }
};

CustomDay.title = (date) => {
  return `My Custom day: ${date.toLocaleDateString()}`;
};
