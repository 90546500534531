import { useState, useRef, useEffect } from "react";

import ProjectFilterTitle from "./ProjectFilterTitle/ProjectFilterTitle";
import ProjectGroup from "./ProjectGroup/ProjectGroup";
import ProjectFilterIcons from "./ProjectFilterIcons/ProjectFilterIcons";
import TrashPopup from "./TrashPopup/TrashPopup";

import styles from "./styles.module.css";

export default function ProjectFilter({ loadProjects, settingState, setIsIntegrationModalOpen }) {
  const [sidebarExpand, setSidebarExpand] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isTrashPopupOn, setIsTrashPopupOn] = useState(false);

  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  const handleTrashClick = () => {
    setIsTrashPopupOn(!isTrashPopupOn);
  };

  const closeTrashPopup = () => {
    setIsTrashPopupOn(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isTrashPopupOn &&
        popupRef.current &&
        buttonRef.current &&
        !popupRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        event.stopPropagation();
        closeTrashPopup();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isTrashPopupOn, popupRef, buttonRef]);

  const handleExpandClick = () => {
    setSidebarExpand((current) => !current);
    setIsIntegrationModalOpen(false);
  };

  return (
    <div
      className={`${styles["projectFilter"]}
        ${!sidebarExpand && styles["projectFilter-default"]}
        ${sidebarExpand && styles["projectFilter-expand"]}`}
    >
      <ProjectFilterTitle handleExpandClick={handleExpandClick} sidebarExpand={sidebarExpand} />
      <ProjectGroup
        expand={sidebarExpand}
        onExpandClick={handleExpandClick}
        loadProjects={loadProjects}
        isDone={isDone}
      />
      <div className={styles.divider}></div>
      <ProjectFilterIcons
        settingState={settingState}
        onTrashClick={handleTrashClick}
        buttonRef={buttonRef}
      />
      <div ref={popupRef}>{isTrashPopupOn && <TrashPopup onClose={closeTrashPopup} />}</div>
    </div>
  );
}
