import PropertyTitle from "../Common/PropertyTitle";
import TaskNote from "./TaskNote";

import { isNoteEmpty } from "../../../utils/taskDetail/checkDataChanged/isNoteDataChanged";

import { ReactComponent as NoteIcon } from "../../../assets/TaskDetail/note-icon.svg";

import styles from "./NotePreview.module.css";

interface NotePreviewProps {
  expand: boolean;
  isChangeNote: boolean;
  noteData: string;
  onSave: () => void;
  setIsModalNoteClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NotePreview({
  expand,
  isChangeNote,
  noteData,
  onSave,
  setIsModalNoteClicked,
}: NotePreviewProps) {
  const parsedNoteData = noteData ? JSON.parse(noteData) : "";
  const parsedText =
    !expand && noteData && parsedNoteData.length > 0
      ? parsedNoteData
          .slice(0, 3)
          .map((item: any) => {
            return item?.content && item?.content[0]
              ? item?.content[0].type === "link"
                ? item?.content[0]?.content[0].text
                : item?.content[0]?.text || ""
              : "";
          })
          .join("\n")
      : "";

  return (
    <>
      {!expand ? (
        <div
          className={styles["property--note"]}
          onClick={() => {
            setIsModalNoteClicked((prev) => !prev);
          }}
        >
          <PropertyTitle Icon={NoteIcon} label="Notes" expand={expand} />
          {isNoteEmpty(noteData) ? (
            <div className={styles["property__empty_title"]}>
              <button>Add a note</button>
            </div>
          ) : (
            <div className={styles["property--note__contents"]}>
              <span className={styles["setting-note-mini"]}>{parsedText && parsedText}</span>
            </div>
          )}
        </div>
      ) : (
        <div className={styles["setting-note"]}>
          <TaskNote isChangeNote={isChangeNote} onSave={onSave} expand={expand} />
        </div>
      )}
    </>
  );
}
