export default function styledConsole({
  topic = "",
  title = "",
  data,
  topicColor = "skyblue",
  method = "log",
}) {
  const term_1 = `%c[${topic}]`;
  const term_1_style = [`color: ${topicColor}`, "font-weight : bold"].join(";");
  const term_2 = `%c${title}`;
  const term_2_style = ["font-weight : bold"].join(";");
  console[method](`${term_1}${term_2}`, term_1_style, term_2_style, data);
}
