import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import { popupState } from "../../recoil/popup/popupState";
import styles from "./Popup.module.css";

function Popup() {
  const [popup, setPopup] = useRecoilState(popupState);

  if (!popup.isVisible) return null;

  const handleDiscard = (event) => {
    event.stopPropagation();
    setPopup((prev) => ({
      isVisible: false,
      clickType: "discard",
      message: "",
      isDataDuplicateWithGuest: prev.isDataDuplicateWithGuest,
      data: popup.data,
    }));
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setPopup({
      isVisible: false,
      clickType: "cancel",
      message: "",
      data: popup.data,
    });
  };

  const handleHeaderClose = (event) => {
    event.stopPropagation();
    setPopup({
      isVisible: false,
      clickType: "headerClose",
      message: "",
      data: popup.data,
    });
  };

  const handleOk = (event) => {
    event.stopPropagation();
    setPopup({
      isVisible: false,
      clickType: "ok",
      message: "",
      data: popup.data,
    });
  };

  return ReactDOM.createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <div className={styles.headerTitle}></div>
        <div
          className={styles.headerClose}
          onClick={handleHeaderClose}
          onMouseDown={(e) => e.stopPropagation()}
        ></div>
      </div>

      <div className={styles.modalMessageContainer}>
        <span className={styles.modalMessage}>{popup.message}</span>
      </div>

      <div className={styles.buttonArea}>
        <div className={styles.buttonLeftArea}>
          <div
            className={styles.modalDiscardButton}
            onClick={handleDiscard}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {popup.discardButtonTitle}
          </div>
        </div>
        <div className={styles.buttonRightArea}>
          <div
            className={styles.modalCloseButton}
            onClick={handleClose}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {popup.closeButtonTitle}
          </div>
          <div
            className={styles.modalOkButton}
            onClick={handleOk}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {popup.okButtonTitle}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("popup-root")
  );
}

export default Popup;
