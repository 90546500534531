import styles from "./Tooltip.module.css";

export default function Tooltip({ text, isVisible }) {
  return (
    <div className={styles.tooltip}>
      <span
        className={styles.tooltiptext}
        style={{ visibility: `${isVisible ? "visible" : "hidden"}` }}
      >
        {text}
      </span>
    </div>
  );
}
