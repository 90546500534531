import { useQuery } from "@tanstack/react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState } from "../recoil/auth/tokenState";
import useApiCalendar from "../services/calendar/useApiCalendar";
import { accountState } from "../recoil/account/accountState";
import { calendarEventState, calendarViewBaseDateState } from "../recoil/calendar/calendarState";

const useFetchCalendarEvents = () => {
  const calendarApi = useApiCalendar();
  const account = useRecoilValue(accountState);
  const token = useRecoilValue(tokenState);
  const calendarViewBaseDate = useRecoilValue(calendarViewBaseDateState);
  const setCalendarEvents = useSetRecoilState(calendarEventState);
  const userEmail = account && account.accountInfo.accounts[0].email;

  const fetchCalendarEvents = async () => {
    if (userEmail) {
      const firstDayOfMonth = new Date(
        calendarViewBaseDate.getFullYear(),
        calendarViewBaseDate.getMonth() - 1,
        1
      );
      const lastDayOfMonth = new Date(
        calendarViewBaseDate.getFullYear(),
        calendarViewBaseDate.getMonth() + 2,
        0
      );
      const response = await calendarApi.get("", {
        params: {
          timeMin: firstDayOfMonth.toISOString(),
          timeMax: lastDayOfMonth.toISOString(),
          maxResults: 2500,
          singleEvents: true,
        },
      });
      return response.data.items.map((event) => ({
        ...event,
        creator: {
          email: userEmail,
        },
      }));
    }
  };

  return useQuery({
    queryKey: ["calendarEvents", calendarViewBaseDate, account],
    queryFn: () => fetchCalendarEvents(),
    enabled: !!calendarViewBaseDate && !!account && !!token.mAccessToken,
    retry: 3,
    staleTime: 1 * 1000, // 1분
    gcTime: Infinity, // 5분
    onSuccess: (data) => {
      setCalendarEvents((currentEvents) => {
        const removedEvents = currentEvents.filter((currentItem) =>
          data.some((newItem) => newItem.id === currentItem.id)
        );

        const updatedEvents = removedEvents.map((currentItem) => {
          const matchingNewEvent = data.find((newItem) => newItem.id === currentItem.id);
          if (
            matchingNewEvent &&
            JSON.stringify(matchingNewEvent) !== JSON.stringify(currentItem)
          ) {
            return matchingNewEvent;
          }
          return currentItem;
        });

        const newEvents = data.filter(
          (newItem) => !removedEvents.some((currentItem) => currentItem.id === newItem.id)
        );

        return [...updatedEvents, ...newEvents];
      });
    },
  });
};

export default useFetchCalendarEvents;
