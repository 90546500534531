import { atom } from "recoil";

export const popupState = atom({
  key: "popupState",
  default: {
    headerTitle: "",
    isVisible: false,
    clickType: "", // ok, cancel, discard
    message: "",
    okButtonTitle: "",
    closeButtonTitle: "",
    discardButtonTitle: "",
    isDataDuplicateWithGuest: false,
    data: null,
  },
});
