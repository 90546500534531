import { useState } from "react";
import styles from "./ActionMenuModal.module.css";

export default function Circle({ circleColor, selected, onClick }) {
  const [hoveredCircle, setHoveredCircle] = useState(false);

  const handleCircleMouseEnter = () => {
    setHoveredCircle(true);
  };

  const handleCircleMouseLeave = () => {
    setHoveredCircle(false);
  };

  return (
    <div
      className={`${styles.circle} ${selected && styles.selected} ${hoveredCircle && styles.hovered}`}
      onMouseEnter={handleCircleMouseEnter}
      onMouseLeave={handleCircleMouseLeave}
      onClick={() => onClick(circleColor)}
    >
      <div className={styles.innerCircle} style={{ backgroundColor: circleColor }} />
      <div className={styles.outerCircle} />
    </div>
  );
}
