export const MeetWithColors = [
  "#1E285C",
  "#183624",
  "#462649",
  "#4B291F",
  "#494421",
  "#4F2838",
  "#29411E",
  "#34234A",
  "#4F2A2A",
  "#1F3851",
  "#3D3311",
  "#492118",
  "#0A323F",
  "#22340B",
  "#471C39",
  "#513D20",
];
