const useInboxTaskUpdate = () => {
  const updateTask = (prevTasks, dragIndex, dragItem) => {
    const updatedTasks = [...prevTasks];
    updatedTasks.splice(dragIndex, 1);
    updatedTasks.splice(dragIndex, 0, dragItem);
    return updatedTasks;
  };

  const deleteTask = (prevTasks, taskId) => {
    return prevTasks.filter((task) => task.id !== taskId);
  };

  return { updateTask, deleteTask };
};

export default useInboxTaskUpdate;
