import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";
import styles from "./style.module.css";

import { ReactComponent as VerticalLine } from "../../assets/Login/signup-vertical-line.svg";
import { ReactComponent as GoogleLoginIcon } from "../../assets/Login/google-login-icon.svg";
import { useJuneTrackCall } from "../../utils/june/analytics";

export default function Join() {
  const [token, setToken] = useRecoilState(tokenState);
  const navigate = useNavigate();
  const trackCall = useJuneTrackCall();

  const handleGoogleLogin = () => {
    window.location.href =
      process.env.REACT_APP_SERVER + "auth/google/primary?requestForNewRefreshToken=true";
  };

  const handleNavigation = () => {
    if (!document.startViewTransition) {
      return navigate("/main");
    } else {
      return document.startViewTransition(() => {
        navigate("/main");
      });
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const mAccessToken = urlSearchParams.get("mAccessToken");
    const mRefreshToken = urlSearchParams.get("mRefreshToken");
    const accessToken = urlSearchParams.get("accessToken");

    if (accessToken) {
      setToken({
        mAccessToken: mAccessToken,
        mRefreshToken: mRefreshToken,
        accessToken: accessToken,
      });

      trackCall("signup", { type: "google" });
      handleNavigation();
    }
  }, []);

  return (
    <div className={styles["wrap"]}>
      <div className={styles["body"]}>
        <div className={styles["title"]}>
          Try Moba
          <VerticalLine style={{ margin: "15% 0" }} />
        </div>
        <button className={styles["gsi-material-button"]} onClick={handleGoogleLogin}>
          <div className={styles["gsi-material-button-state"]}></div>
          <div className={styles["gsi-material-button-content-wrapper"]}>
            <div className={styles["gsi-material-button-icon"]}>
              <GoogleLoginIcon />
            </div>
            <span className={styles["gsi-material-button-contents"]}>Sign in with Google</span>
            <span style={{ display: "none" }}>Sign in with Google</span>
          </div>
        </button>
        {/* <div className={styles['login-btn']} onClick={handleGoogleLogin}>
                  <div className={styles['login-icon']}></div>
                  <div className={styles['login-text']}>
                  Sign up with Google
                  </div>
              </div> */}
      </div>
    </div>
  );
}
