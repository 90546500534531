import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { projectColorListState } from "../../../recoil/account/accountState";

import useApi from "../../../services/auth/useApi";
import styles from "./TrashPopup.module.css";

import trashDeleteImg from "../../../assets/Trash/trash-delete.png";
import trashRestore from "../../../assets/Trash/trash-restore.png";
import iconForVisibility from "../../../utils/taskDetail/visibility/iconForVisibility";

import useFetchDeletedProjects from "../../../hooks/useFetchDeletedProjects";
import useFetchDeletedTasks from "../../../hooks/useFetchDeletedTasks";
import { deletedProjectListState } from "../../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../../utils/june/analytics";

import { COMPLETED } from "../../../constants/taskStateType";
import useFetchCompletedTasks from "../../../hooks/useFetchCompletedTasks";
import useFetchInProgressTasks from "../../../hooks/useFetchInProgressTasks";
import useFetchProjects from "../../../hooks/useFetchProjects";
import { deletedTaskListState } from "../../../recoil/taskList/deletedTaskListState";
import { trashRestoreCalendarEventState } from "../../../recoil/trash/trashRestoreCalendarEventState";
import formatDateTimeForJune from "../../../utils/june/formatDateTimeForJune";

const TrashProjectRow = ({ index, data, onDeleteClick, onRestoreClick }) => {
  const [rowIcon, setRowIcon] = useState(true);

  const handleDeleteClick = () => {
    onDeleteClick(data.id);
  };

  const handleRestoreClick = () => {
    onRestoreClick(data);
  };

  return (
    <div className={styles.project__item}>
      <div
        className={styles.project__icon}
        style={{
          backgroundColor: `${data.color}${!rowIcon ? "26" : ""}`,
          boxShadow: "0 0 0 2px " + data.color + " inset",
        }}
        onClick={() => setRowIcon(!rowIcon)}
      />
      <div className={styles.project__title}>{data.title}</div>
      <div className={styles.project__delete}></div>
      <div className={styles.project__delete} onClick={handleRestoreClick}>
        <img className={styles.project__delete__img} src={trashRestore} alt="restore" />
      </div>

      <div className={styles.project__delete} onClick={handleDeleteClick}>
        <img className={styles.project__delete} src={trashDeleteImg} alt="trash" />
      </div>
    </div>
  );
};

const TrashTaskRow = ({ index, data, onDeleteClick, onRestoreClick }) => {
  const [projectColorList, setProjectColorListState] = useRecoilState(projectColorListState);
  const [integrationImg, setIntegrationImg] = useState(null);
  const isDone = data.lastStatus === COMPLETED;

  useEffect(() => {
    const integrationData = data.integration?.provider;
    if (integrationData && integrationData !== "") {
      setIntegrationImg(integrationData);
    }
  }, [data]);

  const handleDeleteClick = () => {
    onDeleteClick(data.id, data.creator);
  };

  const handleRestoreClick = () => {
    onRestoreClick(data, data.creator);
  };

  return (
    <div className={styles.project__item}>
      <div
        className={`${styles.taskboxBodyIcon} ${
          data.lastStatus === COMPLETED ? styles.taskListBodyIconDone : ""
        }`}
        style={{
          backgroundColor: `${
            data.projectId ? projectColorList[data.projectId] : "#abadad"
          }${isDone ? "" : "26"}`,
          boxShadow: `0 0 0 2px ${
            data.projectId ? projectColorList[data.projectId] : "#abadad"
          } inset`,
        }}
      ></div>
      <div className={styles.taskListBodyTitle}>{data.title}</div>
      {data.visibility && (
        <div className={styles.visibilityIcon}>
          {iconForVisibility(data.visibility, data.transparency)}
        </div>
      )}
      {integrationImg && (
        <div
          className={`${styles["integration"]} ${
            integrationImg === "gmail"
              ? styles["integration-gmail"]
              : integrationImg === "jira"
                ? styles["integration-jira"]
                : integrationImg === "slack" && styles["integration-slack"]
          } `}
        ></div>
      )}
      <div className={styles.project__delete} onClick={handleRestoreClick}>
        <img className={styles.project__delete__img} src={trashRestore} />
      </div>

      <div className={styles.project__delete} onClick={handleDeleteClick}>
        <img className={styles.project__delete} src={trashDeleteImg} />
      </div>
    </div>
  );
};

export default function TrashPopup({ onClose }) {
  const api = useApi();
  const { fetchDeletedProjects } = useFetchDeletedProjects();
  const { fetchDeletedTasks } = useFetchDeletedTasks();
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabContArr, setContArr] = useState([]);
  const tabNameList = ["Projects", "Tasks"];
  const [deletedProjectList, updateDeletedProjectList] = useRecoilState(deletedProjectListState);
  const [deletedTaskList, updateDeletedTaskList] = useRecoilState(deletedTaskListState);
  const [trashRestoreCalendarEvent, setTrashRestoreCalendarEventState] = useRecoilState(
    trashRestoreCalendarEventState
  );
  const { fetchCompletedTasks } = useFetchCompletedTasks();
  const { fetchInProgressTasks } = useFetchInProgressTasks();
  const { fetchProjectsData } = useFetchProjects();

  const trackCall = useJuneTrackCall();

  const tabClickHandler = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    fetchDeletedProjects();
    fetchDeletedTasks();
  }, []);

  useEffect(() => {
    if (activeIndex === 0) {
      projectLoadData();
    } else {
      taskLoadData();
    }
  }, [deletedProjectList, deletedTaskList]);

  useEffect(() => {
    if (activeIndex === 0) {
      projectLoadData();
      trackCall("view_trash", { type: "Project" });
    } else {
      taskLoadData();
      trackCall("view_trash", { type: "Task" });
    }
  }, [activeIndex]);

  const projectLoadData = () => {
    setContArr(deletedProjectList);
  };

  const taskLoadData = () => {
    setContArr(deletedTaskList);
  };

  const handelProjectRestoreOnClick = (project) => {
    api.patch("projects/" + project.id + "/unmark").then(() => {
      fetchProjectsData();
      fetchDeletedProjects();
      trackCall("restore_project", { type: "deleted_items" });
    });
  };

  const handleProjectDeleteClick = (projectId) => {
    api.delete("projects/" + projectId).then(() => {
      fetchDeletedProjects();
    });
  };

  const handelTaskRestoreOnClick = (task, creator) => {
    const newTask = { ...task, itemStatus: task.lastStatus };
    api
      .patch("tasks/" + task.id + "/unmark", "", {
        headers: {
          "X-Requester": creator,
        },
      })
      .then(() => {
        if (task.start) {
          setTrashRestoreCalendarEventState(newTask);
          trackCall("block_time", {
            location: "trash",
            start_datetime: formatDateTimeForJune(newTask.start.dateTime),
            end_datetime: formatDateTimeForJune(newTask.end.dateTime),
          });
        } else if (!task.start && task.lastStatus === COMPLETED) {
          fetchCompletedTasks();
        } else {
          fetchInProgressTasks();
        }

        trackCall("restore_block", {
          location: task.end || task.start ? "calendar" : "inbox",
          type: "deleted_item",
        });

        fetchDeletedTasks();
      });
  };
  const handleTaskDeleteClick = (taskId, creator) => {
    api
      .delete("tasks/" + taskId, {
        headers: {
          "X-Requester": creator,
        },
      })
      .then(() => {
        fetchDeletedTasks();
      });
  };

  const handleProjectEmpty = () => {
    api
      .delete("/projects", {
        data: {
          projects: tabContArr.map((project) => project.id),
        },
      })
      .then(() => {
        fetchDeletedProjects();
      });
  };

  const handleTaskEmpty = () => {
    api
      .delete("/tasks", {
        data: {
          tasks: tabContArr.map((task) => ({
            taskId: task.id,
            creator: task.creator,
          })),
        },
      })
      .then(() => {
        fetchDeletedTasks();
      });
  };

  const handleEmpty = () => {
    if (activeIndex === 0) {
      handleProjectEmpty();
    } else {
      handleTaskEmpty();
    }
  };

  return (
    <div className={styles.trash}>
      <ul>
        {tabNameList.map((tabName, index) => {
          return (
            <li
              className={`${activeIndex === index ? styles.tap__active : styles.tap__inactive}`}
              onClick={() => tabClickHandler(index)}
            >
              {tabName}
            </li>
          );
        })}
      </ul>
      <div className={styles.tap__contents}>
        {activeIndex === 0
          ? tabContArr?.map((project, index) => (
              <TrashProjectRow
                key={index}
                index={index}
                data={project}
                onDeleteClick={handleProjectDeleteClick}
                onRestoreClick={handelProjectRestoreOnClick}
              />
            ))
          : tabContArr.map((task, index) => (
              <TrashTaskRow
                key={index}
                index={index}
                data={task}
                onDeleteClick={handleTaskDeleteClick}
                onRestoreClick={handelTaskRestoreOnClick}
              />
            ))}
      </div>
      <div className={styles.trash__guide}>
        <span className={styles.trash__guide__text}>
          Your history is available here for 30 days.
        </span>
        <div className={styles.trash__guide__empty} onClick={handleEmpty}>
          <img className={styles.project__delete} src={trashDeleteImg} />
          <span className={styles.trash__guide__empty__text}>Empty</span>
        </div>
      </div>
    </div>
  );
}
