import { useRecoilState, useRecoilValue } from "recoil";

import findValueByKey from "../../../utils/taskDetail/findValueByKey";

import { taskPopupState } from "../../../recoil/taskDetail/taskPopupState";
import { accountState } from "../../../recoil/account/accountState";

import {
  getVisiblilityComponent,
  getVisiblilityTooltipContents,
  getVisiblilityDropdownComponent,
} from "./getVisibilityComponents";

import { ReactComponent as MoreIcon } from "../../../assets/TaskDetail/more-icon.svg";
import { ReactComponent as ShrinkIcon } from "../../../assets/TaskDetail/shrink-icon.svg";
import { ReactComponent as BackIcon } from "../../../assets/Popup/back.svg";

import clsx from "clsx";

import styles from "./Header.module.css";

function NoteModalHeader({ setIsModalNoteClicked }) {
  const taskDetail = useRecoilValue(taskPopupState);
  return (
    <div className={styles["note-modal-header"]} style={{ display: "flex", color: "white" }}>
      <div className={styles.backIcon} onClick={(e) => setIsModalNoteClicked(false)}>
        <BackIcon />
      </div>
      <div className={styles["note-modal-header__title"]}>
        <span>
          {taskDetail.data.title ? taskDetail.data.title : `New ${taskDetail.data.taskType}`}
        </span>
      </div>
    </div>
  );
}

function Header({
  moreModalRef,
  visibilityDropdownRef,

  isTask,
  setIsTask,
  toggleExpand,
  setToggleExpand,
  isDisabled,
  isVisibilityClick,
  isSidebarModalOn,
  selectedVisibilityType,
  isModalNoteClicked,
  setIsModalNoteClicked,

  handleVisibilityClick,
  handleVisibilityDropdownItemClick,
  handleTaskDelete,
  handleMoreButtonClick,
}) {
  const [taskDetail, setTaskDetail] = useRecoilState(taskPopupState);
  const accountData = useRecoilValue(accountState);

  const dropdownList = Object.entries({
    public: "Public",
    deepwork: "Deep work",
    invisible: "Invisible",
  });

  const onDataDuplicate = (e) => {
    taskDetail.handleDataDuplicate(e, taskDetail.data);
  };

  return (
    <div
      className={clsx(styles.header, {
        [styles["header-mini"]]: !toggleExpand,
        [styles["header-expand"]]: toggleExpand,
      })}
    >
      {/* 2023.12.15 저장 트리거 시점 변경 후 고칠 예정
             <div className={styles["header-move"]}>
              <span onClick={handlePrevTask}>&lt;</span>
              <span onClick={handleNextTask}>&gt;</span>
            </div> */}

      {isModalNoteClicked ? (
        <NoteModalHeader setIsModalNoteClicked={setIsModalNoteClicked} />
      ) : taskDetail.type !== "inbox" ? (
        <div className={styles.toggle_container}>
          <label className={styles.toggle}>
            <input
              type="checkbox"
              checked={!isTask}
              disabled={taskDetail.type == "inbox"}
              onChange={
                taskDetail.type !== "inbox"
                  ? (e) => {
                      setIsTask((prev) => !prev);
                    }
                  : undefined
              }
            />
            <span className={styles.slider}>
              <div>
                <div className={styles.slider_text_box}>
                  <span
                    className={clsx(styles.slider_text_Task, {
                      [styles.selected_text]: isTask,
                    })}
                  >
                    Task
                  </span>
                </div>
                <div className={styles.slider_text_box}>
                  <span
                    className={clsx(styles.slider_text_Event, {
                      [styles.selected_text]: !isTask,
                    })}
                  >
                    Event
                  </span>
                </div>
              </div>
            </span>
          </label>
        </div>
      ) : (
        <div></div>
      )}

      <div className={styles["header-menu"]}>
        {!isModalNoteClicked && (
          <>
            <div className={styles.visibilityArea} onMouseDown={(e) => e.stopPropagation()}>
              {getVisiblilityComponent(
                selectedVisibilityType,
                isVisibilityClick,
                handleVisibilityClick
              )}
              {isVisibilityClick && (
                <div className={styles.visibilityDropdownBox} ref={visibilityDropdownRef}>
                  {dropdownList.map((label) => (
                    <div
                      key={label[0]} // key를 추가했습니다. map 함수를 사용할 때는 항상 고유한 key가 필요합니다.
                      className={`${styles.dropdownListItem} ${
                        selectedVisibilityType === label[0] ? styles.active : ""
                      }`} // 수정된 조건부 클래스 추가 방식
                      onClick={() => handleVisibilityDropdownItemClick(label[0])}
                    >
                      {getVisiblilityDropdownComponent(
                        label[0],
                        selectedVisibilityType === label[0]
                      )}
                      <div className={styles.dropdownTitle}>{label[1]}</div>
                      <div className={styles.dropdownInfoIcon}>
                        <div className={styles.dropdownInfoDescription}>
                          <span className={styles.infoDescriptionText}>
                            {getVisiblilityTooltipContents(label[0])}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.headerMenuAccArea}>
              <div
                className={styles["header-menu-acc"]}
                style={{
                  backgroundImage: `url(${findValueByKey(
                    accountData.accountInfo.accounts,
                    "email",
                    taskDetail.data.creator,
                    "photoUrl"
                  )})`,
                  width: "24px",
                  height: "24px",
                  backgroundSize: "cover",
                }}
                // onClick={() => setIsAccModal(!isAccModal)}
              ></div>
            </div>
          </>
        )}

        {!isModalNoteClicked && !isDisabled && (
          <button
            className={styles["header__more--btn"]}
            onClick={handleMoreButtonClick}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <>
              <MoreIcon className={styles["header__more--icon"]} />
              {isSidebarModalOn && (
                <div className={styles["modal"]} ref={moreModalRef}>
                  <button onClick={onDataDuplicate}>Duplicate</button>
                  <button onClick={handleTaskDelete}>Delete</button>
                </div>
              )}
            </>
          </button>
        )}

        {!toggleExpand ? (
          <div
            className={styles.headerMenuExpand}
            onClick={() => {
              if (!toggleExpand) {
                setIsModalNoteClicked(false);
              }
              setToggleExpand(!toggleExpand);
            }}
          ></div>
        ) : (
          <button className={styles.header__shrink} onClick={() => setToggleExpand(!toggleExpand)}>
            <ShrinkIcon className={styles.header__shrinkIcon} />
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;
