import styles from "./LoadingComponent.module.css";

interface loadingComponentProps {
  size?: string;
  strokeWidth?: string;
  padding?: string;
  justifyContent?: string;
}

function LoadingComponent({
  size = "48px",
  strokeWidth = "5px",
  padding = "0px",
  justifyContent = "center",
}: loadingComponentProps) {
  return (
    <div
      className={styles.loading_component}
      style={{
        padding: padding,
        justifyContent: justifyContent,
      }}
    >
      <span
        className={styles.loader}
        style={{
          width: size,
          height: size,
          borderWidth: strokeWidth,
        }}
      ></span>
    </div>
  );
}

export default LoadingComponent;
