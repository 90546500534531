import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";

import useApi from "../../services/auth/useApi";
import styles from "./DeletePoup.module.css";

import closeImg from "../../assets/Settings/close.png";

function DeleteDescriptionPopup({ onDelete, onCancel, loginData }) {
  return (
    <>
      <div className={styles.description}>
        You are deleting the account registered under {loginData.emails[0]}.
      </div>
      <div className={styles.question}>Are you sure to delete? </div>
      <div className={styles.button__area}>
        <div className={styles.button} onClick={() => onDelete()}>
          Delete Account
        </div>
        <div className={styles.button} onClick={() => onCancel()}>
          Cancel
        </div>
      </div>
    </>
  );
}

function DeleteFeedbackPopup({ onDelete, onCancel }) {
  const [feedback, setFeedback] = useState("");

  const onChange = (event) => setFeedback(event.target.value);
  const handleDelete = () => {
    if (feedback.length !== 0) {
      onDelete(feedback);
    }
  };

  return (
    <>
      <div className={styles.feedback__description}>
        We would appreciate if you let us know why you’ve decided to leave us.
      </div>
      <div className={styles.feedback__question}>
        We read all the comments, and will improve our service based on your feedbacks.
      </div>
      <div className={styles.feedback__area}>
        <input
          value={feedback}
          type="text"
          placeholder="I’m deleting my account because ..."
          className={styles.feedback__input}
          onChange={onChange}
        />
      </div>
      <div className={styles.feedback__button__area}>
        <div className={styles.button} onClick={handleDelete}>
          Delete Account
        </div>
        <div className={styles.button} onClick={() => onCancel()}>
          Cancel
        </div>
      </div>
    </>
  );
}

function DeletePopup({ toggleDeletePopup, loginData }) {
  const api = useApi();
  const navigate = useNavigate();
  const [isShowFeedbackPopup, setIsShowFeedbackPopup] = useState(true);
  const [token, setToken] = useRecoilState(tokenState);

  const handleDeleteAccount = (feedback) => {
    api
      .delete("accounts/primary", {
        data: {
          feedback: feedback,
        },
      })
      .then((res) => {
        setToken({
          mAccessToken: null,
          mRefreshToken: null,
          accessToken: null,
        });
      });
  };

  useEffect(() => {
    if (token.mRefreshToken == null) {
      navigate("/");
    }
  }, [token]);

  const handleToggleDeletePopup = () => {
    toggleDeletePopup();
  };

  const handleDeleteConfirm = () => {
    setIsShowFeedbackPopup((current) => !current);
  };

  return (
    <div className={styles.popup}>
      <div className={styles.popup__close} onClick={handleToggleDeletePopup}>
        <img src={closeImg} alt="close"></img>
      </div>
      <div className={styles.title}>Delete account </div>
      {isShowFeedbackPopup ? (
        <DeleteDescriptionPopup
          onDelete={handleDeleteConfirm}
          onCancel={handleToggleDeletePopup}
          loginData={loginData}
        />
      ) : (
        <DeleteFeedbackPopup onDelete={handleDeleteAccount} onCancel={handleToggleDeletePopup} />
      )}
    </div>
  );
}

export default DeletePopup;
