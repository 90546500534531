import { useEffect, useState } from "react";

import useApi from "../../services/auth/useApi";

import ActionMenuModal from "../Modal/ActionMenuModal";
import styles from "./ProjectItem.module.css";

import bookmarkAddIcon from "../../assets/Wiki/project-bookmark-add.png";
import ResourceAddModal from "../../views/TaskDetail/Link/LinkAddModal";
import ToolTip from "./Tooltip";

import { useSetRecoilState } from "recoil";
import { projectListState } from "../../recoil/projects/projectListState";
import { useJuneTrackCall } from "../../utils/june/analytics";

function BookmarkAdd({ projectData, loadData }) {
  const api = useApi();
  const [projectInfo, setProjectInfo] = useState({
    createdAt: null,
    creator: null,
    id: null,
    title: null,
    projectId: null,
    description: null,
    bookmarks: [],
  });
  const [isResourceAddModal, setIsResourceAddModal] = useState(false);

  const handleResourceAdd = (event) => {
    event.stopPropagation();
    setIsResourceAddModal(true);
  };

  const updateBookmark = (key, value) => {};

  return (
    <>
      <div className={styles.bookmarkAddArea} onClick={handleResourceAdd}>
        <img className={styles.bookmarkAddIcon} src={bookmarkAddIcon} />
      </div>
      {isResourceAddModal && (
        <ResourceAddModal
          onSave={(key, value) => updateBookmark(key, value)}
          onClose={() => setIsResourceAddModal(false)}
        />
      )}
    </>
  );
}

function BookmarkFavicon({ index, projectRowData }) {
  const [iconPos, setIconPos] = useState(-100);

  const handleIconEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    const centerY = rect.top + rect.height / 2;
    setIconPos(centerY);
  };

  const handleIconLeave = (e) => {
    setIconPos(-100);
  };

  const handleMoveLink = () => {
    const url = projectRowData.data.link;
    if (url.startsWith("http://") || url.startsWith("https://")) {
      window.open(url, "_blank");
    } else {
      window.open("https://www." + url, "_blank");
    }
  };

  return (
    <div
      className={styles["resource-item"]}
      onMouseEnter={handleIconEnter}
      onMouseLeave={handleIconLeave}
      onClick={(e) => {
        e.stopPropagation();
        handleMoveLink();
      }}
    >
      <div
        className={styles["resource-item-favicon"]}
        style={{
          backgroundImage: `url(https://www.google.com/s2/favicons?sz=64&domain_url=${projectRowData.data.link})`,
        }}
      ></div>

      <ToolTip
        text={projectRowData.title}
        isVisible={iconPos !== -100}
        style={{ top: `${iconPos}px` }}
      />
    </div>
  );
}

export default function ProjectItem({ projectRowData, onClick, loadData }) {
  const api = useApi();

  const [projectData, setProjectData] = useState(null);

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [isDone, setIsDone] = useState(false);
  const [isActionMenuModalOn, setIsActionMenuModalOn] = useState(false);
  const updateProjectList = useSetRecoilState(projectListState);
  const trackCall = useJuneTrackCall();

  const handleProjectDetailClick = () => {
    onClick(true, projectRowData);
  };

  const handleItemContextMenu = (e) => {
    e.preventDefault();

    const { clientX, clientY } = e;
    setModalPosition({ x: clientX, y: clientY });
    setIsActionMenuModalOn(true);
  };

  const handleProcessIsDone = () => {
    setIsActionMenuModalOn(false);
    handleDoneOrUnDone();
    trackCall("complete_project", { location: "wiki" });
  };

  const handleDoneOrUnDone = () => {
    updateProjectList((currentProjects) => {
      const updatedProjects = currentProjects.map((project) => {
        if (project.id === projectRowData.id) {
          return { ...project, status: isDone ? "InProgress" : "Completed" };
        }
        return project;
      });
      return updatedProjects;
    });

    api.patch("projects/" + projectRowData.id + "/" + (isDone ? "undone" : "done")).then(() => {
      if (isDone) {
        trackCall("restore_project", { type: "completed_items" });
      }
    });
  };

  const handleDataDelete = () => {
    setIsActionMenuModalOn(false);

    updateProjectList((current) => {
      const updatedProjects = current.filter((project) => project.id !== projectRowData.id);
      return updatedProjects;
    });

    api.patch("projects/" + projectRowData.id + "/mark").then(() => {
      trackCall("delete_project", { location: "wiki" });
    });
  };

  const handleChangeColor = (colorValue) => {
    setProjectData((current) => {
      let newItem = { ...current, color: colorValue };
      return newItem;
    });

    updateProjectList((current) => {
      return current.map((project) => {
        if (project.id === projectRowData.id) {
          return { ...project, color: colorValue };
        }
        return project;
      });
    });

    setIsActionMenuModalOn(false);

    api
      .patch("projects/" + projectRowData.id, {
        color: colorValue,
      })
      .then(() => {
        trackCall("update_project", { type: "color", location: "wiki" });
      });
  };

  const handleClose = () => {
    setIsActionMenuModalOn(false);
  };

  useEffect(() => {
    setProjectData(projectRowData);
    setIsDone(projectRowData.status === "Completed");
  }, [projectRowData]);

  return (
    <>
      <div
        className={styles.project__item}
        style={{
          border: `1px solid ${projectData?.color}`,
          background: `${projectData?.color}0D`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleProjectDetailClick();
        }}
        onContextMenu={handleItemContextMenu}
      >
        <div className={styles.projectTitleDescriptionArea}>
          <div className={styles.project__title}>{projectData?.title}</div>
          <div className={styles.project__description}>{projectData?.description}</div>
        </div>
        <div className={styles.project__bookmark__area}>
          <div className={styles["setting-property-resource"]}>
            {projectData && projectData.bookmarks && projectData.bookmarks.length > 0 ? (
              projectData.bookmarks.length <= 6 ? (
                <>
                  {projectData.bookmarks.map((item, index) => (
                    <BookmarkFavicon key={index} projectRowData={item} />
                  ))}
                  {/* <BookmarkAdd projectData={projectData} /> */}
                </>
              ) : (
                projectData.bookmarks
                  .slice(0, 6)
                  .map((item, index) => <BookmarkFavicon key={index} projectRowData={item} />)
              )
            ) : null}
          </div>
        </div>
      </div>
      {isActionMenuModalOn && (
        <ActionMenuModal
          x={modalPosition.x}
          y={modalPosition.y}
          onProcessDone={handleProcessIsDone}
          onDelete={handleDataDelete}
          onClose={handleClose}
          onChangeColor={handleChangeColor}
          isDone={isDone}
          projectColor={projectRowData.color}
        />
      )}
    </>
  );
}
