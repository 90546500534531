import useDoneTaskUpdate from "./useDoneTaskUpdate";
import useInboxTaskUpdate from "./useInboxTaskUpdate";

const useTaskUpdater = (type) => {
  const { updateTask: updateInboxTask, deleteTask: deleteInboxTask } = useInboxTaskUpdate();
  const { updateTask: updateDoneTask, deleteTask: deleteDoneTask } = useDoneTaskUpdate();

  const updateTaskList = (prevTasks, dragIndex, dragItem) => {
    return type === "inboxTask"
      ? updateInboxTask(prevTasks, dragIndex, dragItem)
      : updateDoneTask(prevTasks, dragIndex, dragItem);
  };

  const deleteTaskList = (prevTasks, taskId) => {
    return type === "inboxTask"
      ? deleteInboxTask(prevTasks, taskId)
      : deleteDoneTask(prevTasks, taskId);
  };

  return { updateTaskList, deleteTaskList };
};

export default useTaskUpdater;
