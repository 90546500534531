import React, { forwardRef } from "react";
import styles from "./index.module.css";

function ShowMoreTrigger({ onClick, children }, ref) {
  return (
    <span ref={ref} className={styles.showMore} onClick={onClick}>
      {children}
    </span>
  );
}
export default forwardRef(ShowMoreTrigger);
