import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { inboxTaskListState } from "../recoil/taskList/inboxTaskListState";
import useApi from "../services/auth/useApi";

const useFetchInProgressTasks = () => {
  const api = useApi();
  const [error, setError] = useState(null);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);

  const fetchInProgressTasks = useCallback(() => {
    try {
      api.get("/tasks?status=InProgress").then((res) => {
        updateInboxTaskList(res.data.tasks);
      });
    } catch (err) {
      setError(err);
    }
  }, []);

  return { fetchInProgressTasks };
};

export default useFetchInProgressTasks;
