export default function generateRecurrence(value) {
  const textToRecurrence = {
    "Every Day": "RRULE:FREQ=DAILY",
    "Every Weekday": "RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR",
    "Every Week": "RRULE:FREQ=WEEKLY",
    "Every Month": "RRULE:FREQ=MONTHLY",
    "Every Year": "RRULE:FREQ=YEARLY",
  };

  const recurrenceToText = Object.entries(textToRecurrence).reduce((acc, [text, recurrence]) => {
    acc[recurrence] = text;
    return acc;
  }, {});

  return textToRecurrence[value] || recurrenceToText[value] || "Custom Repeat";
}
