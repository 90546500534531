import { atom } from "recoil";

export const taskPopupState = atom({
  key: "taskPopupState",
  default: {
    isVisible: false,
    data: null,
    modalPosition: { x: 0, y: 0 },
    targetRect: null,
    onClose: null,
    loadData: null,
    handleDataDuplicate: null,
    type: "", // inbox or calendar,
    handleEventDelete: null,
    handleEventChange: null,
  },
});
