import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuid4 } from "uuid";
import { INPROGRESS } from "../../constants/taskStateType";
import { accountState } from "../../recoil/account/accountState";
import { dragEventState } from "../../recoil/calendar/calendarState";
import { filteringProjectIdListState } from "../../recoil/projects/filteringProjectIdListState";
import { inboxTaskListState } from "../../recoil/taskList/inboxTaskListState";
import { visibilityState } from "../../recoil/calendar/settingCalendar";
import useApi from "../../services/auth/useApi";
import { useJuneTrackCall } from "../../utils/june/analytics";
import styles from "./TaskList.module.css";
import TaskListEmptyDropArea from "./TaskListEmptyDropArea";
import TaskRow from "./TaskRow";

export default function TaskList({ expand, onExpandClick, loadInboxTask }) {
  const api = useApi();
  const trackCall = useJuneTrackCall();
  const inputRef = useRef(null);

  const [filteringProjectIdList, setFilteringProjectIdList] = useRecoilState(
    filteringProjectIdListState
  );
  const [accountData, setAccountData] = useRecoilState(accountState);

  const [groupFold, setGroupFold] = useState(true);
  const [newTaskName, setNewTaskName] = useState("");
  const [taskAddClick, setTaskAddClick] = useState(false);

  const [dragEvent, setDragEvent] = useRecoilState(dragEventState);
  const [inboxTaskList, updateInboxTaskList] = useRecoilState(inboxTaskListState);
  const [isTaskRowHoverd, setIsTaskRowHoverd] = useState(false);
  const [taskSelectedId, setTaskSelectedId] = useState(null);

  const defaultVisibility = useRecoilValue(visibilityState);

  // scroll 숨기기
  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  useEffect(() => {
    if (expand) {
      setTaskAddClick(false);
    }
  }, [expand]);

  const handleTaskAddClick = () => {
    if (!expand) onExpandClick();
    setTaskAddClick(true);
  };

  useEffect(() => {
    if (taskAddClick && inputRef.current) {
      inputRef.current.focus();
    }
  }, [taskAddClick]);

  const [isEnter, setIsEnter] = useState(false);

  useEffect(() => {
    if (inputRef.current && !isEnter) {
      inputRef.current.focus();
    }
  }, [inputRef, isEnter]);

  // NOTE 스크롤바 보이기
  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    setIsScrolling(true);
    const timeout = setTimeout(() => {
      setIsScrolling(false);
    }, 2000);

    setScrollTimeout(timeout);
  };

  useEffect(() => {
    const element = document.querySelector(`.${styles.taskList_expand}`);
    if (element) {
      element.addEventListener("scroll", handleScroll);

      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTaskAddClick(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const handleAddTask = (e) => {
    if (e.key === "Enter" && !isEnter) {
      if (newTaskName && newTaskName.trim().length > 0) {
        setIsEnter(true);
        setNewTaskName("");
        let newItem = {
          id: `task-${uuid4()}`,
          creator: accountData.accountInfo.accounts[0].email,
          title: newTaskName,
          visibility: defaultVisibility === "public" ? "public" : "private",
          transparency: defaultVisibility === "invisible" ? "transparent" : "opaque",
          itemStatus: "InProgress",
          taskType: "Task", // 240802 새로 추가된 프로퍼티
        };

        updateInboxTaskList((current) => {
          return [newItem, ...current];
        });

        api
          .post("tasks", newItem, {
            headers: {
              "X-Requester": accountData.accountInfo.accounts[0].email,
            },
          })
          .then((res) => {
            trackCall("create_block", {
              location: "inbox",
            });
            setTimeout(() => {
              setIsEnter(false);
            }, 200);
          });
      }
    } else if (e.key === "Escape") {
      setTaskAddClick(false);
      setIsEnter(false);
    }
  };

  const handleDataDelete = (rowData) => {
    updateInboxTaskList((current) => {
      const updatedTasks = current.filter((task) => task.id !== rowData.id);
      return updatedTasks;
    });

    api
      .patch("tasks/" + rowData.id + "/mark", "", {
        headers: { "X-Requester": rowData.creator },
      })
      .then(() => {
        // NOTE inbox에서는 무조건 type task(guest 추가 시 시간 추가로 캘린더에 설정 필수이기 때문)
        trackCall("delete_block", { location: "inbox", type: "task" });
      });
  };

  const handleDataDuplicate = (rowData) => {
    const newId = `task-${uuid4()}`;

    let newItem = {
      ...rowData,
      id: newId,
    };

    api.get("notes/" + rowData.id + "/" + rowData.creator).then((res) => {
      newItem = {
        ...newItem,
        note: res.data.note || "",
      };

      const index = inboxTaskList.findIndex((item) => item.id === rowData.id);

      let tempArr = [
        ...inboxTaskList.slice(0, index + 1),
        newItem,
        ...inboxTaskList.slice(index + 1),
      ];

      updateInboxTaskList(tempArr);

      api
        .post("tasks", newItem, {
          headers: {
            "X-Requester": accountData.accountInfo.accounts[0].email,
          },
        })
        .then((res) => {
          let trackObject = { location: "inbox" };
          if (newItem.attendees != null && newItem.attendees.length) {
            trackObject = { ...trackObject, type: "meeting" };
          } else {
            trackObject = { ...trackObject, type: "task" };
          }

          trackCall("duplicate_block", trackObject);

          const taskIdArr = tempArr.map((taskData) => taskData.id);

          const primaryAccountInfo = accountData.accountInfo.accounts.find(
            (account) => account.type === "primary"
          );

          const newItems = { taskIds: taskIdArr, status: INPROGRESS };

          api
            .post("/tasks/order", newItems, {
              headers: {
                "X-Requester": primaryAccountInfo.email,
              },
            })
            .then((res) => {})
            .catch((error) => {
              loadInboxTask();
            });
        });
    });
  };

  const handleTaskRowEnter = (e) => {
    setIsTaskRowHoverd(true);
  };

  const handleTaskRowLeave = (e) => {
    setIsTaskRowHoverd(false);
  };

  const handleTasksReorderChange = () => {
    const taskIdArr = inboxTaskList.map((taskData) => taskData.id);

    const primaryAccountInfo = accountData.accountInfo.accounts.find(
      (account) => account.type === "primary"
    );

    // console.log(
    //   inboxTaskList.map((taskData) => {
    //     return { id: taskData.id, title: taskData.title };
    //   })
    // );

    const newItem = { taskIds: taskIdArr, status: INPROGRESS };

    api
      .post("/tasks/order", newItem, {
        headers: {
          "X-Requester": primaryAccountInfo.email,
        },
      })
      .then((res) => {})
      .catch((error) => {
        loadInboxTask();
      });
  };

  const handleSaveTaskSelectedId = (id) => {
    setTaskSelectedId(id);
  };

  const memoizedTaskList = useMemo(() => {
    const projectIds = filteringProjectIdList;

    if (!inboxTaskList || inboxTaskList.length === 0) {
      return (
        <TaskListEmptyDropArea
          onTasksReorderChange={handleTasksReorderChange}
          handleUpdateTaskList={updateInboxTaskList}
          listType={"inboxTask"}
        />
      );
    }

    return inboxTaskList
      .filter((task) => !projectIds.includes(task.projectId))
      .map((taskDataRow, index) => (
        <TaskRow
          key={taskDataRow.id}
          data={taskDataRow}
          index={index}
          expand={expand}
          onDataDelete={handleDataDelete}
          onDataDuplicate={handleDataDuplicate}
          loadData={loadInboxTask}
          onTasksReorderChange={handleTasksReorderChange}
          taskSelectedId={taskSelectedId}
          onSaveTaskSelectedId={handleSaveTaskSelectedId}
          taskList={inboxTaskList}
          handleUpdateTaskList={updateInboxTaskList}
          listType={"inboxTask"}
        />
      ));
  }, [inboxTaskList, filteringProjectIdList, expand, taskSelectedId, handleSaveTaskSelectedId]);

  return (
    <>
      <div
        className={`${
          expand ? styles["taskList_expand"] : styles["taskList_fold"]
        }  ${isScrolling ? styles.showScrollbar : ""}`}
      >
        <div
          className={`${
            !expand ? styles.taskListAddOuterRectangle : styles["taskList-add"]
          } ${!expand && isTaskRowHoverd && styles.foldHoverd}
          ${expand && isTaskRowHoverd && styles.expandHoverd}`}
          onClick={handleTaskAddClick}
          onMouseEnter={handleTaskRowEnter}
          onMouseLeave={handleTaskRowLeave}
        >
          <div className={styles["taskList-add-icon"]}></div>
          {expand && (
            <div className={styles["taskList-add-title"]}>
              {taskAddClick === true ? (
                !isEnter ? (
                  <input
                    className={styles["taskList-add-input"]}
                    type="text"
                    value={newTaskName}
                    onFocus={() => setIsEnter(false)}
                    onChange={(e) => setNewTaskName(e.target.value)}
                    onKeyDown={handleAddTask}
                    ref={inputRef}
                  />
                ) : null
              ) : (
                <span>Add a task</span>
              )}
            </div>
          )}
        </div>
        {memoizedTaskList}
      </div>
    </>
  );
}
