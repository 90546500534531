import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { tokenState } from "../../recoil/auth/tokenState";
import styles from "./style.module.css";

import useApi from "../../services/auth/useApi";

import { ReactComponent as GoogleLoginIcon } from "../../assets/Login/google-login-icon.svg";
import { ReactComponent as VerticalLine } from "../../assets/Login/signup-vertical-line.svg";
import { useJuneIdentifyCall } from "../../utils/june/analytics";

const isDev = process.env.NODE_ENV !== "production";

export default function Login() {
  const [token, setToken] = useRecoilState(tokenState);
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const identifyCall = useJuneIdentifyCall();

  useEffect(() => {
    if (token.mRefreshToken != null) {
      handleNavigation();
    }
  }, []);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleGoogleLogin = () => {
    window.location.href = process.env.REACT_APP_SERVER + "/auth/google/login";
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const mAccessToken = urlSearchParams.get("mAccessToken");
    const mRefreshToken = urlSearchParams.get("mRefreshToken");
    const accessToken = urlSearchParams.get("accessToken");
    const failReason = urlSearchParams.get("failReason");

    if (failReason != null) {
      handleOpenPopup();
    }

    if (accessToken) {
      setToken({
        mAccessToken: mAccessToken,
        mRefreshToken: mRefreshToken,
        accessToken: accessToken,
      });
    }
  }, [location]);

  useEffect(() => {
    if (token.accessToken) {
      handleNavigation();
    }
  }, [token]);

  const handleNavigation = async () => {
    if (isDev) {
      if (!document.startViewTransition) {
        return navigate("/main");
      } else {
        return document.startViewTransition(() => {
          navigate("/main");
        });
      }
    } else {
      await api.get("accounts/me").then((res) => {
        const [primaryAccountInfo] = res.data.accountInfo.accounts.filter((account) => {
          return account.type === "primary";
        });

        const membership = res.data.accountInfo.membership;
        identifyCall(primaryAccountInfo.email, membership);

        if (membership === "trial") {
          return navigate("/joinWaitList");
        } else {
          if (!document.startViewTransition) {
            return navigate("/main");
          } else {
            return document.startViewTransition(() => {
              navigate("/main");
            });
          }
        }
      });
    }
  };

  return (
    <>
      <div className={styles["wrap"]}>
        <div className={styles["body"]}>
          <div className={styles["title"]}>
            Try Moba
            <VerticalLine style={{ margin: "15% 0" }} />
          </div>
          <button className={styles["gsi-material-button"]} onClick={handleGoogleLogin}>
            <div className={styles["gsi-material-button-state"]}></div>
            <div className={styles["gsi-material-button-content-wrapper"]}>
              <div className={styles["gsi-material-button-icon"]}>
                <GoogleLoginIcon />
              </div>
              <span className={styles["gsi-material-button-contents"]}>Log in with Google</span>
              <span style={{ display: "none" }}>Log in with Google</span>
            </div>
          </button>
        </div>
      </div>
      {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <div className={styles.closeButton} onClick={handleClosePopup}></div>
            <div className={styles.popupBody}>
              <p>There is no account associated with that email.</p>
              <p>
                Please try with another one,{"\n"} or you can{" "}
                <a href="/join">create a new account</a> instead.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
